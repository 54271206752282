import {useCallback, useState} from 'react';
import styles from './addUsers.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';
import {Form, Pagination, Select, Table} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {ADD_USERS_TO_A_GROUP, REMOVE_MEMBER} from 'graphql/mutations/groups';
import {toast} from 'react-toastify';
import {GET_GROUP_MEMBERS_BY_ID} from 'graphql/queries/groups';
import DBModal from 'components/modal/modal';
import gamerIcon from 'images/gamer.png';
import moment from 'moment';
import {UserSelectField} from 'components/userSelectField/UserSelectField';
import {Search} from '@dabafinance/react-components';
import {debounce, sanitizeInput} from 'utils/helper';

const AddUsersToGroup = ({operations}) => {
  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [searchValue, setSearchedValue] = useState(null);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const {id} = useParams();

  const {data, refetch, loading} = useQuery(GET_GROUP_MEMBERS_BY_ID, {
    variables: {
      filters: {
        groupId: id,
        page: 1,
        limit: 10,
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: error => toast.error(error.message),
    onCompleted: data =>
      setTotalPage(data?.adminGetGroupMembers?.pagination?.total),
  });
  const [addMembersToGroup, {loading: loadingUpdate}] =
    useMutation(ADD_USERS_TO_A_GROUP);

  const [removeMemberFromGroup, {loading: removeMember}] =
    useMutation(REMOVE_MEMBER);

  const handlePageChange = currentPage => {
    if (page === currentPage) return;
    setPage(currentPage);
    refetch({
      filters: {
        groupId: id,
        page: currentPage,
        limit,
        search: searchValue,
      },
    });
  };

  const handleSearch = value => {
    const sanitizedValue = sanitizeInput(value);
    setPage(1);
    setLimit(10);
    if (!sanitizedValue) {
      return refetch({
        filters: {
          groupId: id,
          page: 1,
          limit: 10,
        },
      });
    }
    refetch({
      filters: {
        groupId: id,
        search: sanitizedValue,
      },
    });
  };

  const optimizedSearchCall = useCallback(debounce(handleSearch), []);

  const handleDelete = () => {
    removeMemberFromGroup({
      variables: {
        groupId: id,
        memberIds: members,
      },
    }).then(({data: {removeMemberFromGroup}}) => {
      if (removeMemberFromGroup.__typename === 'Error') {
        toast.error(removeMemberFromGroup.message);
        setOpenRemoveModal(false);
      } else {
        setMembers([]);
        refetch();
        toast.success('Removed Successfully');
        setOpenRemoveModal(false);
      }
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <div
            onClick={() => navigate(`/dashboard/user/${record?.id}`)}
            className={styles['record-name']}>
            {record.imageUrl ? (
              <img src={record?.imageUrl} alt="" />
            ) : (
              <img src={gamerIcon} alt="" />
            )}
            <h4 style={{width: 100}}>
              {record?.firstName} {record?.lastName}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => (
        <div>
          <h4>{record?.username}</h4>
        </div>
      ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'expiring',
      key: 'expiring',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{`${record?.countryCode ?? '--'}${
            record?.phoneNumber ?? '--'
          }`}</h4>
        </div>
      ),
    },
    {
      title: 'Profile Completion',
      dataIndex: 'profileCompleted',
      key: 'invested',
      render: (text, record) => (
        <div>
          <span>{record?.profileCompletion?.completionPercentage}%</span>
        </div>
      ),
    },
    {
      title: 'Accredited',
      dataIndex: 'accredited',
      key: 'accredited',
      render: (text, record) => (
        <div>
          <h4>
            {record?.investorProfile?.isAccredited
              ? 'Accredited'
              : 'Unaccredited'}
          </h4>
        </div>
      ),
    },
    {
      title: 'Date Joined',
      dataIndex: 'createdAt',
      key: 'joined',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{moment(record.createdAt).format('MMM Do YYYY')}</h4>
        </div>
      ),
    },
  ];

  const handleSubmit = () => {
    addMembersToGroup({
      variables: {
        groupId: id,
        memberIds: users.map(data => data.split(';')[2]),
      },
    })
      .then(({data: {addMembersToGroup}}) => {
        if (addMembersToGroup.response) {
          toast.success('Member(s) added successful');
          setOpenModal(false);
          setUsers([]);
          refetch();
        } else {
          toast.error('Member already exists');
        }
      })
      .catch(error => toast.error(error));
  };

  return (
    <div>
      <div className={styles.title}>
        <h1>Group Members</h1>
        <div className={styles['action-buttons']}>
          <Search
            value={searchValue}
            placeholder="Search by Names"
            onChange={e => {
              setSearchedValue(e);
              optimizedSearchCall(e, page, limit);
            }}
            id={'user-search'}
          />
          <SubmitButton
            handleClick={() => setOpenModal(true)}
            label="Add Users"
            smallWidth
            disabled={!operations?.update}
          />
          <SubmitButton
            secondary
            disabled={!operations?.update}
            smallWidth
            handleClick={() => setOpenRemoveModal(true)}
            label="Remove Users"
          />
        </div>
      </div>
      <div>
        <Table
          dataSource={data?.adminGetGroupMembers?.data}
          columns={columns}
          className={styles['users-table']}
          pagination={false}
          loading={loading}
        />
        <Pagination
          onChange={e => handlePageChange(e)}
          showSizeChanger
          showQuickJumper
          pageSize={limit}
          total={totalPage}
          className="ant-table-pagination"
          current={page}
          onShowSizeChange={(_, size) => {
            setLimit(size);
            setPage(1);
            refetch({
              filters: {
                groupId: id,
                page: 1,
                search: searchValue,
                limit: size,
              },
            });
          }}
        />
      </div>
      <DBModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        content={
          <div className={styles.body}>
            <Form onFinish={handleSubmit} layout="vertical">
              <Form.Item
                label="Select Users"
                name="users"
                className={styles.width}>
                <UserSelectField users={users} setUsers={setUsers} />
              </Form.Item>
              <SubmitButton label="Add Users" disabled={loadingUpdate} />
            </Form>
          </div>
        }
      />
      <DBModal
        isOpen={openRemoveModal}
        handleClose={() => setOpenRemoveModal(false)}
        content={
          <div className={styles.body}>
            <h2>Remove Members</h2>
            <Form onFinish={handleDelete} layout="vertical">
              <Form.Item label="Select Users" name="users" required>
                <SearchUsersInGroup
                  groupId={id}
                  members={members}
                  setMembers={setMembers}
                />
              </Form.Item>
              <SubmitButton label="Remove Users" disabled={removeMember} />
            </Form>
          </div>
        }
      />
    </div>
  );
};

export const SearchUsersInGroup = ({members, setMembers, groupId}) => {
  const {data, refetch, loading} = useQuery(GET_GROUP_MEMBERS_BY_ID, {
    variables: {
      filters: {
        groupId,
        page: 1,
        limit: 100,
      },
    },
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });

  const debouncedSetSearchValue = debounce(value => {
    const sanitizedValue = sanitizeInput(value);

    refetch({
      filters: {
        search: sanitizedValue,
        groupId,
      },
    });
  });

  return (
    <Select
      loading={loading}
      allowClear
      mode="multiple"
      showSearch
      filterOption={false}
      placeholder="Search Users in Group"
      onSearch={e => debouncedSetSearchValue(e)}
      value={members}
      onChange={e => setMembers(e)}>
      {data?.adminGetGroupMembers?.data?.map(data => (
        <Select.Option key={data.id} value={data.id}>
          <div className={styles.user}>
            <img src={data.imageUrl} alt="" />
            <span>
              {data.firstName} {data.lastName}
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default AddUsersToGroup;
