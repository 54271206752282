import {useQuery} from '@apollo/client';
import {Dropdown, Pagination, Table, Tooltip} from 'antd';
import {GET_ALL_ORDERS} from 'graphql/queries/stocks';
import {useLocation, useNavigate} from 'react-router-dom';
import dotsIcon from 'icons/dots.svg';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from './viewOrders.module.scss';
import {Button, Search} from '@dabafinance/react-components';
import gamer from 'images/gamer.png';
import filterIcon from 'icons/filter.svg';

import {OrderFilter} from './OrderFilter';
import moment from 'moment';
import {CloseCircleOutlined, RetweetOutlined} from '@ant-design/icons';
import {
  BulkFillCSVOrders,
  BulkFillOrder,
  ExportBroker,
  FilledOrder,
  OpenOrder,
  OrderHistory,
  OrderNotes,
  RejectOrder,
  RevertOpenOrder,
} from './HandleOrders';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';
import ManualTriggerEmail from './ManualTriggerEmail';
import {
  bulkOperationItems,
  handleSearchStockTicker,
  handleStocksOrderFilter,
  handleStocksOrdersColumns,
  HoldOrderModal,
} from './OrderActions';

const ViewOrders = ({activeTab, isAffiliate}) => {
  const [orderModal, setOrderModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderId, setOrderId] = useState('');
  const [filterValues, setFilterValues] = useState({});
  const filterContainerRef = useRef(null);
  const [orderDetails, setOrderDetails] = useState({});
  const [user, setUser] = useState(null);
  const [limit, setLimit] = useState(10);
  const [searchedValue, setSearchedValue] = useState('');
  const [tableData, setTableData] = useState([]);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedOrdersInfo, setSelectedOrdersInfo] = useState([]);
  const [operationType, setOperationType] = useState('SINGLE');
  const [isMarketOrderSelected, setIsMarketOrderSelected] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openBulkFillModal, setOpenBulkFillModal] = useState(false);
  const [manualTriggerModal, setManualTriggerModal] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
  const [copied, setCopied] = useState(false);

  const navigate = useNavigate();

  const route = useLocation();

  const searchTicker = new URLSearchParams(route.search).get('ticker');

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.StockListings),
  );

  const handleTab = (tab, id) => {
    if (
      ['FILLED', 'PENDING', 'HISTORY', 'ONHOLD', 'NOTES', 'HOLD'].includes(tab)
    ) {
      setOrderModal(tab);
      setOrderDetails(id);
    } else if (tab !== 'SETTLE') {
      setOrderModal(tab);
    } else {
      navigate(`/dashboard/stocks/settle-order/${id}`);
    }
    setOrderId(id);
  };

  const columns = (() =>
    handleStocksOrdersColumns(
      navigate,
      styles,
      gamer,
      tabOperations,
      handleTab,
      setCopied,
      copied,
      dotsIcon,
    ))();

  const handleBulkOperations = (tab, type) => {
    if (
      ['PENDING', 'HISTORY', 'NOTES', 'HOLD', 'ONHOLD', 'REJECT'].includes(tab)
    ) {
      setOrderModal(tab);
      setOperationType('MULTIPLE');
    } else if (!['SETTLE', 'FILLED'].includes(tab)) {
      setOrderModal(tab);
      setOrderId({type});
      setOperationType('MULTIPLE');
    } else {
      setOpenBulkFillModal({isOpen: true, type: tab});
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys, selectedRows) => {
      const hasMarketOrder = selectedRows?.some(data => data.type === 'MARKET');
      setIsMarketOrderSelected(hasMarketOrder);
      setSelectedRows(selectedRowKeys);
      setSelectedOrdersInfo(selectedRows);
    },
  };

  const {loading, refetch, networkStatus} = useQuery(GET_ALL_ORDERS, {
    variables: {
      input: {
        page: 1,
        hold: activeTab === 'HOLD',
        status: ['PENDING', 'FILLED', 'EXPIRED'].includes(activeTab)
          ? activeTab
          : null,
        'paginate': true,
        'limit': 10,
        search: searchTicker,
        'newFirst': true,
        ...(isAffiliate && {affiliateOrder: isAffiliate}),

        includeOrdersWithElapsedExpiry: true,
        openOrders:
          activeTab === 'ACTIVE'
            ? true
            : ['PENDING', 'FILLED', 'HOLD', 'EXPIRED'].includes(activeTab)
            ? null
            : false,
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => setTableData(data?.getOrders),
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onError: error => toast.error(error.message),
  });

  const handleResetPage = () => setCurrentPage(1);

  useEffect(() => {
    setFilterValues({});
    handleResetPage();
    setSearchedValue('');
    setSelectedOrdersInfo([]);
    setSelectedRows([]);
    setLimit(10);
  }, [activeTab]);

  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true);
      return;
    }
    handleStocksOrderFilter(
      filterValues,
      setFilterValues,
      currentPage,
      user,
      refetch,
      activeTab,
      limit,
      searchTicker,
      searchedValue,
      isAffiliate,
      setOpenFilterModal,
    );
  }, [limit]);

  const debounce = func => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1200);
    };
  };

  const handleResetParamsOnPage = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('ticker');
    const newUrl = window.location.pathname + '?' + searchParams.toString();
    window.location.href = window.location.origin + newUrl; // Reload the page with the updated URL
  };

  const optimizedVersion = useCallback(debounce(handleSearchStockTicker), []);

  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
    handleStocksOrderFilter(
      filterValues,
      setFilterValues,
      page,
      user,
      refetch,
      activeTab,
      limit,
      searchTicker,
      searchedValue,
      isAffiliate,
      setOpenFilterModal,
    );
  };

  if (activeTab === 'PAST') {
    columns.splice(17, 0, {
      title: 'Settled Date',
      key: 'settledDate',
      dataIndex: 'settledDate',
      render: (text, record) =>
        record?.settlementDate ? (
          <span>
            {moment(record?.settlementDate).format('DD MMMM, YYYY h:mmA')}
          </span>
        ) : (
          ''
        ),
    });
  }

  if (activeTab === 'FILLED') {
    columns.splice(17, 0, {
      title: 'Broker',
      key: 'broker',
      dataIndex: 'broker',
      render: (text, record) => <span>{record?.broker?.name}</span>,
    });
  }

  const rowClassName = record => {
    // Example condition: change background for rows with age greater than 40
    return record.notes ? styles.highlightRow : '';
  };

  const memoizedBulkOperationItems = useMemo(
    () =>
      bulkOperationItems(
        activeTab,
        handleBulkOperations,
        !selectedRows?.length,
        setOrderModal,
        isMarketOrderSelected,
      ),
    [activeTab, selectedRows?.length, isMarketOrderSelected],
  );

  return (
    <div>
      {searchTicker && (
        <div className={styles.companyHeader}>
          <h1 className={styles.companyHeader}>{searchTicker} STOCK</h1>
          <CloseCircleOutlined onClick={() => handleResetParamsOnPage()} />
        </div>
      )}
      <div className={styles.filters}>
        <div className={styles.filterSection}>
          <div className={styles['nav-buttons']}>
            <div className={styles['page-number']}>{currentPage}</div>
          </div>
          <Search
            className={styles['search-bar']}
            placeholder="Search by Ticker/Status"
            value={searchedValue}
            onChange={e => {
              setSearchedValue(e);
              optimizedVersion(
                e,
                user,
                activeTab,
                limit,
                filterValues,
                refetch,
                currentPage,
                isAffiliate,
                handleResetPage,
              );
            }}
            id={'user-search'}
          />
          {activeTab === 'EXPIRED' && tabOperations?.update ? (
            <Button
              type="primary"
              onClick={() => setManualTriggerModal(true)}
              label="Export Expiring Order"
            />
          ) : (
            tabOperations?.update && (
              <Button
                type="primary"
                onClick={() => setOpenExportModal(true)}
                disabled={loading || networkStatus === 2}
                label="Broker Export"
              />
            )
          )}

          <div className={styles.filterContainer} ref={filterContainerRef}>
            <div
              onClick={() => setOpenFilterModal(!openFilterModal)}
              className={styles.filter}>
              <h2>Filter</h2>
              <img src={filterIcon} alt="" />
            </div>
            <div
              style={{display: openFilterModal ? 'block' : 'none'}}
              className={styles.filterItems}>
              <OrderFilter
                loading={loading || networkStatus === 2}
                refetch={refetch}
                isFilterModalOpen={openFilterModal}
                user={user}
                setUser={setUser}
                handleFilter={values =>
                  handleStocksOrderFilter(
                    values,
                    setFilterValues,
                    null,
                    user,
                    refetch,
                    activeTab,
                    limit,
                    searchTicker,
                    searchedValue,
                    isAffiliate,
                    setOpenFilterModal,
                  )
                }
                handleClose={() => setOpenFilterModal(false)}
                currentPage={currentPage}
                reset={handleResetPage}
                activeTab={activeTab}
              />
            </div>
            <Tooltip title="Refresh">
              <RetweetOutlined
                size="500"
                className={styles.refresh}
                onClick={() => refetch()}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={styles.tableContainer}>
        {tabOperations?.update && !['EXPIRED', 'PAST'].includes(activeTab) && (
          <Dropdown
            trigger={['click']}
            menu={{
              items: memoizedBulkOperationItems,
            }}>
            <Button
              type="primary"
              onClick={e => e.stopPropagation()}
              label="Bulk Operation"
            />
          </Dropdown>
        )}
      </div>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        loading={loading || networkStatus === 2}
        dataSource={tableData?.data?.map(item => ({...item, key: item?.id}))}
        columns={columns}
        rowClassName={rowClassName}
        pagination={false}
        scroll={{
          x: 'auto',
        }}
      />
      <Pagination
        current={currentPage}
        onChange={e => handlePageChange(e)}
        total={tableData.pagination?.total}
        showQuickJumper
        className="ant-table-pagination"
        showSizeChanger
        pageSize={limit}
        onShowSizeChange={(_, size) => {
          setLimit(size);
        }}
      />

      <BulkFillCSVOrders
        orderModal={orderModal}
        setOrderModal={setOrderModal}
      />

      <RejectOrder
        orderId={orderId}
        orderModal={['REJECT', 'REWRITE'].includes(orderModal)}
        orderIds={selectedRows}
        isReject={orderModal === 'REJECT'}
        setType={setOperationType}
        setSelected={setSelectedRows}
        setOrder={setOrderId}
        setOrderModal={setOrderModal}
        refetch={refetch}
        type={operationType}
        page={currentPage}
      />
      <HoldOrderModal
        holdStatus={orderModal}
        orderId={orderDetails}
        setOrder={setOrderId}
        setOrderModal={setOrderModal}
        setOrderDetails={setOrderDetails}
        refetch={refetch}
        orderIds={selectedRows}
        clearOrderIds={() => setSelectedRows([])}
        operationType={operationType}
        clearOperationType={() => setOperationType('SINGLE')}
        orderModal={['HOLD', 'ONHOLD'].includes(orderModal)}
      />
      <FilledOrder
        orderModal={orderModal === 'FILLED'}
        setOrderModal={setOrderModal}
        orderDetails={orderDetails}
        refetch={refetch}
        page={currentPage}
        operationType={operationType}
        orderIds={selectedRows}
        setType={setOperationType}
        setSelected={setSelectedRows}
      />
      <OpenOrder
        orderModal={orderModal === 'PENDING'}
        setOrderModal={setOrderModal}
        orderId={orderId}
        refetch={refetch}
        page={currentPage}
        type={operationType}
        orderIds={selectedRows}
        setType={setOperationType}
        setSelected={setSelectedRows}
      />
      <RevertOpenOrder
        orderModal={orderModal === 'OPEN'}
        setOrderModal={setOrderModal}
        order={orderId}
        setOrder={setOrderId}
        refetch={refetch}
        page={currentPage}
        type={operationType}
        orderIds={selectedRows}
        setType={setOperationType}
        setSelected={setSelectedRows}
      />
      {/* Export broker modal */}
      <ExportBroker
        activeTab={activeTab}
        orderModal={openExportModal}
        setOrderModal={setOpenExportModal}
      />
      {/* bulk fill modal */}
      <BulkFillOrder
        orderModal={openBulkFillModal}
        setOrderModal={setOpenBulkFillModal}
        refetch={refetch}
        orders={selectedOrdersInfo}
        setOrders={setSelectedOrdersInfo}
        setSelected={setSelectedRows}
      />
      <OrderHistory
        orderModal={orderModal === 'HISTORY'}
        setOrderModal={setOrderModal}
        history={orderDetails}
      />
      <OrderNotes
        orderModal={orderModal === 'NOTES'}
        setOrderModal={setOrderModal}
        notes={orderDetails}
        refetch={refetch}
      />
      <ManualTriggerEmail
        isOpen={manualTriggerModal}
        onClose={() => setManualTriggerModal(false)}
      />
    </div>
  );
};

export default ViewOrders;
