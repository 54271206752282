import {useQuery} from '@apollo/client';
import {Dropdown, Form, Pagination, Switch, Table, Tag, Tooltip} from 'antd';
import {GET_ALL_ORDERS} from 'graphql/queries/stocks';
import {useNavigate, useParams} from 'react-router-dom';
import styles from './transactions.module.scss';
import dotsIcon from 'icons/dots.svg';

import {useState} from 'react';
import {
  FilledOrder,
  OpenOrder,
  OrderHistory,
  RejectOrder,
} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {
  getUserOrdersMenuItems,
  HoldOrderModal,
} from 'pages/adminOpportunities/stocksListings/ViewOrders/OrderActions';

const UserStocks = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [orderId, setOrderId] = useState('');

  const [form] = Form.useForm();
  const holdStatus = Form.useWatch('hold', form);

  const [limit, setLimit] = useState(10);
  const [orderModal, setOrderModal] = useState('');
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState({});
  const [copied, setCopied] = useState(false);

  const {id} = useParams();
  const {loading, data, refetch} = useQuery(GET_ALL_ORDERS, {
    variables: {
      input: {
        page: 1,
        'paginate': true,
        'newFirst': true,
        'limit': 10,
        userId: id,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onError: error => toast.error(error?.message),
  });

  const handleCopy = referenceId => {
    navigator.clipboard.writeText(referenceId).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 400);
    });
  };

  const pageOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Users),
  );

  const handleTab = (tab, id) => {
    if (['FILLED', 'PENDING', 'HISTORY', 'HOLD', 'ONHOLD'].includes(tab)) {
      setOrderModal(tab);
      setOrderDetails(id);
    } else if (tab === 'REJECT') {
      setOrderModal(tab);
    } else {
      navigate(`/dashboard/stocks/settle-order/${id}`);
    }
    setOrderId(id);
  };

  const columns = [
    {
      title: 'Order ID',
      key: 'id',
      dataIndex: 'id',
      render: (text, record) => (
        <Tooltip trigger="hover" title={copied ? 'Copied' : 'Copy'}>
          <b
            style={{cursor: 'pointer'}}
            onClick={e => {
              e.stopPropagation();
              handleCopy(record?.id);
            }}>
            {record?.id}
          </b>
        </Tooltip>
      ),
    },
    {
      title: 'Ticker',
      key: 'symbol',
      dataIndex: 'symbol',
      render: (text, render) => <span>{render?.symbol}</span>,
    },
    {
      title: 'Direction',
      key: 'direction',
      dataIndex: 'direction',
    },
    {
      title: 'Instrument',
      key: 'instrumentKey',
      dataIndex: 'instrumentKey',
      render: (text, render) => (
        <Tag
          color={
            {
              'BOND': 'cyan',
              'STOCK': 'red',
              'MUTUAL_FUND': 'orange',
            }[render?.instrumentKey]
          }>
          {render?.instrumentKey}
        </Tag>
      ),
    },
    {
      title: 'Currency',
      key: 'assetCurrency',
      dataIndex: 'assetCurrency',
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: (text, render) => (
        <Tag color={render?.type === 'LIMIT' ? 'black' : 'default'}>
          {render?.type}
        </Tag>
      ),
    },
    {
      title: 'Hold Status',
      key: 'hold',
      dataIndex: 'hold',
      render: (text, render) =>
        render?.hold ? (
          <Tag color={render?.hold ? 'red' : ''}>
            {render?.hold ? 'ON HOLD' : ''}
          </Tag>
        ) : (
          '- -'
        ),
    },
    {
      title: 'Price',
      key: 'price',
      dataIndex: 'price',
      render: (text, render) => <span>{render?.price?.toLocaleString()}</span>,
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (text, render) => (
        <Tag
          color={
            {
              'FILLED': 'green',
              'PENDING': 'orange',
              'ERROR': 'red',
              'REJECTED': 'red',
              'CANCELLED': 'red',
              'SETTLED': 'cyan',
              'PARTIALLY_FILLED': 'yellow',
            }[render?.status] || 'blue'
          }
          key={render.id}>
          {render.status}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      fixed: 'right',
      render: (text, render) => (
        <Dropdown
          trigger="click"
          menu={{
            items: getUserOrdersMenuItems(render, handleTab, pageOperations),
          }}>
          <img src={dotsIcon} onClick={e => e.stopPropagation()} />
        </Dropdown>
      ),
    },
  ];

  const handlePageChange = (page, pageSize) => {
    if (page === currentPage && pageSize === limit) return;
    setCurrentPage(page);
    setLimit(pageSize);
    refetch({
      input: {
        page,
        limit: pageSize,
        userId: id,
        hold: holdStatus,
        'paginate': true,
        'newFirst': true,
      },
    });
  };

  return (
    <div>
      <Form
        className={styles.hold}
        form={form}
        onFinish={values => {
          setCurrentPage(1);
          refetch({
            input: {
              limit,
              page: 1,
              userId: id,
              'paginate': true,
              'newFirst': true,
              hold: values?.hold,
            },
          });
        }}
        layout="horizontal">
        <Form.Item name="hold" label="Orders on Hold">
          <Switch />
        </Form.Item>
        <SubmitButton smallWidth secondary label="Filter" />
      </Form>
      <div className={styles.tableContainer}>
        <Table
          columns={columns}
          dataSource={data?.getOrders?.data}
          loading={loading}
          pagination={false}
        />
      </div>
      <Pagination
        current={currentPage}
        onChange={handlePageChange}
        total={data?.getOrders?.pagination?.total}
        showQuickJumper
        className="ant-table-pagination"
        showSizeChanger
        pageSize={limit}
      />
      <RejectOrder
        orderId={orderId}
        orderModal={orderModal === 'REJECT'}
        setOrderModal={setOrderModal}
        refetch={refetch}
        page={currentPage}
      />
      <FilledOrder
        orderModal={orderModal === 'FILLED'}
        setOrderModal={setOrderModal}
        orderDetails={orderDetails}
        refetch={refetch}
        page={currentPage}
      />
      <OpenOrder
        orderModal={orderModal === 'PENDING'}
        setOrderModal={setOrderModal}
        orderId={orderId}
        refetch={refetch}
        setSelected={() => {}}
        page={currentPage}
      />
      <OrderHistory
        orderModal={orderModal === 'HISTORY'}
        setOrderModal={setOrderModal}
        history={orderDetails}
      />
      <HoldOrderModal
        holdStatus={orderModal}
        orderId={orderDetails}
        setOrder={setOrderId}
        setOrderModal={setOrderModal}
        setOrderDetails={setOrderDetails}
        refetch={refetch}
        clearOrderIds={() => {}}
        clearOperationType={() => {}}
        orderModal={['HOLD', 'ONHOLD'].includes(orderModal)}
      />
    </div>
  );
};

export default UserStocks;
