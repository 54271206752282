import {gql} from '@apollo/client';

export const GET_ALL_REPORT = gql`
  query GetAllReports {
    getAllReports {
      ... on Error {
        message
        statusCode
      }
      ... on Report {
        id
        title
        createdBy
        id
        document
        type
        createdAt
        relatedTopics {
          id
          name
          icon
        }
        relatedCountry {
          id
          name
          icon
        }
        relatedCompany {
          id
          name
          logoImgURL
        }
        relatedCompanies {
          id
          name
          logoImgURL
        }
        relatedIndustry {
          id
          name
          icon
        }
        relatedCountries {
          id
          name
          icon
        }
      }
    }
  }
`;

export const GET_ALL_REPORT_IDS = gql`
  query GetAllReports {
    getAllReports {
      ... on Report {
        id
        title
      }
    }
  }
`;

export const GET_REPORT = gql`
  query GetReport($getReportId: ID!) {
    getReport(id: $getReportId) {
      ... on Error {
        message
        statusCode
      }
      ... on Report {
        title
        createdBy
        document
        id
        type
        imageUrl
        keywords
        linkType
        sections {
          sectionName
          sequence
          content
        }
        relatedTopics {
          id
          name
          icon
          type
        }
        relatedCountry {
          id
          name
          icon
        }
        relatedCompany {
          id
          name
          logoImgURL
        }
        relatedCompanies {
          id
          name
          logoImgURL
        }
        relatedIndustry {
          id
          name
          icon
        }
        relatedCountries {
          id
          name
          icon
          type
        }
      }
    }
  }
`;

export const GET_ALL_COMPANY = gql`
  query GetAllCompanies {
    getAllCompanies {
      ... on Company {
        id
        name
        logoImgURL
        legalName
        fractionalShares
        ticker
        feeSchedule {
          feeName
          feeType
          fixedFeeAmount
          variableFeeAmount
        }
        stock {
          lastPrice
          baseCurrencyCode
        }
      }
    }
  }
`;

export const GET_ALL_INDUSTRIES = gql`
  query GetAllIndustries {
    getAllIndustries {
      ... on Error {
        message
        statusCode
      }
      ... on Industry {
        id
        name
        icon
      }
    }
  }
`;
