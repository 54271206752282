import {Dropdown, Form, Input, Select, Tabs} from 'antd';
import styles from './groupInstrumentForm.module.scss';
import backIcon from 'images/back.png';
import UploadImage from 'components/Startup/profile/companyImage';
import {isDev, supportedLanguages} from 'utils/constants';
import {useEffect, useState} from 'react';
import {Button} from '@dabafinance/react-components';
import {handleTranslateCollectionGroups} from 'pages/collections/CollectionGroups/create/actions';
import {useMutation, useQuery} from '@apollo/client';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {GET_ASSET_CLASS_INSTRUMENTS} from 'graphql/queries/assetManagement';
import {useNavigate, useParams} from 'react-router-dom';
import _ from 'lodash';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {toast} from 'react-toastify';
import {createInstrumentGroup} from '../actions';
import {GROUP_FINANCIAL_INSTRUMENTS} from 'graphql/mutations/assetManagement';
import {useSelector} from 'react-redux';

export const GroupInstrumentsForm = () => {
  const [imgUrl, setImgUrl] = useState(null);
  const [multiLang, setMultiLang] = useState([]);

  const [form] = Form.useForm();
  const title = Form.useWatch('title', form);
  const description = Form.useWatch('description', form);
  const language = Form.useWatch('language', form);
  const {key, id, assetClass} = useParams();
  const navigate = useNavigate();
  const isUpdate = !!id;
  const defaultValues = useSelector(
    state => state?.assetClass?.groupedInstruments,
  );

  useEffect(() => {
    if (isUpdate) {
      form.setFieldsValue({
        title: defaultValues?.title,
        description: defaultValues?.description,
        language: defaultValues?.lang,
        instruments: defaultValues?.instruments?.map(data => data?.id),
      });
      setImgUrl(defaultValues?.banner);
      setMultiLang(
        defaultValues?.multilang
          ?.filter(data => data?.language !== defaultValues.lang)
          ?.map(({title, description, language}) => ({
            title,
            description,
            language,
          })),
      );
    }
  }, [isUpdate, defaultValues]);

  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );
  const {
    data,
    refetch: refetchInstrument,
    loading: loadingInstruments,
  } = useQuery(GET_ASSET_CLASS_INSTRUMENTS, {
    variables: {
      input: {
        explore: false,
        instrumentKey: key,
        assetClassKey: assetClass,
        page: 1,
        limit: 30,
      },
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const [groupFinancialInstruments, {loading}] = useMutation(
    GROUP_FINANCIAL_INSTRUMENTS,
  );

  const handleChangeTranslatedValues = (key, value, name) => {
    const newFormValues = [...multiLang];
    newFormValues[key][name] = value;
    setMultiLang(newFormValues);
  };

  const translationProps = {
    sourceLang: language,
    targetText1: title,
    targetText2: description,
  };

  const handleSearch = _.debounce(async value => {
    try {
      refetchInstrument({
        input: {
          explore: false,
          instrumentKey: key,
          assetClassKey: assetClass,
          search: value,
          page: 1,
          limit: 30,
        },
      });
    } catch (error) {
      console.error('Error fetching instruments:', error);
    }
  }, 1200);

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>{isUpdate ? 'Update' : 'Create'} Instruments Group</h1>
      </div>
      <Form
        onFinish={values => {
          if (!multiLang.length) {
            return toast.error('Please Provide a Translation');
          }
          createInstrumentGroup(
            groupFinancialInstruments,
            values,
            imgUrl,
            multiLang,
            assetClass,
            key,
            navigate,
            id,
          );
        }}
        className={styles.form}
        form={form}
        layout="vertical">
        <div className={styles.image}>
          <UploadImage
            large
            previewUrl={imgUrl}
            setImgUrl={setImgUrl}
            isACL={true}
            label="Upload Group Banner"
            bucket={
              isDev
                ? 'dabafinancialinstruments-nonprod'
                : 'dabafinancialinstruments-prod'
            }
          />
        </div>
        <Form.Item
          className={styles.field}
          rules={[{required: true, message: 'Language is Required'}]}
          name="language"
          label="Base Language">
          <Select disabled={isUpdate}>
            {supportedLanguages.map(lang => (
              <Select.Option key={lang.value}>{lang.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Tabs
          className={styles.tabs}
          tabBarExtraContent={
            language && (
              <Dropdown
                disabled={loadingTranslation || !title || !description}
                trigger="click"
                menu={{
                  items: supportedLanguages
                    ?.filter(lang => lang.value !== language)
                    .map(lang => ({
                      label: `To ${lang.label}`,
                      key: lang.value,
                      onClick: () =>
                        handleTranslateCollectionGroups(
                          {...translationProps, targetLang: lang.value},
                          multiLang,
                          setMultiLang,
                          translateText,
                        ),
                    })),
                }}>
                <Button
                  label="Translate"
                  disabled={loadingTranslation || !title || !description}
                />
              </Dropdown>
            )
          }>
          <Tabs.TabPane tab="Base Language" key="BaseLanguage">
            <Form.Item
              name="title"
              className={styles.field}
              label="Title"
              rules={[{required: true, message: 'Title is Required'}]}>
              <Input />
            </Form.Item>
            <Form.Item
              className={styles.field}
              label="Description"
              name="description">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Tabs.TabPane>
          {multiLang?.length &&
            multiLang.map((data, index) => (
              <Tabs.TabPane
                tab={
                  supportedLanguages.find(lang => lang.value === data.language)
                    ?.label
                }
                key={data?.language}>
                <Form.Item className={styles.field} label="Translated Title">
                  <Input
                    value={data?.title}
                    onChange={e =>
                      handleChangeTranslatedValues(
                        index,
                        e.target.value,
                        'title',
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  className={styles.field}
                  label="Translated Description">
                  <Input.TextArea
                    rows={4}
                    value={data?.description}
                    onChange={e =>
                      handleChangeTranslatedValues(
                        index,
                        e.target.value,
                        'description',
                      )
                    }
                  />
                </Form.Item>
              </Tabs.TabPane>
            ))}
        </Tabs>
        <Form.Item
          name="instruments"
          rules={[{required: true, message: 'This is Required'}]}
          className={styles.field}
          label="Instruments to Group">
          <Select
            showSearch
            mode="multiple"
            onSearch={handleSearch}
            filterOption={false}
            loading={loadingInstruments}
            placeholder="Search for Instrument"
            allowClear>
            {data?.getFinancialInstruments?.data?.map(instrument => (
              <Select.Option key={instrument?.id}>
                {instrument?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <SubmitButton
          label={isUpdate ? 'Save Changes' : 'Create Group'}
          disabled={loading}
        />
      </Form>
    </div>
  );
};
