import {gql} from '@apollo/client';

export const GET_ALL_COLLECTIONS = gql`
  query AdminGetAllCollections($filters: CollectionFilters) {
    adminGetAllCollections(filters: $filters) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAdminCollections {
        message
        data {
          collections {
            id
            iconUrl
            name
            industry {
              name
            }
            companies {
              id
            }
            status
            createdAt
          }
          pagination {
            page
            limit
            pages
            total
            hasPrevPage
            hasNextPage
            previous
            next
          }
          collectionOrders
        }
      }
    }
  }
`;

export const GET_COLLECTION_STATS = gql`
  query AdminGetCollectionStats($collectionId: ID!) {
    adminGetCollectionStats(collectionId: $collectionId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAdminCollectionStats {
        message
        data {
          totalOrders
          totalCompanies
          totalInvested
          totalUsers
          id
          name
        }
      }
    }
  }
`;

export const GET_COLLECTION_BY_ID = gql`
  query GetCollection($collectionId: ID!) {
    getCollection(collectionId: $collectionId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCollection {
        data {
          id
          name
          language
          description
          bannerUrl
          videoUrl
          ticker
          minCompanyCount
          iconUrl
          linkedCollectionGroups {
            id
          }
          industry {
            id
            name
          }
          currency
          stockExchange
          companies {
            id
            name
            maxCollectionUnit
            minCollectionUnit
            defaultCollectionUnits
            industry {
              id
              name
            }
            stock {
              symbol
              lastPrice
            }
            logoImgURL
          }
          orderType
          dabaScore {
            score
            pdfReport
            business
            industry
            market
            team
            strategyProduct
            accelerator
            riskChallenges
            analogus
            economicsAndFinance
            legalCompliance
            returnOutlook
            socialImpact
          }
          financialReturns {
            YTD
            today
          }
          status
          allowUserRemoveCompany
          multilang {
            name
            description
            bannerUrl
            videoUrl
            pdfReport
            language
          }
        }
      }
    }
  }
`;

export const GET_COLLECTION_ORDERS = gql`
  query GetOrders($input: OrderFilter) {
    getOrders(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrders {
        message
        data {
          user {
            lastName
            id
            firstName
            imageUrl
          }
          createdAt
          quantity
          fillValue
          price
        }
      }
    }
  }
`;

export const GET_ALL_COLLECTION_GROUPS = gql`
  query GetAllCollectionGroups($filters: CollectionGroupFilters) {
    getAllCollectionGroups(filters: $filters) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCollectionGroups {
        data {
          pagination {
            total
          }
          collectionGroups {
            id
            title
            language
            description
            imageUrl
            hasParentGroup
            isVisible
            collectionCount
            collections {
              id
              ticker
              name
            }
            linkedCollectionGroups {
              id
              title
            }
            isDeleted
            createdAt
            deletedAt
          }
        }
      }
    }
  }
`;

export const GET_COLLECTION_ICONS = gql`
  query Error {
    getAllCollectionIcons {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCollectionIcons {
        message
        data {
          id
          name
          url
        }
      }
    }
  }
`;

export const GET_COLLECTION_GROUP = gql`
  query GetCollectionGroup($collectionGroupId: ID!) {
    getCollectionGroup(collectionGroupId: $collectionGroupId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCollectionGroup {
        data {
          id
          title
          language
          description
          collections {
            id
          }
          imageUrl
          linkedCollectionGroups {
            id
          }
          hasParentGroup
          isVisible
          collectionCount
          multilang {
            title
            description
            language
          }
          isDeleted
          createdAt
          deletedAt
        }
      }
    }
  }
`;
