import {gql} from '@apollo/client';

export const CREATE_USER_TRADE = gql`
  mutation CreateTrade($input: CreateTradeInput!) {
    createTrade(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on Trade {
        id
        paymentMethod
        transactionUnits
      }
    }
  }
`;

export const UPDATE_USER_TRADE = gql`
  mutation UpdateTrade($tradeId: ID!, $input: UpdateTradeInput!) {
    updateTrade(tradeId: $tradeId, input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on Trade {
        id
        paymentMethod
      }
    }
  }
`;

export const DELETE_TRADE = gql`
  mutation DeleteTrade($tradeId: String!) {
    deleteTrade(tradeId: $tradeId) {
      ... on Error {
        message
        statusCode
      }
      ... on Trade {
        id
        paymentMethod
        transactionUnits
      }
    }
  }
`;

export const VOID_TRADE = gql`
  mutation VoidTrade($tradeId: ID!) {
    voidTrade(tradeId: $tradeId) {
      ... on Error {
        statusCode
        message
      }
      ... on Trade {
        id
      }
    }
  }
`;

export const CANCEL_TRADE = gql`
  mutation DeleteTrade($tradeId: String!) {
    deleteTrade(tradeId: $tradeId) {
      ... on Error {
        statusCode
        message
      }
      ... on Trade {
        userId
      }
    }
  }
`;

export const BUY_STOCK = gql`
  mutation BuyStock($input: StockOrderInput!) {
    buyStock(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrder {
        message
      }
    }
  }
`;

export const SELL_STOCK = gql`
  mutation SellStock($input: StockOrderInput!) {
    sellStock(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;

export const BUY_BOND = gql`
  mutation BuyBond($input: BondOrderInput!) {
    buyBond(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;

export const SELL_BOND = gql`
  mutation SellBond($input: BondOrderInput!) {
    sellBond(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;
