import {Button} from '@dabafinance/react-components';
import {Form, Select, Table} from 'antd';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styles from './stocks.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_STOCKS} from 'graphql/queries/stocks';
import {convertToCurrency} from 'utils/helper';
import DBModal from 'components/modal/modal';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {
  ADD_COMPANY_TO_STOCK_EXCHANGE,
  DELETE_STOCK_EXCHANGE,
} from 'graphql/mutations/stocks';
import {toast} from 'react-toastify';
import trashIcon from 'images/trash-can-outline.svg';
import {currencySymbols} from 'utils/mock';
import ViewOrders from './ViewOrders/ViewOrders';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const tabs = [
  {key: 'STOCKS', label: 'Stock Exchanges'},
  {key: 'ACTIVE', label: 'Open Orders'},
  {key: 'PENDING', label: 'Pending Orders'},
  {key: 'FILLED', label: 'Filled Orders'},
  {key: 'HOLD', label: 'Orders on Hold'},
  {key: 'PAST', label: 'Past Orders'},
  {key: 'EXPIRED', label: 'Expired Orders'},
];

const AdminStockListings = () => {
  const route = useLocation();
  const searchParamValue = new URLSearchParams(route.search).get('route');
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [stockId, setStockId] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [activeTab, setActiveTab] = useState('');
  const navigate = useNavigate();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.StockListings),
  );

  useEffect(() => {
    if (searchParamValue === 'order') {
      setActiveTab('ACTIVE');
    } else if (searchParamValue) {
      setActiveTab(searchParamValue);
    } else setActiveTab('STOCKS');
  }, [searchParamValue]);

  const {data, loading} = useQuery(GET_ALL_STOCKS, {
    errorPolicy: 'all',
    onError: () =>
      toast.error('Something went wrong when fetching all stock exchanges'),
  });
  const {data: getAllCompanies, loading: loadingCompanies} =
    useQuery(GET_ALL_STARTUPS);
  const [addCompanyToStockExchange] = useMutation(
    ADD_COMPANY_TO_STOCK_EXCHANGE,
  );
  const [removeStockExchange, {loading: loadingDelete}] = useMutation(
    DELETE_STOCK_EXCHANGE,
  );

  const handleOpenUpdateModal = (event, id) => {
    if (event.target.isConnected) {
      setStockId(id);
      setOpenModal(true);
    }
  };

  const handleOpenDeleteModal = (event, id) => {
    if (event.target.isConnected) {
      setStockId(id);
      setOpenDeleteModal(true);
    }
  };

  const stocksColumn = [
    {
      title: 'Stock Name',
      dataIndex: 'stockExchangeName',
      key: 'stockExchangeName',
      render: (text, record) => (
        <span
          className={styles.name}
          onClick={() =>
            navigate(`/dashboard/stock-listing/companies/${record?.code}`)
          }>
          {record?.stockExchangeName}
        </span>
      ),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Market Cap',
      dataIndex: 'totalMarketCapitalization',
      key: 'price',
      render: (action, record) => (
        <div style={{width: 'max-content'}}>
          {currencySymbols[record?.baseCurrencyCode]?.symbol}{' '}
          {convertToCurrency(record?.totalMarketCapitalization)}
        </div>
      ),
    },
    {
      title: 'Currency',
      dataIndex: 'baseCurrencyCode',
      key: 'currency',
    },
    {
      title: 'Number of Listings',
      dataIndex: 'numberOfListings',
      key: 'listings',
    },
    {
      title: 'Add Company',
      dataIndex: 'status',
      key: 'status',
      render: (action, record) => (
        <Button
          label="Add Company"
          disabled={!tabOperations?.update}
          onClick={e => handleOpenUpdateModal(e, record.id)}
        />
      ),
    },
    {
      title: 'Update',
      dataIndex: 'update',
      key: 'update',
      render: (action, record) => (
        <Button
          label="Update"
          type="secondary"
          disabled={!tabOperations?.update}
          onClick={() =>
            navigate(`/dashboard/stocks/update-stock/${record?.id}`)
          }
        />
      ),
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      render: (action, record) => (
        <div>
          {tabOperations?.delete && (
            <img
              src={trashIcon}
              className={styles.deleteIcon}
              alt=""
              onClick={e => handleOpenDeleteModal(e, record.id)}
            />
          )}
        </div>
      ),
    },
  ];

  const handleAddCompanyToStock = () => {
    addCompanyToStockExchange({
      variables: {
        stockExchangeId: stockId,
        companyId: selectedCompany?.split(';;')[2],
        ticker: selectedCompany?.split(';;')[1],
      },
    })
      .then(({data: {addCompanyToStockExchange}}) => {
        if (addCompanyToStockExchange.__typename === 'Error') {
          toast.error(
            `Something went wrong!!! - ${addCompanyToStockExchange.message} `,
          );
        } else {
          toast.success('Company added Successfully');
          setOpenModal(false);
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };

  const handleDeleteStockExchange = () => {
    removeStockExchange({
      variables: {
        removeStockExchangeId: stockId,
      },
      refetchQueries: [{query: GET_ALL_STOCKS}],
    })
      .then(({data: removeStockExchange}) => {
        if (removeStockExchange.__typename === 'Error') {
          toast.error(
            `Something went wrong!!! - ${removeStockExchange.message} `,
          );
        } else {
          toast.success('Stock Exchange deleted Successfully');
          setOpenDeleteModal(false);
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };

  return (
    <div className={styles.stocksContainer}>
      <h1>STOCK EXCHANGE LISTINGS</h1>
      <div className={styles.header}>
        <div>
          <div className={styles['details-toggler']}>
            {tabs.map(tab => (
              <span
                key={tab.key}
                className={activeTab === tab.key ? styles['active-toggle'] : ''}
                onClick={() => {
                  // setActiveTab(tab.key);
                  navigate(`?route=${tab.key}`);
                }}>
                {tab.label}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.section}></div>
      {activeTab === 'STOCKS' && (
        <div className={styles.stockSection}>
          <Button
            onClick={() => navigate('/dashboard/stocks/create-stock')}
            className={styles.button}
            label="Create Stock Exchange"
            disabled={!tabOperations?.create}
          />
        </div>
      )}
      <div>
        {activeTab === 'STOCKS' && (
          <Table
            columns={stocksColumn}
            dataSource={data?.getAllStocksExchange}
            loading={loading}
          />
        )}
        {['ACTIVE', 'PAST', 'PENDING', 'FILLED', 'HOLD', 'EXPIRED'].includes(
          activeTab,
        ) && <ViewOrders activeTab={activeTab} />}
      </div>

      <DBModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        content={
          <div>
            <h1>Add a Company to Stock Exchange</h1>
            <Form layout="vertical">
              <Form.Item label="Select Company" name="company">
                <Select
                  loading={loadingCompanies}
                  value={selectedCompany}
                  showSearch
                  onChange={e => setSelectedCompany(e)}>
                  {getAllCompanies?.getAllCompanies?.map(company => (
                    <Select.Option
                      key={`${company?.name};;${company?.ticker};;${company?.id}`}>
                      <div className="select-option-style">
                        <img src={company?.logoImgURL} alt="" />
                        <span>{company?.name}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <SubmitButton label="Add" handleClick={handleAddCompanyToStock} />
            </Form>
          </div>
        }
      />
      <DBModal
        isOpen={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        content={
          <div className={styles.deleteModal}>
            <h1>Delete Stock Exchange</h1>
            <span>Are you sure you want to delete this Exchange?</span>
            <div className={styles.deleteButtons}>
              <Button
                onClick={() => setOpenDeleteModal(false)}
                type="secondary"
                label="NO"
              />
              <Button
                disabled={loadingDelete}
                onClick={handleDeleteStockExchange}
                label="YES"
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export {AdminStockListings};
