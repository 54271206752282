import {DatePicker, Dropdown, Form, Input, Select} from 'antd';
import DBModal from 'components/modal/modal';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useState} from 'react';
import styles from './financial.module.scss';
import {walletCurrencies} from 'utils/mock';
import {useLazyQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import moment from 'moment';

const exportType = {
  'Wallet': {query: 'getWalletMetrics'},
  'Volume': {query: 'getVolumeMetrics'},
  'Revenue': {query: 'getRevenueMetrics'},
};

export const ExportFinancialButton = ({type, query, startDate, endDate}) => {
  const [modal, setModal] = useState({isOpen: false, type: 'NONE'});
  const [form] = Form.useForm();

  const [exportCall, {loading}] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
  });

  return (
    <div>
      <Dropdown
        menu={{
          items: [
            {
              key: 1,
              label: 'Export Data with Filters',
              onClick: () => setModal({isOpen: true, type: 'FILTERS'}),
            },
            {
              key: 2,
              label: 'Custom Data Export',
              onClick: () => setModal({isOpen: true, type: 'CUSTOM'}),
            },
          ],
        }}
        trigger="click">
        <div>
          <SubmitButton secondary={false} smallWidth label="Export" />
        </div>
      </Dropdown>
      <DBModal
        isOpen={modal.isOpen}
        handleClose={() => setModal({isOpen: false, type: 'NONE'})}
        content={
          <div className={styles.exportModal}>
            <h2>Export {type} Metrics</h2>
            <p>Note that this is exporting data based on selected filters</p>
            <Form
              onFinish={values => {
                const setStartDate =
                  modal.type === 'FILTERS' ? startDate : values?.startDate;
                const setEndDate =
                  modal.type === 'FILTERS' ? endDate : values?.endDate;

                exportCall({
                  variables: {
                    filters: {
                      export: true,
                      date: {
                        ...(!setStartDate && !setEndDate && {range: 'MONTHLY'}),
                        ...(setStartDate && {
                          startDate: moment(setStartDate).format(),
                        }),
                        ...(setEndDate && {
                          endDate: moment(setEndDate).format(),
                        }),
                      },
                      emails: [values.email],
                      currencies: values.currencies,
                    },
                  },
                })
                  .then(({data}) => {
                    const resolverType = data[exportType[type]?.query];
                    if (
                      resolverType?.statusCode === 400 ||
                      resolverType?.__typename === 'Error'
                    ) {
                      toast.error(resolverType.message);
                    } else {
                      toast.success(resolverType?.message);
                      form.resetFields(setModal({isOpen: false, type: 'NONE'}));
                    }
                  })
                  .catch(() =>
                    toast.error('Something went wrong when exporting data'),
                  );
              }}
              form={form}
              layout="vertical">
              <Form.Item
                rules={[
                  {required: true, type: 'email', message: 'Email is Required'},
                ]}
                className={styles.field}
                label="Email"
                name="email">
                <Input placeholder="dabaadmin@joindaba.com" />
              </Form.Item>
              {modal.type === 'CUSTOM' && (
                <>
                  <Form.Item
                    className={styles.field}
                    name="currencies"
                    label="Currencies">
                    <Select mode="multiple" allowClear>
                      {walletCurrencies
                        .filter(
                          item => !['XAF', 'GBP', 'NGN'].includes(item.key),
                        )
                        .map(data => (
                          <Select.Option key={data.key}>
                            {data.key}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Start Date" name="startDate">
                    <DatePicker
                      className={styles.field}
                      placeholder="Start Date"
                      showTime
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item label="End Date" name="endDate">
                    <DatePicker
                      className={styles.field}
                      placeholder="End Date"
                      showTime
                      allowClear
                    />
                  </Form.Item>
                </>
              )}
              <SubmitButton disabled={loading} label="Export Data" />
            </Form>
          </div>
        }
      />
    </div>
  );
};
