import {useCallback, useEffect, useState} from 'react';
import styles from './adminuser.module.scss';
import adminstyles from './adminuser_list.module.scss';
import {Search} from '@dabafinance/react-components';
import UserCard from './card/UserCard';
import pageNotFound from 'images/pageNotFound.png';
import {GET_ALL_USERS} from 'graphql/queries/users';
import {useQuery} from '@apollo/client';
import UserTable from './card/UserTable';
import UnverifiedUserTable from './card/unverifiedUser';
import GamerImage from 'images/gamer.png';
import {Pagination} from 'antd';
import {sanitizeInput} from 'utils/helper';
import filterIcon from 'icons/filter.svg';
import UsersFilter from './userDetails/UsersFIlter/UsersFilter';
import moment from 'moment';

const AdminUserList = ({
  userType,
  switchView,
  onSetStatistics,
  onLoadingUser,
  userTypeMeta,
  currentPage,
  operations,
  setCurrentPage,
  onPageReset,
  filterValues,
  setFilterValues,
}) => {
  const [searchData, setSearchData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [limit, setLimit] = useState(40);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const {data, loading, refetch} = useQuery(GET_ALL_USERS, {
    variables: {
      filters: {
        pagination: {
          page: 1,
          limit: 40,
        },
        sort: {
          order: 'DESC',
          field: 'createdAt',
        },
        user: {
          [userTypeMeta?.filterProperty]: userTypeMeta?.filterPropertyValue,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      onSetStatistics?.(data?.statistics?.statistics);
      setSearchData(data?.getAllUsers?.users);
    },
    errorPolicy: 'ignore',
  });
  useEffect(() => {
    handleFilter(filterValues);
  }, [limit]);

  useEffect(() => {
    setSearchTerm('');
    setFilterValues({});
  }, [userType]);

  const handleFilter = (values, page) => {
    let {
      kycStatus,
      depositStatus,
      nationality,
      city,
      country,
      profileCompletion,
      hasMadeReferral,
      startDate,
      endDate,
      walletTransactionType,
      hasMadeDeposit,
      hasMadeInvestment,
      walletTransactionStartDate,
      walletTransactionEndDate,
    } = values;

    if (startDate) {
      startDate = moment(startDate).format();
    }
    if (endDate) {
      endDate = moment(endDate).format();
    }
    if (walletTransactionStartDate) {
      walletTransactionStartDate = moment(walletTransactionStartDate).format();
    }
    if (walletTransactionEndDate) {
      walletTransactionEndDate = moment(walletTransactionEndDate).format();
    }
    setFilterValues(values);
    refetch({
      filters: {
        pagination: {
          page: page || currentPage,
          limit,
        },
        sort: {
          order: 'DESC',
          field: 'createdAt',
        },
        user: {
          [userTypeMeta?.filterProperty]:
            userTypeMeta?.filterPropertyValue ?? kycStatus,
          depositStatus,
          nationality,
          city,
          startDate,
          endDate,
          profileCompletion,
          walletTransactionType,
          hasMadeReferral,
          walletTransactionEndDate,
          walletTransactionStartDate,
          hasMadeDeposit,
          hasMadeInvestment,
          country,
          username: searchTerm,
        },
      },
    });
    setOpenFilterModal(false);
  };

  useEffect(() => {
    onLoadingUser(loading);
  }, [loading]);

  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
    handleFilter(filterValues, page);
  };

  /**
   * This is a debounce function that delays the execution of a function until a certain amount of time
   * has passed without the function being called again.
   * @returns A new function that wraps the original function passed as an argument and adds a debounce
   * functionality to it.
   */
  const debounce = func => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1200);
    };
  };

  const handleUserSearch = (
    searchTerm,
    pageLimit,
    existingFilters,
    typeMeta,
  ) => {
    // Check for double preceding whitespace or double whitespace
    let {
      kycStatus,
      depositStatus,
      nationality,
      city,
      country,
      startDate,
      endDate,
      profileCompletion,
      walletTransactionType,
      hasMadeReferral,
      hasMadeDeposit,
      hasMadeInvestment,
      walletTransactionStartDate,
      walletTransactionEndDate,
    } = existingFilters;

    if (startDate) {
      startDate = moment(startDate).format();
    }
    if (endDate) {
      endDate = moment(endDate).format();
    }

    if (walletTransactionStartDate) {
      walletTransactionStartDate = moment(walletTransactionStartDate).format();
    }
    if (walletTransactionEndDate) {
      walletTransactionEndDate = moment(walletTransactionEndDate).format();
    }

    const sanitizedValue = sanitizeInput(searchTerm);
    setFilterValues(values => ({...values, username: sanitizedValue}));
    setCurrentPage(1);
    onPageReset();
    refetch({
      filters: {
        pagination: {
          page: 1,
          limit: pageLimit,
        },
        sort: {
          order: 'DESC',
          field: 'createdAt',
        },
        user: {
          [typeMeta?.filterProperty]:
            typeMeta?.filterPropertyValue ?? kycStatus,
          username: sanitizedValue || '',
          depositStatus,
          nationality,
          city,
          startDate,
          endDate,
          profileCompletion,
          hasMadeReferral,
          walletTransactionType,
          walletTransactionEndDate,
          walletTransactionStartDate,
          hasMadeDeposit,
          hasMadeInvestment,
          country,
        },
      },
    });
  };

  const optimizedVersion = useCallback(debounce(handleUserSearch), []);

  const handleSearch = term => {
    if (term) {
      const newList = searchData.filter(data => {
        return Object.values(data)
          .join(' ')
          .toLowerCase()
          .includes(term.toLowerCase());
      });
      setSearchData(newList);
    }
  };

  const RenderListTable = () => {
    if (['ALL', 'VERIFIED', 'PENDING'].includes(userType)) {
      return (
        <UserTable
          loading={loading}
          className={styles['user-table']}
          data={searchData}
          operations={operations}
          onSearch={handleSearch}
          pagination={false}
        />
      );
    }

    return (
      <UnverifiedUserTable
        loading={loading}
        data={searchData}
        operations={operations}
        onSearch={handleSearch}
        className={styles['user-table']}
        pagination={false}
      />
    );
  };

  return (
    <div className={adminstyles.wrapper}>
      <div className={styles['investors-header-grid']}>
        <div className={styles['title']}>{userTypeMeta.labelTitle}</div>
        <div className={styles['nav-buttons']}>
          <div className={styles['page-number']}>{currentPage}</div>
        </div>
        <div className={styles.filters}>
          <Search
            className={styles['search-bar']}
            placeholder="Name, Username, Email(s), Account No."
            onChange={e => {
              setSearchTerm(e);
              optimizedVersion(e, limit, filterValues, userTypeMeta);
            }}
            id={'user-search'}
          />
          {userType !== 'ACCREDITED' && (
            <>
              <div
                onClick={() => setOpenFilterModal(!openFilterModal)}
                className={styles.filter}>
                <h2>Filter</h2>
                <img src={filterIcon} alt="" />
              </div>
              <div
                className={styles.filterModal}
                style={{display: openFilterModal ? 'block' : 'none'}}>
                <UsersFilter
                  handleClose={() => setOpenFilterModal(false)}
                  handleFilter={handleFilter}
                  activePage={userType}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {switchView ? (
        loading ? (
          <div className={styles['users-card']}>
            {Array(8)
              .fill('')
              .map((data, key) => (
                <div key={key} className={styles.loadingSkeleton}>
                  {data}
                </div>
              ))}
          </div>
        ) : (
          <div className={styles['users-card']}>
            {searchData?.length ? (
              searchData?.map((data, index) => (
                <div key={index}>
                  <UserCard
                    id={data?.id}
                    firstName={data?.firstName}
                    lastName={data?.lastName}
                    operations={operations}
                    accredited={data?.investorProfile?.isAccredited}
                    tab="All Users"
                    imageUrl={data?.imageUrl ? data?.imageUrl : GamerImage}
                  />
                </div>
              ))
            ) : (
              <div className={styles['no-update']}>
                <img src={pageNotFound} alt="" />
                <h1>No User Found</h1>
              </div>
            )}
          </div>
        )
      ) : (
        <RenderListTable />
      )}
      <Pagination
        current={currentPage}
        onChange={e => handlePageChange(e)}
        showQuickJumper
        pageSize={limit}
        total={data?.getAllUsers?.totalDocs}
        className="ant-table-pagination"
        showSizeChanger
        onShowSizeChange={(_, size) => {
          setLimit(size);
        }}
      />
    </div>
  );
};

export {AdminUserList};
