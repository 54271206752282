import {useQuery} from '@apollo/client';
import styles from './single/singleSavingsProduct.module.scss';
import {GET_SAVINGS_PRODUCTS} from 'graphql/queries/savings';
import {Dropdown, Table, Tag} from 'antd';
import moment from 'moment';
import dotsIcon from 'images/dots.svg';
import {useNavigate} from 'react-router-dom';
import ClockIcon from 'icons/clockBtn.svg';
import CheckIcon from 'icons/checkBtn.svg';
import WalletIcon from 'icons/walletBtn.svg';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const SavingsProductsOverview = () => {
  const navigate = useNavigate();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.SAVINGS),
  );

  const {data, loading} = useQuery(GET_SAVINGS_PRODUCTS, {
    variables: {
      input: {
        limit: 100,
        page: 1,
      },
    },
  });

  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Time Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record) => (
        <span>
          {record?.createdAt
            ? moment(record.createdAt).format('DDD MMM, YYYY - hh:mm A')
            : ''}
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => (
        <Tag color={record?.savingsType === 'FLEXIBLE' ? 'cyan' : 'geekblue'}>
          {record?.savingsType}
        </Tag>
      ),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      render: (text, record) => <Tag>{record?.config?.allowedCurrency}</Tag>,
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                key: 1,
                label: 'View Info',
                disabled: !tabOperations?.view,
                onClick: () =>
                  navigate(`/dashboard/savings/product/${record?.id}`),
              },
              {
                key: 2,
                disabled: !tabOperations?.update,
                label: 'View & Update Product',
                onClick: () =>
                  navigate(`/dashboard/savings/product/update/${record?.id}`),
              },
            ],
          }}>
          <img src={dotsIcon} />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.rootProduct}>
      <div>
        <h1>Savings Products</h1>
      </div>
      <CardResults data={data?.getSavingsProducts?.statistics} />
      <div>
        <h1>Savings Products List</h1>
        <Table
          columns={columns}
          loading={loading}
          dataSource={data?.getSavingsProducts?.data}
        />
      </div>
    </div>
  );
};

const CardResults = ({data}) => {
  const plans = [
    {
      label: 'Total Amount Saved',
      amount: data?.totalBalance?.toLocaleString(),
      image: <img src={ClockIcon} alt="" />,
    },
    {
      label: 'Active savings',
      amount: data?.activeSavingsCount,
      image: <img src={CheckIcon} alt="" />,
    },
    {
      label: 'Total Products',
      amount: data?.totalProducts,

      image: <img src={WalletIcon} alt="" />,
    },
  ];

  return (
    <div className={styles.percentages}>
      {plans.map((data, index) => (
        <div className={styles.percentage} key={index}>
          <div className={styles.percentImage}>{data.image}</div>

          <div className={styles.percentageLabel}>
            <span>{data.label}</span>
            <p>{data.amount}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SavingsProductsOverview;
