import {useMutation, useQuery} from '@apollo/client';
import styles from './savingsOverview.module.scss';
import usersLogo from 'images/savingUserIcon.svg';
import companiesLogo from 'images/savingsBag.svg';
import productsLogo from 'images/savingsWallet.svg';
import {GET_SAVINGS_OVERVIEW_QUERIES} from 'graphql/queries/savings';
import {toast} from 'react-toastify';
import {Dropdown, Spin, Table, Tag} from 'antd';
import {useState} from 'react';
import {Button} from '@dabafinance/react-components';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import dotsIcon from 'images/dots.svg';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {updateSavingsOrganizationStatus} from '../companies/savingsCompanyActions';
import {UPDATE_SAVINGS_COMPANY_STATUS} from 'graphql/mutations/savings';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const SavingsOverview = () => {
  const [updateModal, setUpdateModal] = useState({isOpen: false});
  const [cardsData, setCardsData] = useState({});
  const navigate = useNavigate();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.SAVINGS),
  );

  const {loading, data, refetch} = useQuery(GET_SAVINGS_OVERVIEW_QUERIES, {
    errorPolicy: 'all',
    variables: {
      filter: {
        page: 1,
        limit: 4,
      },
    },
    onError: error =>
      toast.error('There was an Error loading this Page data' - error),
    onCompleted: data => {
      setCardsData(data?.getGeneralStatistics?.data);
    },
  });

  const [updateOrganization, {loading: loadingUpdate}] = useMutation(
    UPDATE_SAVINGS_COMPANY_STATUS,
  );

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Organization Name',
      render: (text, record) => (
        <div className={styles.imageProfile}>
          <img src={record?.logo} alt="" />
          <span>{record?.name}</span>
        </div>
      ),
    },
    {
      dataIndex: 'Industry',
      key: 'Industry',
      title: 'Industry',
      render: (text, record) => <span>{record?.industry?.name || ''}</span>,
    },
    {
      dataIndex: 'userCount',
      key: 'userCount',
      title: 'No. of Users',
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      render: (text, record) => (
        <Tag color={record?.status === 'ACTIVE' ? 'green' : 'red'}>
          {record?.status}
        </Tag>
      ),
    },

    {
      dataIndex: 'Date Added',
      key: 'DateAdded',
      title: 'Date Added',
      render: (text, record) => (
        <span>
          {' '}
          {record?.createdAt
            ? moment(record?.createdAt).format('DD MMM, YYYY hh:mm A')
            : ''}
        </span>
      ),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: 'Actions',
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                label: 'View Info',
                key: 1,
                disabled: !tabOperations?.view,
                onClick: () =>
                  navigate(`/dashboard/savings/company/${record?.id}`),
              },
              {
                label: 'Update Status',
                key: 2,
                disabled: !tabOperations?.update,
                onClick: () =>
                  setUpdateModal({
                    isOpen: true,
                    data: {id: record?.id, status: record?.status},
                  }),
              },
            ],
          }}>
          <div>
            <img src={dotsIcon} alt="" />
          </div>
        </Dropdown>
      ),
    },
  ];

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <h1>Overview</h1>
      <div className={styles.topCards}>
        <div className={styles.card}>
          <img src={usersLogo} alt="" />
          <div>
            <p>Active Users</p>
            <h1>{cardsData?.totalUsers}</h1>
          </div>
        </div>
        <div className={styles.card}>
          <img src={companiesLogo} alt="" />
          <div>
            <p>Organizations</p>
            <h1>{cardsData?.totalOrganizations}</h1>
          </div>
        </div>
        <div className={styles.card}>
          <img src={productsLogo} alt="" />
          <div>
            <p>Saving Products</p>
            <h1>{cardsData?.totalSavingsProducts}</h1>
          </div>
        </div>
      </div>
      {
        //** CHART SECTION */
      }
      <div>
        <div className={styles.tableHeader}>
          <h1>Recent Organizations</h1>
          <Button
            onClick={() => navigate('/dashboard/savings/companies')}
            label="View All"
          />
        </div>
        <Table
          pagination={false}
          dataSource={data?.getOrganizations?.data}
          columns={columns}
        />
        <ConfirmModal
          openModal={updateModal.isOpen}
          setOpenModal={() => setUpdateModal({isOpen: false})}
          title="Update Organization Status"
          confirmText={`You are updating the status of this Organization to ${
            updateModal?.data?.status === 'ACTIVE' ? 'IN-ACTIVE' : 'ACTIVE'
          }`}
          loading={loadingUpdate}
          handleOperation={() =>
            updateSavingsOrganizationStatus({
              id: updateModal?.data?.id,
              status:
                updateModal?.data?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
              updateOrganization,
              onClose: () => setUpdateModal({isOpen: false}),
              refetch,
            })
          }
        />
      </div>
    </div>
  );
};

export default SavingsOverview;
