import {AllUserReward} from 'pages/rewardSchemes/rewardSchemeTabs/userReward';
import {useNavigate, useParams} from 'react-router-dom';
import styles from './userReferral.module.scss';
import {useState} from 'react';
import {Pagination, Table} from 'antd';
import {GET_USER_INVITES} from 'graphql/queries/userReferral';
import {useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import moment from 'moment';
import gamer from 'images/gamer.png';

export const SingleUserReferral = () => {
  const {id} = useParams();
  const [type, setType] = useState('reward');
  return (
    <div>
      <div className={styles['tab-container']}>
        <span
          onClick={() => setType('reward')}
          className={type === 'reward' ? styles['active-toggle'] : ''}>
          REWARD
        </span>
        <span
          onClick={() => setType('referral')}
          className={type === 'referral' ? styles['active-toggle'] : ''}>
          REFERRAL
        </span>
      </div>
      {type === 'reward' ? (
        <AllUserReward userId={id} />
      ) : (
        <>
          <AllSingleUserReferral id={id} />
        </>
      )}
    </div>
  );
};

const AllSingleUserReferral = ({id}) => {
  const [invitedUserCurrentPage, setInvitedUserCurrentPage] = useState(1);
  const [invitedUserLimit, setInvitedUserLimit] = useState(10);

  const navigate = useNavigate();

  const {
    refetch: refetchUsers,
    data: userInvites,
    loading: userInviteListLoading,
  } = useQuery(GET_USER_INVITES, {
    variables: {
      user: id,
      page: 1,
      limit: 10,
    },
    onError: error => toast.error(error),
    notifyOnNetworkStatusChange: true,
  });

  const inviteColumns = [
    {
      title: 'Name',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <div
            onClick={() => navigate(`/dashboard/user/${record?.id}`)}
            className={styles['record-label']}>
            {record?.imageUrl ? (
              <img src={record?.imageUrl} alt="" />
            ) : (
              <img src={gamer} alt="" />
            )}
            <h4 style={{width: 100}}>
              {record?.firstName} {record?.lastName}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => <span>{record?.email}</span>,
    },
    {
      title: 'Date Invited',
      dataIndex: 'invitedDate',
      key: 'invitedDate',
      render: (text, record) => (
        <p>{moment(record?.invitedDate).format('DD, MMMM YYYY')}</p>
      ),
    },
  ];

  const handleUserLimitChange = size => {
    if (size === invitedUserLimit) return;
    setInvitedUserLimit(size);
    setInvitedUserCurrentPage(1);
    refetchUsers({page: 1, limit: size});
  };

  const handleChangeInvitedUserPage = page => {
    if (page === invitedUserCurrentPage) return;
    setInvitedUserCurrentPage(page);
    refetchUsers({page: page, limit: invitedUserLimit});
  };

  return (
    <div>
      <div>
        <div className={styles.totalReferral}>
          <h2>User Referrals</h2>
          <h3>
            {' '}
            {userInvites?.getUserInvites?.pagination?.total || 0} User(s)
            Referred
          </h3>
        </div>
        <Table
          loading={userInviteListLoading}
          columns={inviteColumns}
          dataSource={userInvites?.getUserInvites?.userReferral}
          pagination={false}
        />
        <Pagination
          current={invitedUserCurrentPage}
          onChange={page => handleChangeInvitedUserPage(page)}
          total={userInvites?.getUserInvites?.pagination?.total}
          showQuickJumper
          className="ant-table-pagination"
          showSizeChanger
          pageSize={invitedUserLimit}
          onShowSizeChange={(_, size) => handleUserLimitChange(size)}
        />
      </div>
    </div>
  );
};
