/* eslint-disable no-unreachable */
import {Form, Input} from 'antd';
import styles from './mobileSetting.module.scss';
import {FeeSchedule} from 'components/feeSchedule/FeeSchedule';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation} from '@apollo/client';
import {TRANSLATE_TEXT} from 'graphql/mutations/notifications';
import {Button} from '@dabafinance/react-components';
import {toast} from 'react-toastify';
import {UPDATE_APP_CONFIGURATION} from 'graphql/queries/mobileSettings';
import {useEffect, useState} from 'react';

const discountTabs = {
  STOCK: {
    name: 'STOCK',
    value: 'STOCK',
    enfieldName: 'enStockDescription',
    frFieldName: 'frStockDescription',
    enLabel: 'Stock Description (EN)',
    frLabel: 'Stock Description (FR)',
    feeName: 'stocksFeeSchedule',
  },
  BOND: {
    name: 'BOND',
    value: 'BOND',
    enfieldName: 'enBondDescription',
    frFieldName: 'frBondDescription',
    enLabel: 'Bond Description (EN)',
    frLabel: 'Bond Description (FR)',
    feeName: 'bondFeeSchedule',
  },
  VENTURE_FUND: {
    name: 'VENTURE FUND',
    value: 'VENTURE_FUND',
    enfieldName: 'enVentureFundDescription',
    frFieldName: 'frVentureFundDescription',
    enLabel: 'Venture Fund Description (EN)',
    frLabel: 'Venture Fund Description (FR)',
    feeName: 'ventureFundFeeSchedule',
  },
  VENTURE: {
    name: 'VENTURE',
    value: 'VENTURE',
    enfieldName: 'enVentureDescription',
    frFieldName: 'frVentureDescription',
    enLabel: 'Venture Description (EN)',
    frLabel: 'Venture Description (FR)',
    feeName: 'ventureFeeSchedule',
  },
  IPO: {
    name: 'IPO',
    value: 'IPO',
    enfieldName: 'enIPODescription',
    frFieldName: 'frIPODescription',
    enLabel: 'IPO Description (EN)',
    frLabel: 'IPO Description (FR)',
    feeName: 'ipoFeeSchedule',
  },
};

export const ProDiscountSettings = ({refetch, data}) => {
  const [form] = Form.useForm();
  const [currentTab, setCurrentTab] = useState('STOCK');
  const description = Form.useWatch(discountTabs[currentTab].enfieldName, form);

  const [translateText, {loading: loadingTranslation}] =
    useMutation(TRANSLATE_TEXT);
  const [updateAppConfig, {loading}] = useMutation(UPDATE_APP_CONFIGURATION, {
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (data) {
      const mappedValues = mapDataToFormValues(data);
      form.setFieldsValue(mappedValues);
    }
  }, [data, form]);

  const mapDataToFormValues = rawData => {
    const formValues = {};
    Object.keys(discountTabs).forEach(tabKey => {
      const tab = discountTabs[tabKey.toUpperCase()];
      const tabData = rawData[tabKey];

      if (tabData) {
        formValues[tab.enfieldName] = tabData.description?.EN || '';
        formValues[tab.frFieldName] = tabData.description?.FR || '';
        formValues[tab.feeName] = tabData.feeSchedule || [];
      }
    });
    return formValues;
  };

  const handleTranslation = () => {
    let sourceText = form.getFieldValue(discountTabs[currentTab].enfieldName);

    if (!sourceText) return;

    translateText({
      variables: {
        sourceLang: 'EN',
        targetLang: 'FR',
        sourceText,
      },
    })
      .then(({data: {translateText}}) => {
        if (
          translateText.statusCode === 400 ||
          translateText.__typename === 'Error'
        ) {
          toast.error('Error Translating Text', translateText.message);
        } else {
          toast.success('Translated Successfully');
          form.setFieldValue(
            discountTabs[currentTab].frFieldName,
            translateText?.targetText,
          );
        }
      })
      .catch(err => toast.error(err));
  };

  const handleSubmit = values => {
    let pro = {};
    let existingData = data
      ? Object.keys(data)
          .filter(key => key !== currentTab)
          .reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
          }, {})
      : {};

    Object.keys(discountTabs)
      .filter(key => key === currentTab)
      .forEach(tabKey => {
        const tab = discountTabs[tabKey];
        const descriptionEN = values[tab.enfieldName];
        const descriptionFR = values[tab.frFieldName];
        const feeSchedule = values[tab.feeName]?.map(fee => ({
          feeName: fee?.feeName,
          feeType: fee?.feeType,
          ...(fee?.fixedFeeAmount && {
            fixedFeeAmount: Number(fee?.fixedFeeAmount),
          }),
          ...(fee?.variableFeeAmount && {
            variableFeeAmount: Number(fee?.variableFeeAmount),
          }),
        }));

        // Only include tab if description or feeSchedule is updated
        if (descriptionEN || descriptionFR || feeSchedule?.length) {
          pro[tabKey] = {
            description: {
              ...(descriptionEN && {EN: descriptionEN}),
              ...(descriptionFR && {FR: descriptionFR}),
            },
            ...(feeSchedule?.length && {feeSchedule}),
          };
        }
      });

    updateAppConfig({
      variables: {
        data: {
          discountSettings: {
            pro: {
              ...existingData,
              ...pro,
            },
          },
        },
      },
    })
      .then(({data: {updateAppConfig}}) => {
        if (
          updateAppConfig.statusCode === 400 ||
          updateAppConfig.__typename === 'Error'
        ) {
          toast.error('Error Updating Config', updateAppConfig.message);
        } else {
          refetch();
          toast.success('Updated Successfully');
        }
      })
      .catch(err => toast.error(err.message || 'An error occurred'));
  };

  const isFieldRequired = (tabKey, formValues) => {
    const tab = discountTabs[tabKey];
    const enValue = formValues[tab.enfieldName];
    const frValue = formValues[tab.frFieldName];
    const feeSchedule = formValues[tab.feeName];

    const isValid = !!(
      enValue ||
      frValue ||
      (feeSchedule && feeSchedule.length > 0)
    );
    return isValid;
  };

  return (
    <div className={styles.body}>
      <h1>Daba Pro Plan Discount Settings</h1>

      <div className={styles.tip}>
        <p>
          <strong>NOTE: </strong>
          Discount works as a subtracted reduction from the total fee. This
          means if a variable fee of 3% is already set on the instrument a
          variable fee discount of 1% will simply bring the overall variable fee
          to 2%.{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.notion.so/investondaba/Stock-Trading-f60b8d750d504d08a6c8c7e17736c986?pvs=4#f2b60dfc93dc48c2a89ce28cfba96867">
            Link to Documentation
          </a>
        </p>
      </div>
      <div className={styles['details-toggler']}>
        {Object.keys(discountTabs).map(tab => (
          <span
            onClick={() => setCurrentTab(tab)}
            className={tab === currentTab ? styles['active-toggle'] : ''}
            key={tab}>
            {discountTabs[tab]?.name}
          </span>
        ))}
      </div>
      <Form onFinish={handleSubmit} form={form} layout="vertical">
        <div>
          <div className={styles.translateField}>
            <Form.Item
              rules={[
                ({getFieldValue}) => ({
                  validator(_, value) {
                    const formValues = getFieldValue();
                    const isRequired = isFieldRequired(currentTab, formValues);
                    if (isRequired && !value) {
                      return Promise.reject(
                        new Error('This field is required'),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              className={styles.proField}
              name={discountTabs[currentTab].enfieldName}
              label={discountTabs[currentTab].enLabel}>
              <Input.TextArea rows={2} />
            </Form.Item>
            {description?.length > 5 && (
              <Button
                type="secondary"
                disabled={loadingTranslation}
                label="Translate"
                onClick={handleTranslation}
              />
            )}
          </div>
          <Form.Item
            rules={[
              ({getFieldValue}) => ({
                validator(_, value) {
                  const formValues = getFieldValue();
                  const isRequired = isFieldRequired(currentTab, formValues);
                  if (isRequired && !value) {
                    return Promise.reject(new Error('This field is required'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            className={styles.proField}
            name={discountTabs[currentTab].frFieldName}
            label={discountTabs[currentTab].frLabel}>
            <Input.TextArea rows={2} />
          </Form.Item>
          <h2>{discountTabs[currentTab]?.name} Discount Schedule</h2>
          <FeeSchedule
            feeName={discountTabs[currentTab]?.feeName}
            isOfStockExchange
            form={form}
          />
        </div>
        <SubmitButton disabled={loading} label="Update" />
      </Form>
    </div>
  );
};
