import {GET_ALL_COLLECTION_GROUPS} from 'graphql/queries/collections';

const {toast} = require('react-toastify');

const onTranslateContent = async (query, translateProps) => {
  const {sourceLang, targetLang, targetText1, targetText2} = translateProps;
  try {
    const {data} = await query({
      variables: {
        translations: [
          {sourceLang, sourceText: targetText1, targetLang},
          {sourceLang, sourceText: targetText2, targetLang},
        ],
        treatment: 'html',
      },
    });
    return {
      title: data.translateText.translations[0],
      description: data.translateText.translations[1],
      language: targetLang,
    };
  } catch (error) {
    toast.error(`Something went wrong, Error: ${error}`);
  }
};

const handleTranslateCollectionGroups = async (
  translateProps,
  multilang,
  setMultilang,
  translateText,
) => {
  const {targetLang: selectedTranslationLanguage} = translateProps;
  try {
    const newValues = [...multilang];

    //Check if translation exists
    if (newValues.length && newValues[0]) {
      // Check if we're trying to translate in the same language
      const languageExists = newValues.some(
        data => data.language === selectedTranslationLanguage,
      );
      if (languageExists) {
        const updateTranslation = await onTranslateContent(
          translateText,
          translateProps,
        );
        const translationIndex = newValues.findIndex(
          data => data.language === selectedTranslationLanguage,
        );
        newValues[translationIndex].title = updateTranslation.title;
        newValues[translationIndex].description = updateTranslation.description;
        newValues[translationIndex].language = updateTranslation.language;
        setMultilang(newValues);
      } else {
        const updateTranslation = await onTranslateContent(
          translateText,
          translateProps,
        );

        newValues[0].title = updateTranslation.title;
        newValues[0].description = updateTranslation.description;
        newValues[0].language = updateTranslation.language;
        setMultilang(newValues);
      }
    } else {
      const triggerTranslation = await onTranslateContent(
        translateText,
        translateProps,
      );
      if (!triggerTranslation) {
        return toast.error('Something went wrong when Translating');
      }
      newValues.push(triggerTranslation);
      setMultilang(newValues);
    }
  } catch (error) {
    toast.error(`Something went wrong! Error: ${error}`);
  }
};

const handleSubmitCollectionGroups = async (
  values,
  multiLang,
  image,
  createResolver,
  updateResolver,
  navigate,
  id,
) => {
  let resolver;
  if (id) {
    resolver = updateResolver;
  } else {
    resolver = createResolver;
  }

  const {
    language,
    title,
    description,
    collections,
    linkedCollectionGroups,
    isVisible,
  } = values;
  try {
    await resolver({
      variables: {
        ...(id && {collectionGroupId: id}),
        input: {
          language,
          title,
          description,
          collections,
          linkedCollectionGroups,
          isVisible: isVisible == null ? false : isVisible,
          imageUrl: image,
          multilang: multiLang?.map(data => ({
            language: data?.language,
            title: data?.title,
            description: data?.description,
          })),
        },
      },
      refetchQueries: [
        {
          query: GET_ALL_COLLECTION_GROUPS,
          variables: {filters: {paginate: false}},
        },
      ],
    })
      .then(({data}) => {
        const type =
          data?.[id ? 'updateCollectionGroup' : 'createCollectionGroup'];
        if (type.__typename === 'Error') {
          throw new Error(type.message);
        } else {
          toast.success('Action Successfully');
          navigate(-1);
        }
      })
      .catch(error => {
        toast.error(`GraphQL Error: ${error.message}`);
      });
  } catch (error) {
    toast.error(`Something went wrong, Error: ${error.message}`);
  }
};

export {handleTranslateCollectionGroups, handleSubmitCollectionGroups};
