import {useMutation, useQuery} from '@apollo/client';
import {Collapse, Spin, Switch, Tooltip} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {UPDATE_PROFILE} from 'graphql/mutations/user';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import styles from './notificationPreference.module.scss';
import {GET_USER_NOTIFICATION_PREFERENCE} from 'graphql/queries/notifications';
import {UPDATE_USER_NOTIFICATION_PREFERENCE} from 'graphql/mutations/notifications';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

const NotificationPreference = () => {
  const {id} = useParams();
  const [userPreference, setUserPreference] = useState([]);

  const {data: getUserPreference, loading} = useQuery(
    GET_USER_NOTIFICATION_PREFERENCE,
    {
      variables: {
        input: {
          userId: id,
        },
      },
    },
  );

  const pageOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Users),
  );

  useEffect(() => {
    setUserPreference(getUserPreference?.getUserNotificationPreferences?.data);
  }, [getUserPreference]);

  const [updateProfile, {loading: updateNotification}] =
    useMutation(UPDATE_PROFILE);
  const [updatePreference, {loading: updateNotificationPreference}] =
    useMutation(UPDATE_USER_NOTIFICATION_PREFERENCE);

  const handlePreferenceChange = (index, i, e) => {
    setUserPreference(prevPreferences => {
      const newValues = [...prevPreferences];
      const channels = [...newValues[index].channels];
      channels[i] = {...channels[i], enabled: e};
      newValues[index] = {...newValues[index], channels};
      return newValues;
    });
  };

  const {Panel} = Collapse;

  const handleUpdate = () => {
    updatePreference({
      variables: {
        input: {
          preferences: userPreference,
          userId: id,
        },
      },
    }).then(({data: updateUserNotificationPreferences}) => {
      if (updateUserNotificationPreferences.__typename === 'Error') {
        toast.error(updateProfile.message);
      } else {
        toast.success('Notification preference updated');
      }
    });
  };

  return loading ? (
    <div className={styles.loading}>
      <Spin size="large" />
      <h1>Loading...</h1>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Notification Preference</h1>
      </div>
      <div className={styles.row}>
        <Collapse defaultActiveKey={['1']} ghost>
          {userPreference?.map((item, index) => (
            <Panel
              header={`${item?.name} Notification`}
              key={index}
              className={styles.pannel}
              style={{color: '#16054e'}}>
              {item?.channels?.map((channel, i) => (
                <div key={`${i}-${channel?.key}`} className={styles.switches}>
                  <div className={styles['form-group']}>
                    <label>Receive {channel?.name} Notification: </label>
                    {channel?.mandatory ? (
                      <Tooltip title="This is Mandatory and cannot be updated">
                        <Switch
                          checked={channel?.enabled}
                          disabled={channel?.mandatory}
                          onChange={e => handlePreferenceChange(index, i, e)}
                        />
                      </Tooltip>
                    ) : (
                      <Switch
                        checked={channel?.enabled}
                        disabled={channel?.mandatory}
                        onChange={e => handlePreferenceChange(index, i, e)}
                      />
                    )}
                  </div>
                  <hr />
                </div>
              ))}
            </Panel>
          ))}
        </Collapse>
      </div>
      {pageOperations?.update && (
        <SubmitButton
          handleClick={handleUpdate}
          disabled={updateNotification || updateNotificationPreference}
          label="Update Preference"
        />
      )}
    </div>
  );
};

export {NotificationPreference};
