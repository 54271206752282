import {useMutation} from '@apollo/client';
import {DatePicker, Form, InputNumber, Select, Switch} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {UPDATE_APP_CONFIGURATION} from 'graphql/queries/mobileSettings';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import styles from './mobileSetting.module.scss';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const ReviewSettings = ({data, refetch, inAppData}) => {
  const [endDate, setEndDate] = useState('');
  const [profileCompletion, setProfileCompletion] = useState('');
  const [investedInCampaign, setInvestedInCampaign] = useState('');
  const [form] = Form.useForm();

  const [updateAppConfig, {loading}] = useMutation(UPDATE_APP_CONFIGURATION);

  useEffect(() => {
    if (inAppData) {
      console.log(inAppData);
      form.setFieldsValue({
        isEnabled: inAppData?.isEnabled,
        maxPrompts: inAppData?.frequencyControl?.maxPrompts,
        daysInterval: inAppData?.frequencyControl?.daysInterval,
        cooldownPeriod: inAppData?.frequencyControl?.cooldownPeriod,
        hasMadeInvestment: inAppData?.filters?.hasMadeInvestment,
      });
    }
  }, [inAppData]);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.PlatformSettings),
  );
  useEffect(() => {
    setEndDate(data?.reviewPeriodEnd);
    setProfileCompletion(data?.filters?.hasCompletedProfile ? 'YES' : 'NO');
    setInvestedInCampaign(
      data?.filters?.hasInvestedInAnyCampaign ? 'YES' : 'NO',
    );
  }, [data]);

  const handleSaveChanges = () => {
    updateAppConfig({
      variables: {
        data: {
          platformReviewSettings: {
            reviewPeriodEnd: endDate,
            filters: {
              hasCompletedProfile: profileCompletion === 'YES',
              hasInvestedInAnyCampaign: investedInCampaign === 'YES',
            },
          },
        },
      },
    })
      .then(({data: {updateAppConfig}}) => {
        if (updateAppConfig.__typename === 'Error') {
          toast.error(updateAppConfig.message);
        } else {
          toast.success('Updated Successfully');
          refetch();
        }
      })
      .catch(error => toast.error(error));
  };

  return (
    <div className={styles['body']}>
      <div>
        <div className={styles.filterSection}>
          <h1>Platform SETTINGS</h1>
          <span>
            This displays a prompt in the Users App, Alerting them to review our
            App in various stores
          </span>
        </div>
        <Form onFinish={handleSaveChanges} layout="vertical">
          <Form.Item
            initialValue={
              data?.reviewPeriodEnd
                ? moment(data?.reviewPeriodEnd)
                    .endOf('month')
                    .format('YYYY-MM-DD')
                : ''
            }
            name="date"
            label="Prompt Display Stop Date">
            <DatePicker
              value={endDate}
              defaultValue={
                data?.reviewPeriodEnd
                  ? moment(data?.reviewPeriodEnd)
                      .endOf('month')
                      .format('YYYY-MM-DD')
                  : ''
              }
              onChange={e =>
                setEndDate(moment(e).endOf('month').format('YYYY-MM-DD'))
              }
              picker="month"
              disabledDate={current => {
                return current < moment().add(0, 'month');
              }}
              format={'MMMM'}
              style={{width: 300}}
            />
          </Form.Item>
          <div>
            <h2>Who gets to see this?</h2>
          </div>
          <Form.Item
            name="profileCompletion"
            label="Users That have not completed their Profile">
            <Select
              value={profileCompletion}
              onChange={e => setProfileCompletion(e)}
              placeholder="Select an Option"
              defaultValue={data?.filters?.hasCompletedProfile ? 'YES' : 'NO'}
              style={{width: 300}}>
              <Select.Option key={'YES'}>Yes</Select.Option>
              <Select.Option key={'NO'}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="investedInCampaign"
            label="Users that have invested in a Campaign">
            <Select
              value={investedInCampaign}
              onChange={e => setInvestedInCampaign(e)}
              placeholder="Select an Option"
              defaultValue={
                data?.filters?.hasInvestedInAnyCampaign ? 'YES' : 'NO'
              }
              style={{width: 300}}>
              <Select.Option key={'YES'}>Yes</Select.Option>
              <Select.Option key={'NO'}>No</Select.Option>
            </Select>
          </Form.Item>
          <SubmitButton
            disabled={loading || !tabOperations?.update}
            label="Update Changes"
          />
        </Form>
      </div>
      <div className={styles.filterSection}>
        <h1>IN-APP REVIEW SETTINGS</h1>
        <span>
          This displays a prompt in the Users App, to give Feedback about their
          experience with the App
        </span>
      </div>
      <Form
        onFinish={values =>
          updateAppConfig({
            variables: {
              data: {
                inAppReviewSettings: {
                  isEnabled: values.isEnabled,
                  frequencyControl: {
                    maxPrompts: values.maxPrompts,
                    daysInterval: values.daysInterval,
                    cooldownPeriod: values.cooldownPeriod,
                  },
                  filters: {
                    hasMadeInvestment: values.hasMadeInvestment,
                  },
                },
              },
            },
          }).then(({data: {updateAppConfig}}) => {
            if (updateAppConfig.__typename === 'Error') {
              toast.error(updateAppConfig.message);
            } else {
              toast.success('Updated Successfully');
              refetch();
            }
          })
        }
        form={form}
        layout="vertical">
        <Form.Item name="isEnabled" label="Enable">
          <Switch defaultChecked={inAppData?.isEnabled} />
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This is a required field'}]}
          label="Maximum Prompts"
          name="maxPrompts">
          <InputNumber className={styles.number} />
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This is a required field'}]}
          label="Days Interval"
          name="daysInterval">
          <InputNumber className={styles.number} />
        </Form.Item>{' '}
        <Form.Item
          rules={[{required: true, message: 'This is a required field'}]}
          label="Cool down Period (Days)"
          name="cooldownPeriod">
          <InputNumber className={styles.number} />
        </Form.Item>
        <h2>Who gets to see this?</h2>
        <Form.Item
          name="hasMadeInvestment"
          label="Users that has Made Investment?">
          <Select placeholder="Select an Option" style={{width: 300}}>
            <Select.Option key={'YES'}>Yes</Select.Option>
            <Select.Option key={'NO'}>No</Select.Option>
            <Select.Option key={'EITHER'}>Either</Select.Option>
          </Select>
        </Form.Item>
        <SubmitButton disabled={loading} label="Update Changes" />
      </Form>
    </div>
  );
};

export default ReviewSettings;
