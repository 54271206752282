import DBModal from 'components/modal/modal';
import styles from './userWalletSettings.module.scss';
import {Form, Input, InputNumber, Select} from 'antd';
import {currencySymbols, walletCurrencies, walletProviders} from 'utils/mock';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useState} from 'react';
import {Button} from '@dabafinance/react-components';
import {useMutation} from '@apollo/client';
import {
  CREATE_DEPOSIT_TX,
  CREATE_WITHDRAWAL_TX,
} from 'graphql/mutations/wallet';
import {toast} from 'react-toastify';
import {UserSelectField} from 'components/userSelectField/UserSelectField';
import {walletTransactionDirectionOptions} from 'utils/constants';

const ManualTxModal = ({isOpen, onClose, type, userId, refetch}) => {
  const [formValues, setFormValues] = useState({});
  const [showConfirmPage, setShowConfirmPage] = useState(false);
  const [user, setUser] = useState('');
  const [form] = Form.useForm();

  const [createManualDepositWalletTransaction, {loading: loadingDeposit}] =
    useMutation(CREATE_DEPOSIT_TX, {
      onError: error => toast.error(error.message),
    });

  const [
    createManualWithdrawalWalletTransaction,
    {loading: loadingWithdrawal},
  ] = useMutation(CREATE_WITHDRAWAL_TX, {
    onError: error => toast.error(error.message),
  });

  const handleSubmit = () => {
    const resolver =
      type === 'DEPOSIT'
        ? createManualDepositWalletTransaction
        : createManualWithdrawalWalletTransaction;
    const {paymentProvider, currency, amount, direction, reason, fees} =
      formValues;
    resolver({
      variables: {
        input: {
          userId: userId ?? user?.split(';')[2],
          currency,
          direction,
          paymentProvider,
          reason,
          amount: Number(amount),
          fees: Number(fees),
        },
      },
    })
      .then(({data}) => {
        const resolver =
          data?.[
            type === 'DEPOSIT'
              ? 'createManualDepositWalletTransaction'
              : 'createManualWithdrawalWalletTransaction'
          ];
        if (resolver.__typename === 'Error') {
          toast.error(resolver.message);
        } else {
          toast.success('Created Successfully');
          setShowConfirmPage(false);
          form.resetFields();
          refetch();
          onClose();
          setUser('');
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <DBModal
      isOpen={isOpen}
      handleClose={onClose}
      content={
        <div className={styles.rootModal}>
          <h1>
            {' '}
            {showConfirmPage ? 'Confirm' : 'Create'} Manual {type} Transaction
          </h1>

          {showConfirmPage ? (
            <div>
              <div>
                <p className={styles.confirm}>
                  You are about to create{' '}
                  <strong>
                    {currencySymbols[formValues?.currency]?.symbol}
                    {formValues?.amount?.toLocaleString()}{' '}
                  </strong>
                  {type === 'DEPOSIT' ? 'Deposit' : 'Withdrawal'} with{' '}
                  <strong>
                    {currencySymbols[formValues?.currency]?.symbol}
                    {formValues?.fees}
                  </strong>{' '}
                  fees for{' '}
                  <strong>
                    {user?.split(';')[0]} {user?.split(';')[1]}
                  </strong>
                </p>
              </div>
              <div className="buttons">
                <Button
                  disabled={loadingDeposit || loadingWithdrawal}
                  label="Confirm"
                  onClick={handleSubmit}
                />
                <Button
                  type="secondary"
                  label="No"
                  onClick={() => setShowConfirmPage(false)}
                />
              </div>
            </div>
          ) : (
            <>
              <Form
                onFinish={values => {
                  setShowConfirmPage(true);
                  setFormValues(values);
                }}
                form={form}
                layout="vertical">
                {!userId && (
                  <Form.Item
                    name="user"
                    className={styles.input}
                    label="Select User">
                    <UserSelectField
                      mode="single"
                      users={user}
                      defaultUsers={user?.split(';')[2]}
                      setUsers={setUser}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  label="Select Payment Provider"
                  className={styles.input}
                  rules={[
                    {required: true, message: 'This is a Required Field'},
                  ]}
                  name="paymentProvider">
                  <Select allowClear>
                    {walletProviders.map(data => (
                      <Select.Option key={data?.key}>
                        {data?.label}
                      </Select.Option>
                    ))}
                    <Select.Option key="BANK_ACCOUNT">
                      Bank Account
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[
                    {required: true, message: 'This is a Required Field'},
                  ]}
                  className={styles.input}
                  name="currency"
                  label="Currency">
                  <Select allowClear>
                    {walletCurrencies
                      .filter(data => !['NGN', 'XAF', 'GBP'].includes(data.key))
                      .map(data => (
                        <Select.Option key={data?.key}>
                          {data?.key}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[
                    {required: true, message: 'This is a Required Field'},
                    {
                      validator: (_, value) =>
                        value > 0
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error('Amount must be greater than zero'),
                            ),
                    },
                  ]}
                  name="amount"
                  className={styles.input}
                  label="Amount">
                  <InputNumber
                    className={styles.inputNumber}
                    min={0}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {required: true, message: 'This is a Required Field'},
                    {
                      validator: (_, value) =>
                        value >= 0
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error('Fee cannot be less than zero'),
                            ),
                    },
                  ]}
                  name="fees"
                  className={styles.input}
                  label="Fees">
                  <InputNumber
                    className={styles.inputNumber}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    min={0}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item
                  label="Direction"
                  className={styles.input}
                  rules={[
                    {required: true, message: 'This is a Required Field'},
                  ]}
                  name="direction">
                  <Select showSearch allowClear>
                    {walletTransactionDirectionOptions.map(data => (
                      <Select.Option key={data.value}>
                        {data.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  className={styles.input}
                  rules={[
                    {required: true, message: 'This is a Required Field'},
                  ]}
                  name="reason"
                  label="Reason">
                  <Input.TextArea rows={5} />
                </Form.Item>
                <SubmitButton label="Proceed" />
              </Form>
            </>
          )}
        </div>
      }
    />
  );
};

export default ManualTxModal;
