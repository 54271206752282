import {Button} from '@dabafinance/react-components';
import styles from './payouts.module.scss';
import gamer from 'images/gamer.png';
import {Dropdown, Menu, Pagination, Table, Tag} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import {GET_DIVIDENDS_PAYOUT_HISTORY} from 'graphql/queries/dividends';
import DBModal from 'components/modal/modal';
import {useState} from 'react';
import {toast} from 'react-toastify';
import dotsIcon from 'icons/dots.svg';
import {useSelector} from 'react-redux';
import {
  CreditOrDebitUserDividend,
  DividendHistory,
  ProcessUserDividend,
  UpdatePayoutAmount,
} from '../create/updateUserDividend/UpdateUserDividend';
import {useNavigate} from 'react-router-dom';
import {PAYOUT_ALL_USERS_DIVIDEND} from 'graphql/mutations/dividends';
import {ProcessDividendPayout} from './ProcessModals';
import moment from 'moment';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const Payouts = () => {
  const [openPayoutModal, setOpenPayoutModal] = useState(false);
  const [dividendUserId, setDividendUserId] = useState('');
  const [openUpdatePayoutModal, setOpenUpdatePayoutModal] = useState('');
  const [openPayoutAllUsersModal, setOpenPayoutAllUsersModal] = useState(false);
  const [pastId, setPastId] = useState('');
  const dividendIds = useSelector(state => state?.app);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState('PAYOUT');
  const [filterValues, setFilterValues] = useState({});
  const [limit, setLimit] = useState(10);
  const [processDividendPayouts, {loading: loadingPayoutAllUsers}] =
    useMutation(PAYOUT_ALL_USERS_DIVIDEND, {
      onError: error => toast.error(`Error something wrong -${error.message}`),
    });

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Dividends),
  );

  const isPayoutPage = activeTab === 'PAYOUT';

  const {data, loading, refetch} = useQuery(GET_DIVIDENDS_PAYOUT_HISTORY, {
    variables: {
      input: {
        page: 1,
        limit: 15,
        status: isPayoutPage ? 'UNPAID' : 'PAID',
        ...(isPayoutPage && {
          companyId: dividendIds?.dividendId,
          ...(dividendIds?.instrumentId && {
            instrumentId: dividendIds?.instrumentId,
          }),
        }),
        ...(!isPayoutPage &&
          (pastId?.startDate || dividendIds?.pastDividendFilter?.startDate) && {
            startDate:
              pastId?.startDate || dividendIds?.pastDividendFilter?.startDate,
          }),
        ...(!isPayoutPage &&
          (pastId?.endDate || dividendIds?.pastDividendFilter?.endDate) && {
            endDate:
              pastId?.endDate || dividendIds?.pastDividendFilter?.endDate,
          }),
      },
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const handlePayoutAllUsers = () => {
    const values = data?.getDividendHistory?.data?.[0];
    processDividendPayouts({
      variables: {
        companyId: values?.company?.id,
        ...(values?.instrumentId && {instrumentId: values?.instrumentId}),
      },
    })
      .then(({data: {processDividendPayouts}}) => {
        if (processDividendPayouts.__typename === 'Error') {
          toast.error(processDividendPayouts.message);
        } else {
          toast.success(processDividendPayouts?.message);
          setOpenPayoutAllUsersModal(false);
          refetch();
        }
      })
      .catch(error => toast.error(error));
  };

  const handleFilter = (values, currentPage, newLimit) => {
    let {companyId, startDate, instrumentId, investmentCategory, endDate} =
      values;
    setFilterValues(prev => ({...prev, companyId}));
    refetch({
      input: {
        status: isPayoutPage ? 'UNPAID' : 'PAID',
        companyId: isPayoutPage
          ? dividendIds?.dividendId
          : companyId ?? filterValues?.companyId,
        instrumentId,
        startDate,
        investmentCategory,
        limit: newLimit ? newLimit : limit,
        endDate,
        page: currentPage ?? page,
      },
    });
  };

  const handleChangePage = currentPage => {
    if (page === currentPage) return;
    setPage(currentPage);
    handleFilter(filterValues, currentPage);
  };

  const handleResetPage = () => {
    setFilterValues({});
    setPage(1);
  };

  const columns = [
    {
      dataIndex: 'name',
      title: 'Name',
      key: 'name',
      fixed: 'left',
      render: (text, record) => (
        <div
          onClick={() =>
            navigate(
              `/dashboard/user/${record?.user?.id}/transactions?tab=dividend`,
            )
          }
          className={styles['record-label']}>
          <div className={styles['record-name']}>
            {record?.user?.imageUrl ? (
              <img src={record?.user?.imageUrl} alt="" />
            ) : (
              <img src={gamer} alt="" />
            )}
            <h4 style={{width: 100}}>
              {record?.user?.firstName} {record?.user?.lastName}
            </h4>
          </div>
        </div>
      ),
    },
    {
      dataIndex: 'company',
      title: 'Company',
      key: 'company',
      fixed: isPayoutPage ? '' : 'left',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img src={record?.company?.logoImgURL} alt="" />
          <h4 style={{width: 100}}>{record?.company?.legalName}</h4>
        </div>
      ),
    },
    {
      dataIndex: 'instrument',
      title: 'Instrument',
      key: 'instrument',
      render: (text, record) => (
        <div className={styles['record-label']}>{record?.instrument?.name}</div>
      ),
    },
    {
      dataIndex: 'units',
      title: 'Number of Shared Units',
      key: 'Number of Shared',
    },
    {
      dataIndex: 'investmentCategory',
      title: 'Category',
      key: 'category',
      render: (text, render) => (
        <Tag
          color={
            {
              'BOND': 'cyan',
              'STOCK': 'red',
              'VENTURE': 'orange',
              'IPO': 'green',
            }[render?.investmentCategory]
          }>
          {render?.investmentCategory}
        </Tag>
      ),
    },
    {
      dataIndex: 'principalPerShare',
      title: 'Principal Per Share',
      key: 'principalPerShare',
      render: (text, render) => (
        <span>
          {render?.principalPerShare} {render?.payoutCurrency}
        </span>
      ),
    },
    {
      dataIndex: 'amountPerShare',
      title: 'Expected Payout (Per Share)',
      key: 'amountPerShare',
      render: (text, render) => (
        <span>
          {render?.amountPerShare} {render?.payoutCurrency}
        </span>
      ),
    },
    {
      dataIndex: 'userTotalDividendPayout',
      title: 'User Total Dividend Payout',
      key: 'userTotalDividendPayout',
      render: (text, render) => (
        <span>
          {render?.payoutAmount?.toLocaleString()} {render?.payoutCurrency}
        </span>
      ),
    },
    {
      dataIndex: 'period',
      title: 'Payout Period',
      key: 'period',
      children: [
        {
          dataIndex: 'startDate',
          title: 'Start Date',
          key: 'startDate',
          render: (text, render) => (
            <span>
              {moment(render?.period?.startDate).format('MMM DD, YYYY')}
            </span>
          ),
        },
        {
          dataIndex: 'endDate',
          title: 'End Date',
          key: 'endDate',
          render: (text, render) => (
            <span>
              {moment(render?.period?.endDate).format('MMM DD, YYYY')}
            </span>
          ),
        },
      ],
    },
    {
      dataIndex: 'status',
      title: 'Status',
      key: 'status',
      render: (text, render) => (
        <Tag
          color={
            {
              'PAID': 'green',
              'UNPAID': 'default',
            }[render?.status] || 'blue'
          }
          key={render.status}>
          {render.status}
        </Tag>
      ),
    },
    {
      dataIndex: 'actions',
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, render) => (
        <div>
          <Dropdown
            overlay={
              <Menu>
                {render.status === 'PAID' ? (
                  <>
                    {tabOperations?.update && (
                      <Menu.Item
                        onClick={() => {
                          setDividendUserId({
                            id: render?.id,
                            amount: render?.payoutAmount,
                            currency: render?.payoutCurrency,
                          });
                          setOpenUpdatePayoutModal('CREDIT');
                        }}
                        key="3">
                        Credit / Debit User
                      </Menu.Item>
                    )}
                    <Menu.Item
                      onClick={() => {
                        setDividendUserId({
                          id: render?.id,
                          history: render?.history,
                        });
                        setOpenUpdatePayoutModal('HISTORY');
                      }}
                      key="4">
                      History
                    </Menu.Item>
                  </>
                ) : (
                  tabOperations?.update && (
                    <>
                      <Menu.Item
                        onClick={() => {
                          setDividendUserId({
                            id: render?.id,
                            units: render?.units,
                            principalPerShare: render?.principalPerShare,
                            amount: render?.amountPerShare,
                          });
                          setOpenUpdatePayoutModal('AMOUNT');
                        }}
                        key="1">
                        Edit Dividend Payout
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          setDividendUserId(render?.id);
                          setOpenUpdatePayoutModal('PROCESS');
                        }}
                        key="2">
                        Pay Individual Dividend
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          setDividendUserId(render?.id);
                          setOpenUpdatePayoutModal('DELETE');
                        }}
                        key="3">
                        Delete User from Payout List
                      </Menu.Item>
                    </>
                  )
                )}
              </Menu>
            }>
            <img src={dotsIcon} />
          </Dropdown>
        </div>
      ),
    },
  ];

  if (!isPayoutPage) {
    columns.splice(5, 0, {
      dataIndex: 'payoutDate',
      title: 'Payout Date',
      key: 'payoutDate',
      render: (text, render) => (
        <p style={{minWidth: 100}}>
          {moment(render?.payoutDate).format('MMM DD, YYYY')}
        </p>
      ),
    });
  }

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <h1>Dividend Payout</h1>
        <div>
          {isPayoutPage && (
            <Dropdown
              trigger="hover"
              menu={{
                items: [
                  {
                    key: 1,
                    label: 'Finalize Dividends Payout',
                    onClick: () =>
                      setOpenPayoutModal({isOpen: true, type: 'FINALIZE'}),
                  },
                  {
                    key: 2,
                    label: 'Export Dividends Payout',
                    onClick: () =>
                      setOpenPayoutModal({isOpen: true, type: 'CSV'}),
                  },
                ],
              }}>
              <div>
                <Button
                  className={styles.button}
                  disabled={!tabOperations?.create}
                  label={'Process New Dividend Payout'}
                />
              </div>
            </Dropdown>
          )}
        </div>
      </div>
      <div className={styles['details-toggler']}>
        <span
          className={isPayoutPage ? styles['active-toggle'] : ''}
          onClick={() => {
            setActiveTab('PAYOUT');
            handleResetPage();
          }}>
          {' '}
          Payout
        </span>
        <span
          className={!isPayoutPage ? styles['active-toggle'] : ''}
          onClick={() => {
            setActiveTab('HISTORY');
            handleResetPage();
          }}>
          {' '}
          Payout History
        </span>
      </div>
      <div className={styles.table}>
        {dividendIds?.dividendId &&
        !data?.getDividendHistory?.data?.length &&
        !loading &&
        isPayoutPage ? (
          <div className={styles.loading}>
            <h2>
              Finalizing Payout Information, Table data would be automatically
              populated when done and an official mail would be sent
            </h2>
          </div>
        ) : (
          <div>
            {data?.getDividendHistory?.data?.[0]?.company?.legalName &&
            isPayoutPage ? (
              <div className={styles.header}>
                <h1>
                  {data?.getDividendHistory?.data?.[0]?.company?.legalName}{' '}
                  Payout
                </h1>
                <div>
                  <Button
                    onClick={() => setOpenPayoutAllUsersModal(true)}
                    label="Payout All Users"
                    disabled={!tabOperations?.update}
                    type="secondary"
                  />
                </div>
              </div>
            ) : (
              <div className={styles.header}>
                <div />
                <Button
                  onClick={() => setOpenPayoutModal(true)}
                  label="Filter History"
                  type="secondary"
                />
              </div>
            )}
            <div className={styles.table}>
              <div className={styles['nav-buttons']}>
                <div className={styles['page-number']}>{page}</div>
              </div>
              <Table
                columns={columns}
                loading={loading}
                pagination={false}
                scroll={{
                  x: 1500,
                }}
                dataSource={data?.getDividendHistory?.data}
              />
              <Pagination
                current={page}
                onChange={e => handleChangePage(e)}
                total={data?.getDividendHistory?.pagination?.total}
                showQuickJumper
                className="ant-table-pagination"
                showSizeChanger
                onShowSizeChange={(_, size) => {
                  setLimit(size);
                  handleFilter(_, _, size);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div>
        <ProcessDividendPayout
          openPayoutModal={openPayoutModal}
          onClose={() => setOpenPayoutModal(false)}
          activeTab={activeTab}
          resetPage={() => setPage(1)}
          handlePastId={setPastId}
          handleFilterValues={setFilterValues}
          onFilter={handleFilter}
        />
        <DBModal
          isOpen={openUpdatePayoutModal}
          handleClose={() => setOpenUpdatePayoutModal('')}
          width={openUpdatePayoutModal === 'HISTORY' ? '1000px' : ''}
          content={
            <div>
              {openUpdatePayoutModal === 'AMOUNT' ? (
                <UpdatePayoutAmount
                  refetch={refetch}
                  data={dividendUserId}
                  closeModal={() => setOpenUpdatePayoutModal('')}
                />
              ) : openUpdatePayoutModal === 'PROCESS' ||
                openUpdatePayoutModal === 'DELETE' ? (
                <ProcessUserDividend
                  id={dividendUserId}
                  refetch={refetch}
                  isDelete={openUpdatePayoutModal === 'DELETE'}
                  closeModal={() => setOpenUpdatePayoutModal('')}
                />
              ) : openUpdatePayoutModal === 'HISTORY' ? (
                <DividendHistory data={dividendUserId} />
              ) : (
                <CreditOrDebitUserDividend
                  data={dividendUserId}
                  refetch={refetch}
                  closeModal={() => setOpenUpdatePayoutModal('')}
                />
              )}
            </div>
          }
        />

        <DBModal
          isOpen={openPayoutAllUsersModal}
          handleClose={() => setOpenPayoutAllUsersModal(false)}
          content={
            <div className={styles.allUser}>
              <div className={styles.top}>
                <h1>Process All Users Dividend</h1>
                <span>
                  Are you sure, you want to Process all Users Dividends Payouts
                </span>
              </div>
              <div className={styles.button}>
                <Button
                  disabled={loadingPayoutAllUsers}
                  onClick={handlePayoutAllUsers}
                  label="Yes"
                />
                <Button
                  onClick={() => setOpenPayoutAllUsersModal(false)}
                  label="No"
                  type="secondary"
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Payouts;
