import {Route, Routes} from 'react-router-dom';
import {FinancialHeader} from './FinancialHeader';
import {RevenueMetrics} from './RevenueMetrics';
import {WalletMetrics} from './WalletMetrics';
import {OrderMetrics} from './OrderMetrics';
import {VolumeMetrics} from './VolumeMetric';

export const FinancialRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FinancialHeader />}>
        <Route index path="/" element={<RevenueMetrics />} />
        <Route index path="/wallet" element={<WalletMetrics />} />
        <Route index path="/orders" element={<OrderMetrics />} />
        <Route index path="/volume" element={<VolumeMetrics />} />
      </Route>
    </Routes>
  );
};
