/* eslint-disable no-undef */
import {Form, Input, Select, Tag, Table} from 'antd';
import styles from './updateUserDividend.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation} from '@apollo/client';
import {
  CREDIT_OR_DEBIT_USER_PAYOUT,
  DELETE_USER_FROM_PAYOUT,
  PROCESS_ONE_DIVIDEND_PAYOUT,
  UPDATE_DIVIDEND_PAYOUT_AMOUNT,
} from 'graphql/mutations/dividends';
import {toast} from 'react-toastify';
import {Button} from '@dabafinance/react-components';
import {useEffect} from 'react';
import moment from 'moment';

export const UpdatePayoutAmount = ({data, closeModal, refetch}) => {
  const [form] = Form.useForm();
  const [updateDividendPayoutAmount, {loading}] = useMutation(
    UPDATE_DIVIDEND_PAYOUT_AMOUNT,
    {
      errorPolicy: 'all',
      onError: error => toast.error(`Error something wrong -${error.message}`),
    },
  );

  useEffect(() => {
    form.setFieldsValue({
      dividendPerShare: data.amount,
      units: data?.units,
      totalDividendAmount:
        (Number(data.amount) + Number(data?.principalPerShare)) *
        Number(data?.units),
      principalPerShare: data?.principalPerShare,
    });
  }, [data]);

  const handleFinish = values => {
    const {totalDividendAmount, principalPerShare, dividendPerShare} = values;
    try {
      updateDividendPayoutAmount({
        variables: {
          input: {
            totalDividendAmount: Number(totalDividendAmount),
            dividendPerShare: Number(dividendPerShare),
            ...(principalPerShare && {
              principalPerShare: Number(principalPerShare),
            }),
            id: data?.id,
          },
        },
      })
        .then(({data: {updateDividendPayoutAmount}}) => {
          if (updateDividendPayoutAmount.__typename === 'Error') {
            toast.error(updateDividendPayoutAmount.message);
          } else {
            toast.success(updateDividendPayoutAmount?.message);
            closeModal();
            form.resetFields();
            refetch();
          }
        })
        .catch(error => toast.error(error));
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>Edit Stock Dividend Payout</h1>
      </div>
      <Form
        onFieldsChange={(changedFields, allFields) => {
          const {dividendPerShare, principalPerShare} = allFields.reduce(
            (acc, field) => {
              acc[field.name[0]] = field.value;
              return acc;
            },
            {},
          );

          form.setFieldValue(
            'totalDividendAmount',
            Number(data?.units) *
              (Number(dividendPerShare) + Number(principalPerShare)),
          );
        }}
        form={form}
        onFinish={handleFinish}
        layout="vertical">
        <Form.Item
          rules={[{required: true, message: 'This Field is required'}]}
          name="dividendPerShare"
          className={styles.field}
          label="Amount Per Share">
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="principalPerShare"
          className={styles.field}
          label="Principal Per Share">
          <Input type="number" />
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This Field is required'}]}
          name="units"
          label="Units">
          <Input type="number" readOnly disabled />
        </Form.Item>
        <Form.Item name="totalDividendAmount" label="Total Expected Amount">
          <Input type="number" readOnly disabled />
        </Form.Item>
        <SubmitButton disabled={loading} label="Update" />
      </Form>
    </div>
  );
};

export const ProcessUserDividend = ({id, closeModal, refetch, isDelete}) => {
  const [deleteDividendPayoutById, {loading: loadingDelete}] = useMutation(
    DELETE_USER_FROM_PAYOUT,
    {
      errorPolicy: 'all',
      onError: error => toast.error(`Error something wrong -${error.message}`),
    },
  );
  const [processOneDividendPayout, {loading}] = useMutation(
    PROCESS_ONE_DIVIDEND_PAYOUT,
    {
      errorPolicy: 'all',
      onError: error => toast.error(`Error something wrong -${error.message}`),
    },
  );

  const handleProcessPayout = () => {
    if (isDelete) {
      try {
        deleteDividendPayoutById({
          variables: {
            deleteDividendPayoutByIdId: id,
          },
        })
          .then(({data: {deleteDividendPayoutById}}) => {
            if (deleteDividendPayoutById.__typename === 'Error') {
              toast.error(deleteDividendPayoutById.message);
            } else {
              toast.success(deleteDividendPayoutById?.message);
              closeModal();
              refetch();
            }
          })
          .catch(error => toast.error(error));
      } catch (error) {
        toast.error(error);
      }
    } else {
      try {
        processOneDividendPayout({
          variables: {
            processOneDividendPayoutId: id,
          },
        })
          .then(({data: {processOneDividendPayout}}) => {
            if (processOneDividendPayout.__typename === 'Error') {
              toast.error(processOneDividendPayout.message);
            } else {
              toast.success(processOneDividendPayout?.message);
              closeModal();
              refetch();
            }
          })
          .catch(error => toast.error(error));
      } catch (error) {
        toast.error(error);
      }
    }
  };
  return (
    <div className={styles.modal}>
      <div className={styles.top}>
        {isDelete ? (
          <>
            <h1>Delete this User from Payout List</h1>
            <span>Are you sure you want to carry out this action?</span>
          </>
        ) : (
          <>
            <h1>Process This User Payout</h1>
            <span>
              Are you sure you want to process this user Dividend Payout
            </span>
          </>
        )}
      </div>
      <div className={styles.buttons}>
        <Button
          disabled={loading || loadingDelete}
          label="Yes"
          onClick={handleProcessPayout}
        />
        <Button type="secondary" label="No" onClick={closeModal} />
      </div>
    </div>
  );
};

export const CreditOrDebitUserDividend = ({data, closeModal, refetch}) => {
  const [form] = Form.useForm();
  const [creditOrDebitUserPaidDividend, {loading}] = useMutation(
    CREDIT_OR_DEBIT_USER_PAYOUT,
    {
      errorPolicy: 'all',
      onError: error => toast.error(`Error something wrong -${error.message}`),
    },
  );

  const handleUpdate = values => {
    try {
      creditOrDebitUserPaidDividend({
        variables: {
          input: {
            id: data.id,
            amount: Number(values.amount),
            action: values.action,
          },
        },
      })
        .then(({data: {creditOrDebitUserPaidDividend}}) => {
          if (creditOrDebitUserPaidDividend.__typename === 'Error') {
            toast.error(creditOrDebitUserPaidDividend.message);
          } else {
            toast.success(creditOrDebitUserPaidDividend?.message);
            form.resetFields();
            refetch();
            closeModal();
          }
        })
        .catch(error => toast.error(error));
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className={styles.root}>
      <h1>Credit / Debit User Payout</h1>
      <Form form={form} onFinish={handleUpdate} layout="vertical">
        <Form.Item
          rules={[{required: true, message: 'This Field is required'}]}
          name="action"
          className={styles.field}
          label="Action">
          <Select>
            <Select.Option key="CREDIT">
              CREDIT (Increase the amount of dividend to be Paid out)
            </Select.Option>
            <Select.Option key="DEBIT">
              Debit (Reduce the amount of dividend to be Paid out)
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="amount"
          className={styles.field}
          rules={[
            {
              required: true,
              message: 'This Field is required',
            },
          ]}
          label={
            <div>
              Amount:
              <span style={{color: 'green'}}>
                {' '}
                ({data?.amount} {data?.currency})
              </span>
            </div>
          }>
          <Input type="number" />
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.amount !== currentValues.amount ||
            prevValues.action !== currentValues.action
          }
          dependencies={['amount, action']}
          label="Total Amount">
          {({getFieldValue}) => {
            const action = getFieldValue('action');
            let amount = getFieldValue('amount');
            if (action === 'CREDIT')
              amount = Number(data?.amount) + Number(amount);
            else if (action === 'DEBIT')
              amount = Number(data?.amount) - Number(amount);

            return <Input disabled={true} value={amount || 0} />;
          }}
        </Form.Item>
        <SubmitButton disabled={loading} label="Update Dividend Payout" />
      </Form>
    </div>
  );
};

export const DividendHistory = ({data}) => {
  if (data?.history.includes(null))
    return (
      <div className={styles.noData}>
        <h1>ERROR: Loading History, Please notify the Team</h1>
      </div>
    );
  const columns = [
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      render: (text, render) => (
        <Tag
          color={
            {
              'SUCCESSFUL': 'green',
              'PENDING': 'orange',
              'FAILED': 'red',
              'CANCELLED': 'red',
            }[render?.status] || 'blue'
          }
          key={render.id}>
          {render.status}
        </Tag>
      ),
    },
    {
      dataIndex: 'type',
      key: 'type',
      title: 'Type',
      render: (text, render) => (
        <Tag
          color={
            {
              'SUCCESSFUL': 'green',
              'DIVIDEND_REVERSAL': 'red',
              'ERROR': 'red',
              'REJECTED': 'red',
              'CANCELLED': 'red',
              'SETTLED': 'cyan',
              'PARTIALLY_FILLED': 'yellow',
            }[render?.type] || 'blue'
          }
          key={render.id}>
          {render.type}
        </Tag>
      ),
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: 'Amount (Fee)',
      render: (text, render) => (
        <span>
          {render?.transactionAmountNetOfFees} ({render?.transactionFee})
        </span>
      ),
    },
    {
      dataIndex: 'Date',
      key: 'Date',
      title: 'Date',
      render: (text, record) => (
        <span>{moment(record?.createdAt).format('DD MMM, YYYY h:mmA')}</span>
      ),
    },
  ];
  return (
    <div className={styles.root}>
      <h1>Payout History</h1>
      <Table columns={columns} dataSource={data?.history} />
    </div>
  );
};
