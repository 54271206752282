import {useEffect, useState} from 'react';
import backIcon from 'images/back.png';
import styles from './createTransaction.module.scss';
import {useNavigate, useParams} from 'react-router-dom';
import {Form, Select, Spin} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import {UPDATE_USER_TRADE} from 'graphql/mutations/trades';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {GET_USER_TRADE_BY_ID, USER_TRANSACTIONS} from 'graphql/queries/users';
import {transactionStatusOptions, transactionTypes} from 'utils/constants';

const UpdateTransaction = () => {
  const [transactionStatus, setTransactionStatus] = useState('');
  const [type, setType] = useState('');
  const navigate = useNavigate();
  const {id, tradeId} = useParams();

  const {data, loading: loadingTrade} = useQuery(GET_USER_TRADE_BY_ID, {
    variables: {
      tradeId,
    },
  });

  useEffect(() => {
    if (data?.getTradeById) {
      setTransactionStatus(data?.getTradeById?.transactionStatus);
      setType(data?.getTradeById?.type);
    }
  }, [data?.getTradeById]);

  const [updateTrade, {loading}] = useMutation(UPDATE_USER_TRADE);

  const handleUpdateTrade = () => {
    const optionalFields = {};
    if (transactionStatus) optionalFields.transactionStatus = transactionStatus;
    updateTrade({
      variables: {
        tradeId,
        input: {
          ...optionalFields,
          type,
        },
      },
      refetchQueries: [
        {
          query: USER_TRANSACTIONS,
          variables: {
            userId: id,
            getUserByIdId: id,
          },
        },
      ],
    })
      .then(({data: {updateTrade}}) => {
        if (updateTrade.__typename === 'Error') {
          toast.error(updateTrade.message);
        } else {
          toast.success('Trade Updated Successfully');
          navigate(-1);
        }
      })
      .catch(error => toast.error(error.message));
  };

  if (loadingTrade) {
    return (
      <div className={styles.loading}>
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={backIcon} alt="" onClick={() => navigate(-1)} />
        <h1>Update Transaction</h1>
      </div>
      <div>
        <Form onFinish={handleUpdateTrade} layout="vertical">
          <Form.Item
            rules={[{required: true, message: 'Please select a type'}]}
            name="transactionStatus"
            label="Transaction Status"
            className={styles.width}
            initialValue={data?.getTradeById?.transactionStatus}>
            <Select
              defaultValue={data?.getTradeById?.transactionStatus}
              value={transactionStatus}
              onChange={e => setTransactionStatus(e)}>
              {transactionStatusOptions
                .filter(status =>
                  ['FUNDED', 'CREATED', 'SETTLED'].includes(status.value),
                )
                .map(data => (
                  <Select.Option key={data.value}>
                    <span>{data.label}</span>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Please select a type'}]}
            name="type"
            label="Transaction Type"
            className={styles.width}
            initialValue={data?.getTradeById?.type}>
            <Select
              defaultValue={data?.getTradeById?.type}
              value={type}
              disabled
              onChange={e => setType(e)}>
              {transactionTypes.map(data => (
                <Select.Option key={data.value}>
                  <span>{data.label}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <SubmitButton disabled={loading} label="Update Trade" />
        </Form>
      </div>
    </div>
  );
};

export default UpdateTransaction;
