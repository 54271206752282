import styles from './financial.module.scss';

export const FinancialCurrencyCard = ({currency, symbol, type, amount}) => {
  return (
    <div
      style={{
        background:
          type === 'USD' ? '#8D6CFA' : type === 'EUR' ? '#286103' : '#3a2196',
      }}
      className={styles.currencyCard}>
      <p>{currency}</p>
      <h1>
        {symbol} {amount?.toLocaleString()}
      </h1>
    </div>
  );
};
