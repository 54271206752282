import {Form, Switch} from 'antd';
import styles from './mobileSetting.module.scss';
import {UPDATE_APP_CONFIGURATION} from 'graphql/queries/mobileSettings';
import {useMutation} from '@apollo/client';
import {useEffect, useState} from 'react';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';

const formItems = [
  {name: 'ssn', label: 'SSN'},
  {name: 'socialInsuranceNumber', label: 'Social Insurance Number'},
  {name: 'passport', label: 'Passport'},
  {name: 'nationalIdentityNumber', label: 'National Identity Number (NIN)'},
  {name: 'license', label: 'License'},
  {name: 'idcard', label: 'ID Card'},
];

export const KycConfig = ({data, refetch}) => {
  const [openModal, setOpenModal] = useState(false);
  const [form] = Form.useForm();
  const [switchStates, setSwitchStates] = useState(
    formItems.reduce((acc, item) => {
      acc[item.name] = false; // Initialize all switches to false
      return acc;
    }, {}),
  );
  const [updateAppConfig, {loading}] = useMutation(UPDATE_APP_CONFIGURATION, {
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line no-unused-vars
      const {__typename, ...restData} = data;
      setSwitchStates(restData);
    }
  }, [data]);

  const handleSwitchChange = (name, checked) => {
    setSwitchStates(prevStates => ({
      ...prevStates,
      [name]: checked, // Update the switch state
    }));
  };

  const handleItemClick = name => {
    setSwitchStates(prevStates => ({
      ...prevStates,
      [name]: !prevStates[name], // Toggle the switch state
    }));
  };

  return (
    <div className={styles.body}>
      <h2>KYC Configuration</h2>
      <p>
        This is the configuration for the KYC process. You can set the KYC
        requirements for your users.
      </p>
      <Form
        onFinish={() => {
          setOpenModal(true);
        }}
        form={form}
        layout="horizontal"
        className={styles.row}>
        {formItems.map(item => (
          <div
            onClick={() => handleItemClick(item.name)}
            key={item.name}
            className={styles.formItem}>
            <Form.Item key={item.name} name={item.name}>
              <Switch
                onChange={(checked, event) => {
                  event.stopPropagation();
                  handleSwitchChange(item.name, checked);
                }}
                checked={switchStates[item.name]}
              />
            </Form.Item>
            <div className={styles.label}>{item.label}</div>
          </div>
        ))}
        <SubmitButton label="Proceed to Update" />
      </Form>
      <ConfirmModal
        openModal={openModal}
        setOpenModal={() => setOpenModal(false)}
        loading={loading}
        title="Update Configuration"
        confirmText="Are you sure you want to update this, This action cannot be undone?"
        handleOperation={() =>
          updateAppConfig({
            variables: {
              data: {
                kyc: switchStates,
              },
            },
          })
            .then(({data: {updateAppConfig}}) => {
              if (updateAppConfig.__typename === 'Error') {
                toast.error(updateAppConfig.message);
              } else {
                toast.success('Updated Successfully');
                refetch();
                setOpenModal(false);
              }
            })
            .catch(error => toast.error(error))
        }
      />
    </div>
  );
};
