import {useQuery} from '@apollo/client';
import {FinancialCurrencyCard} from './FinancialCards';
import {GET_REVENUE_METRICS} from 'graphql/queries/financial';
import {DatePicker, Form, Switch} from 'antd';
import {useState} from 'react';
import styles from './financial.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import moment from 'moment';
import {ExportFinancialButton} from './ExportFinancialButton';
import {currentDayOfMonth, firstDayOfMonth} from './WalletMetrics';

export const RevenueMetrics = () => {
  const [form] = Form.useForm();
  const startDate = Form.useWatch('startDate', form);
  const endDate = Form.useWatch('endDate', form);

  const [data, setData] = useState({});
  const {loading, refetch} = useQuery(GET_REVENUE_METRICS, {
    variables: {
      filters: {
        date: {
          range: 'MONTHLY',
        },
      },
    },
    fetchPolicy: 'no-cache',

    notifyOnNetworkStatusChange: true,
    onCompleted: data => setData(data?.getRevenueMetrics?.data),
  });

  return (
    <div>
      <div className={styles.filters}>
        <ExportFinancialButton
          startDate={startDate}
          endDate={endDate}
          query={GET_REVENUE_METRICS}
          type="Revenue"
        />

        <Form
          layout="horizontal"
          form={form}
          initialValues={{
            startDate: firstDayOfMonth,
            endDate: currentDayOfMonth,
          }}
          className={styles.form}
          onFinish={values => {
            if (values?.allTime) {
              return refetch({
                'filters': {
                  'date': {
                    'range': 'MONTHLY',
                  },
                  'allTime': true,
                },
              });
            }
            if (!values?.startDate && !values?.endDate) {
              return refetch({
                filters: {
                  date: {
                    range: 'MONTHLY',
                  },
                },
              });
            }
            refetch({
              filters: {
                date: {
                  ...(values?.startDate && {
                    startDate: moment(values?.startDate).format(),
                  }),
                  ...(values?.endDate && {
                    endDate: moment(values?.endDate).format(),
                  }),
                },
              },
            });
          }}>
          <Form.Item name="startDate">
            <DatePicker placeholder="Start Date" showTime allowClear />
          </Form.Item>
          <Form.Item name="endDate">
            <DatePicker placeholder="End Date" showTime allowClear />
          </Form.Item>
          <Form.Item name="allTime" label="All Time">
            <Switch />
          </Form.Item>
          <SubmitButton smallWidth label="Filter" />
        </Form>
      </div>
      <div className={styles.cardsRoot}>
        <div>
          <h2>Total Transactional Revenue (Variable + Fixed)</h2>
          <div className={styles.cards}>
            {loading
              ? Array(3)
                  .fill('')
                  .map((data, key) => (
                    <div key={key} className={styles.loadingSkeleton}>
                      {data}
                    </div>
                  ))
              : data?.totalRevenue?.map((data, key) => (
                  <FinancialCurrencyCard
                    type={data?.currency}
                    key={key}
                    currency={data?.currency}
                    symbol={data?.symbol}
                    amount={data?.amount}
                  />
                ))}
          </div>
        </div>
        <div>
          <h2>Total Platform fees (This is the fixed fee collected)</h2>
          <div className={styles.cards}>
            {loading
              ? Array(3)
                  .fill('')
                  .map((data, key) => (
                    <div key={key} className={styles.loadingSkeleton}>
                      {data}
                    </div>
                  ))
              : data?.totalPlatformFees?.map((data, key) => (
                  <FinancialCurrencyCard
                    type={data?.currency}
                    key={key}
                    currency={data?.currency}
                    symbol={data?.symbol}
                    amount={data?.amount}
                  />
                ))}
          </div>
          <div>
            <h2>Total Transaction fees (This is the variable fee collected)</h2>
            <div className={styles.cards}>
              {loading
                ? Array(3)
                    .fill('')
                    .map((data, key) => (
                      <div key={key} className={styles.loadingSkeleton}>
                        {data}
                      </div>
                    ))
                : data?.totalTransactionFees?.map((data, key) => (
                    <FinancialCurrencyCard
                      type={data?.currency}
                      key={key}
                      currency={data?.currency}
                      symbol={data?.symbol}
                      amount={data?.amount}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
