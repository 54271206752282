import {Form, Input, Select} from 'antd';
import styles from './feeSchedule.module.scss';
import plusIcon from 'images/plus-circle.png';
import deleteIcon from 'images/delete.png';

export const FeeSchedule = ({feeName, form, isOfStockExchange}) => {
  const feeTypes = Form.useWatch(feeName, form) || [];

  const isDisabled = (feeType, field, feeTypes, name) => {
    const fixedFeeAmount = feeTypes[name]?.fixedFeeAmount;
    const variableFeeAmount = feeTypes[name]?.variableFeeAmount;
    if (field === 'fixedFeeAmount') {
      return (
        feeType === 'VARIABLE' ||
        feeType === 'FLOATING' ||
        !feeType ||
        variableFeeAmount
      );
    }
    if (field === 'variableFeeAmount') {
      return feeType === 'FIXED' || !feeType || fixedFeeAmount;
    }
    return false;
  };

  return (
    <Form.List name={feeName}>
      {(fields, {add, remove}) => (
        <>
          {fields.map(({name}) => {
            const feeType = feeTypes[name]?.feeType;
            return (
              <div className={styles['card-container']} key={name}>
                <div className={styles['left-grid']}>
                  <Form.Item
                    rules={[
                      {required: true, message: 'This is a required field'},
                    ]}
                    label="Fee Name"
                    className={styles['card-item']}
                    name={[name, 'feeName']}>
                    <Input name="feeName" />
                  </Form.Item>
                  <Form.Item
                    label="Fee Type"
                    name={[name, 'feeType']}
                    rules={[
                      {required: true, message: 'This is a required field'},
                    ]}
                    className={styles['card-item']}>
                    <Select placeholder="select fee type" name="feeType">
                      <Select.Option value="FIXED">Fixed</Select.Option>
                      <Select.Option value="VARIABLE">Variable</Select.Option>
                      {isOfStockExchange && (
                        <>
                          <Select.Option value="FLOATING">
                            Floating
                          </Select.Option>
                          <Select.Option value="STATIC">Static</Select.Option>
                        </>
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Fixed Fee Amount"
                    name={[name, 'fixedFeeAmount']}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            isDisabled(
                              feeType,
                              'fixedFeeAmount',
                              feeTypes,
                              name,
                            )
                          ) {
                            return Promise.resolve();
                          }
                          if (value === undefined || value === '') {
                            return Promise.reject(
                              new Error('This field is required'),
                            );
                          }
                          return value >= 0
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error('Value must be 0 or greater'),
                              );
                        },
                      },
                    ]}
                    className={styles['card-item']}>
                    <Input
                      type="number"
                      min={0}
                      step="0.01"
                      placeholder="Input the fixed fee amount"
                      disabled={isDisabled(
                        feeType,
                        'fixedFeeAmount',
                        feeTypes,
                        name,
                      )}
                      name="fixedFeeAmount"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Variable Fee Amount (%)"
                    name={[name, 'variableFeeAmount']}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            isDisabled(
                              feeType,
                              'variableFeeAmount',
                              feeTypes,
                              name,
                            )
                          ) {
                            return Promise.resolve();
                          }
                          if (value === undefined || value === '') {
                            return Promise.reject(
                              new Error('This field is required'),
                            );
                          }
                          return value >= 0 && value <= 100
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error('Value must be between 0 and 100'),
                              );
                        },
                      },
                    ]}
                    className={styles['card-item']}>
                    <Input
                      placeholder="Input variable fee amount"
                      type="number"
                      max={100}
                      min={0}
                      step="0.01"
                      name={[name, 'variableFeeAmount']}
                      disabled={isDisabled(
                        feeType,
                        'variableFeeAmount',
                        feeTypes,
                        name,
                      )}
                    />
                  </Form.Item>
                </div>
                <div className={styles['delete-icon']}>
                  <img onClick={() => remove(name)} src={deleteIcon} alt="" />
                </div>
              </div>
            );
          })}
          <div onClick={add} className={styles['add-faq']}>
            <img src={plusIcon} alt="add-founder" />
            <span>Add Fee</span>
          </div>
        </>
      )}
    </Form.List>
  );
};
