import {useMutation, useQuery} from '@apollo/client';
import {
  Table,
  Tag,
  Dropdown,
  Menu,
  Form,
  Select,
  Input,
  Pagination,
} from 'antd';
import {GET_ALL_SWAP_TRANSACTIONS} from 'graphql/queries/wallet';
import {useParams, useNavigate} from 'react-router-dom';
import dotsIcon from 'icons/dots.svg';
import backIcon from 'images/back.png';
import {Button} from '@dabafinance/react-components';

import styles from './swapTransactions.module.scss';
import {useState} from 'react';
import SwapModal from './SwapModal';
import {currencySymbols, walletCurrencies} from 'utils/mock';
import DBModal from 'components/modal/modal';
import {UserSelectField} from 'components/userSelectField/UserSelectField';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {CREATE_USER_SWAP_TRANSACTION} from 'graphql/mutations/wallet';
import {toast} from 'react-toastify';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const SwapTransactions = () => {
  const {currency} = useParams();
  const [openUserModal, setOpenUserModal] = useState(false);
  const [updateModal, setUpdateModal] = useState({});
  const [openFinishScreen, setOpenFinishScreen] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [filterModal, setFilterModal] = useState(false);
  const [user, setUser] = useState();
  const [page, setPage] = useState(1);
  const [modalForm] = Form.useForm();
  const [limit, setLimit] = useState(10);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Swap),
  );

  const navigate = useNavigate();
  const {data, loading, refetch} = useQuery(GET_ALL_SWAP_TRANSACTIONS, {
    variables: {
      input: {
        sourceCurrency: currency === 'All' ? null : currency,
        pagination: {
          limit: 10,
          page: 1,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    onError: error => toast.error(error.message),
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
  const [swapCurrencies, {loading: loadingSwap}] = useMutation(
    CREATE_USER_SWAP_TRANSACTION,
  );

  const handleSort = (values, page, limit) => {
    setFilterValues(values);
    if (!page) setPage(1);
    const {sourceCurrency, targetCurrency, status} = values;
    refetch({
      input: {
        sourceCurrency,
        targetCurrency,
        status,
        pagination: {
          page: page ?? 1,
          limit,
        },
      },
    });

    setFilterModal(false);
  };

  const handlePageChange = currentPage => {
    if (page === currentPage) return;
    setPage(currentPage);
    handleSort(filterValues, currentPage, limit);
  };

  const column = [
    {
      key: 'user',
      title: 'User',
      dataIndex: 'user',
      render: (text, record) => (
        <p>{`${record?.user.firstName} ${record?.user?.lastName}`}</p>
      ),
    },
    {
      key: 'currencyPair',
      title: 'Currency Pair',
      dataIndex: 'currencyPair',
      render: (text, record) => (
        <p>
          {record?.sourceCurrency?.currency}/{record?.targetCurrency?.currency}
        </p>
      ),
    },
    {
      key: 'rate',
      title: 'Rate',
      dataIndex: 'rate',
      render: (text, record) => (
        <Tag color="default">
          {currencySymbols[record?.targetCurrency?.currency]?.symbol}{' '}
          {record?.rate}
        </Tag>
      ),
    },
    {
      key: 'amountPaid',
      title: 'Swap Amount',
      dataIndex: 'amountPaid',
      render: (text, record) => (
        <p>
          {record?.amountPaid?.toLocaleString()}{' '}
          {currencySymbols[record?.sourceCurrency?.currency]?.symbol}
        </p>
      ),
    },
    {
      key: 'amountSwapped',
      title: 'Amount Swapped',
      dataIndex: 'amountSwapped',
      render: (text, record) => (
        <p>
          {currencySymbols[record?.sourceCurrency?.currency]?.symbol}{' '}
          {record?.amountSwapped?.toLocaleString()}
        </p>
      ),
    },
    {
      key: 'Fee',
      title: 'Fees',
      dataIndex: 'Fee',
      render: (text, record) => (
        <p>
          {' '}
          {currencySymbols[record?.sourceCurrency?.currency]?.symbol}
          {record?.calculatedFees}
        </p>
      ),
    },
    {
      key: 'amountReceived',
      title: 'Amount Received',
      dataIndex: 'amountReceived',
      render: (text, record) => (
        <p>
          {currencySymbols[record?.targetCurrency?.currency]?.symbol}{' '}
          {record?.amountReceived?.toFixed(2)}{' '}
        </p>
      ),
    },

    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (text, render) => (
        <Tag
          color={
            render.status === 'SUCCESSFUL'
              ? 'green'
              : render?.status === 'PENDING'
              ? 'orange'
              : 'red'
          }>
          {render?.status}
        </Tag>
      ),
    },
    {
      key: 'createdAt',
      title: 'Date',
      dataIndex: 'createdAt',
      render: (text, render) => (
        <p className={styles.reference}>
          {moment(render?.createdAt).format('DD MMM, YYYY hh:mm A')}
        </p>
      ),
    },
    {
      key: 'action',
      title: 'Actions',
      dataIndex: 'action',
      render: (text, render) =>
        render.status === 'PENDING' && (
          <Dropdown
            trigger="hover"
            overlay={
              <Menu>
                {tabOperations?.update ? (
                  <>
                    <Menu.Item
                      key="1"
                      onClick={() =>
                        setUpdateModal({
                          open: true,
                          id: render?.id,
                          status: render?.status,
                        })
                      }>
                      <div>
                        <span>Fulfil / Reject</span>
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() =>
                        setUpdateModal({
                          updateOpen: true,
                          id: render?.id,
                          status: render?.status,
                          targetCurrency: render?.targetCurrency?.currency,
                          sourceCurrency: render?.sourceCurrency?.currency,
                          rate: render?.rate,
                        })
                      }
                      key="2">
                      Update Transaction
                    </Menu.Item>
                  </>
                ) : (
                  <Menu.Item>No Actions</Menu.Item>
                )}
              </Menu>
            }>
            <img src={dotsIcon} onClick={e => e.stopPropagation()} />
          </Dropdown>
        ),
    },
  ];

  const handleFieldValues = values => {
    setUpdateModal({user, ...values});
    setOpenFinishScreen(true);
  };

  const handleCreateTransaction = () => {
    swapCurrencies({
      variables: {
        input: {
          userId: user?.split(';')[2],
          amount: Number(updateModal?.amount),
          sourceCurrency: updateModal?.sourceCurrency,
          targetCurrency: updateModal?.targetCurrency,
        },
      },
    })
      .then(({data: {swapCurrencies}}) => {
        if (swapCurrencies.__typename === 'Error') {
          toast.error(swapCurrencies.message);
        } else {
          toast.success('Swap Transaction Created Successfully');
          modalForm.resetFields();
          refetch();
          setUpdateModal({});
          setOpenUserModal(false);
          setOpenFinishScreen(false);
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <div>
      <div className={styles.top}>
        <img onClick={() => navigate(-1)} src={backIcon} />
        <h1> {currency} Swap Transactions </h1>
      </div>
      <div className={styles['nav-buttons']}>
        <div className={styles['page-number']}>{page}</div>
      </div>
      <div className={styles.create}>
        <Button
          onClick={() => setOpenUserModal(true)}
          type="secondary"
          disabled={!tabOperations?.create}
          label="Manually Create Swap Transaction"
        />
        <Button onClick={() => setFilterModal(true)} label="Filter & Sort" />
        <div
          className={styles.filterItems}
          style={{display: filterModal ? 'block' : 'none'}}>
          <div className={styles.sortHeader}>
            <h1>Sort Transactions</h1>
            <p role="button" onClick={() => setFilterModal(false)}>
              X
            </p>
          </div>
          <Form onFinish={handleSort} layout="vertical">
            <Form.Item
              name="sourceCurrency"
              className={styles.bigWidth}
              label="Source Currency">
              <Select allowClear>
                {walletCurrencies.map(data => (
                  <Select.Option key={data.key}>{data.key}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.bigWidth}
              name="targetCurrency"
              label="Target Currency">
              <Select allowClear>
                {walletCurrencies.map(data => (
                  <Select.Option key={data.key}>{data.key}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item className={styles.bigWidth} name="status" label="Status">
              <Select allowClear>
                <Select.Option key="SUCCESSFUL">Successful</Select.Option>
                <Select.Option key="PENDING">Pending</Select.Option>
                <Select.Option key="FAILED">Failed</Select.Option>
              </Select>
            </Form.Item>
            <SubmitButton label="Apply Changes" disabled={loading} />
          </Form>
        </div>
      </div>
      <div>
        <Table
          loading={loading}
          columns={column}
          pagination={false}
          dataSource={data?.getAllSwapTransactions?.swapTransactions}
        />
        <Pagination
          current={page}
          onChange={e => handlePageChange(e)}
          total={data?.getAllSwapTransactions.paginate?.total}
          showQuickJumper
          className="ant-table-pagination"
          showSizeChanger
          onShowSizeChange={(_, size) => {
            setLimit(size);
            setPage(1);
            handleSort(filterValues, 1, size);
          }}
        />

        <SwapModal
          updateModal={updateModal}
          setUpdateModal={setUpdateModal}
          currency={currency}
          refetch={refetch}
        />
        <DBModal
          isOpen={openUserModal}
          handleClose={() => setOpenUserModal(false)}
          content={
            <div className={styles.modal}>
              {openFinishScreen ? (
                <div className="finishScreen">
                  <h1>You are about to Create a Swap Transaction</h1>
                  <span>
                    Are you sure you want to Proceed with this Action?
                  </span>
                  <div className="finish-items">
                    <div className="items">
                      <p>User</p>
                      <span>
                        {user?.split(';')[0]} {user?.split(';')[1]}
                      </span>
                    </div>
                    <div className="items">
                      <p>Source Currency</p>
                      <Tag color="green">{updateModal?.sourceCurrency}</Tag>
                    </div>
                    <div className="items">
                      <p>Target Currency</p>
                      <Tag color="blue">{updateModal?.targetCurrency}</Tag>
                    </div>
                    <div className="items">
                      <p>Amount ({updateModal?.sourceCurrency})</p>
                      <span>
                        {Number(updateModal?.amount)?.toLocaleString()}
                      </span>
                    </div>
                  </div>
                  <div className="finish-buttons">
                    <Button
                      className="finish-button"
                      label="No"
                      onClick={() => setOpenFinishScreen(false)}
                      type="secondary"
                    />
                    <Button
                      onClick={handleCreateTransaction}
                      className="finish-button"
                      disabled={loadingSwap}
                      label="Yes"
                    />
                  </div>
                </div>
              ) : (
                <>
                  <h1>Manually Create Swap Transaction</h1>
                  <Form
                    onFinish={handleFieldValues}
                    form={modalForm}
                    layout="vertical">
                    <Form.Item
                      className={styles.field}
                      rules={[
                        {required: !user, message: 'This field is required'},
                      ]}
                      name="user"
                      label="Select User">
                      <UserSelectField
                        mode="single"
                        users={user}
                        defaultUsers={user?.split(';')[2]}
                        setUsers={setUser}
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {required: true, message: 'This field is required'},
                      ]}
                      name="sourceCurrency"
                      className={styles.field}
                      label="Source Currency">
                      <Select>
                        {walletCurrencies.map(data => (
                          <Select.Option key={data.key}>
                            {data.key}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {required: true, message: 'This field is required'},
                      ]}
                      className={styles.field}
                      name="targetCurrency"
                      label="Target Currency">
                      <Select>
                        {walletCurrencies.map(data => (
                          <Select.Option key={data.key}>
                            {data.key}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      className={styles.field}
                      rules={[
                        {required: true, message: 'This field is required'},
                      ]}
                      name="amount"
                      label="Amount">
                      <Input type="number" />
                    </Form.Item>
                    <SubmitButton label="Create" />
                  </Form>
                </>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default SwapTransactions;
