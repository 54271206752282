import {useQuery} from '@apollo/client';
import {FinancialCurrencyCard} from './FinancialCards';
import {GET_VOLUME_METRIC} from 'graphql/queries/financial';
import {DatePicker, Form, Switch} from 'antd';
import styles from './financial.module.scss';
import moment from 'moment';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {ExportFinancialButton} from './ExportFinancialButton';
import {currentDayOfMonth, firstDayOfMonth} from './WalletMetrics';

export const VolumeMetrics = () => {
  const [form] = Form.useForm();
  const startDate = Form.useWatch('startDate', form);
  const endDate = Form.useWatch('endDate', form);

  const {loading, data, refetch} = useQuery(GET_VOLUME_METRIC, {
    variables: {
      filters: {
        date: {
          range: 'MONTHLY',
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  return (
    <>
      <div className={styles.filters}>
        <ExportFinancialButton
          startDate={startDate}
          endDate={endDate}
          query={GET_VOLUME_METRIC}
          type="Volume"
        />
        <Form
          layout="horizontal"
          initialValues={{
            startDate: firstDayOfMonth,
            endDate: currentDayOfMonth,
          }}
          form={form}
          className={styles.form}
          onFinish={values => {
            if (values?.allTime) {
              return refetch({
                'filters': {
                  'date': {
                    'range': 'MONTHLY',
                  },
                  'allTime': true,
                },
              });
            }
            if (!values?.startDate && !values?.endDate) {
              return refetch({
                filters: {
                  date: {
                    range: 'MONTHLY',
                  },
                },
              });
            }
            refetch({
              filters: {
                date: {
                  ...(values?.startDate && {
                    startDate: moment(values?.startDate).format(),
                  }),
                  ...(values?.endDate && {
                    endDate: moment(values?.endDate).format(),
                  }),
                },
              },
            });
          }}>
          <Form.Item name="startDate">
            <DatePicker placeholder="Start Date" showTime allowClear />
          </Form.Item>
          <Form.Item name="endDate">
            <DatePicker placeholder="End Date" showTime allowClear />
          </Form.Item>
          <Form.Item name="allTime" label="All Time">
            <Switch />
          </Form.Item>
          <SubmitButton smallWidth label="Filter" />
        </Form>
      </div>
      <div className={styles.cardsRoot}>
        {loading ? (
          <div className={styles.cards}>
            {Array(9)
              .fill('')
              .map((data, key) => (
                <div key={key} className={styles.loadingSkeleton}>
                  {data}
                </div>
              ))}
          </div>
        ) : (
          data?.getVolumeMetrics?.data?.map(
            data =>
              !!data?.items?.length && (
                <div key={data?.asset}>
                  <h2>{data?.asset}</h2>
                  <div className={styles.cards}>
                    {data?.items?.map((item, key) => (
                      <FinancialCurrencyCard
                        key={key}
                        currency={item?.type}
                        type={item?.currency}
                        symbol={item?.symbol}
                        amount={item?.amount}
                      />
                    ))}
                  </div>
                </div>
              ),
          )
        )}
      </div>
    </>
  );
};
