import {Dropdown, Table, Tag} from 'antd';
import styles from './savingCompanies.module.scss';
import {GET_SAVING_COMPANIES} from 'graphql/queries/savings';
import {useMutation, useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import dotsIcon from 'images/dots.svg';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {Button} from '@dabafinance/react-components';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {useState} from 'react';
import {updateSavingsOrganizationStatus} from './savingsCompanyActions';
import {UPDATE_SAVINGS_COMPANY_STATUS} from 'graphql/mutations/savings';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const SavingCompanies = () => {
  const [updateModal, setUpdateModal] = useState({isOpen: false});

  const [updateOrganization, {loading: loadingUpdate}] = useMutation(
    UPDATE_SAVINGS_COMPANY_STATUS,
  );
  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.SAVINGS),
  );

  const navigate = useNavigate();

  const {data, loading, refetch} = useQuery(GET_SAVING_COMPANIES, {
    variables: {
      filters: {
        page: 1,
        limit: 100,
      },
    },
    errorPolicy: 'all',
    onError: error =>
      toast.error('There was an Error loading this Page data' - error.message),
  });

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Organization Name',
      render: (text, record) => (
        <div className={styles.imageProfile}>
          <img src={record?.logo} alt="" />
          <span>{record?.name}</span>
        </div>
      ),
    },
    {
      dataIndex: 'Industry',
      key: 'Industry',
      title: 'Industry',
      render: (text, record) => <span>{record?.industry?.name || ''}</span>,
    },
    {
      dataIndex: 'userCount',
      key: 'userCount',
      title: 'No. of Users',
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      render: (text, record) => (
        <Tag color={record?.status === 'ACTIVE' ? 'green' : 'red'}>
          {record?.status}
        </Tag>
      ),
    },

    {
      dataIndex: 'Date Added',
      key: 'DateAdded',
      title: 'Date Added',
      render: (text, record) => (
        <span>
          {' '}
          {record?.createdAt
            ? moment(record?.createdAt).format('DD MMM, YYYY hh:mm A')
            : ''}
        </span>
      ),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: 'Actions',
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                label: 'View Info',
                key: 1,
                disabled: !tabOperations?.view,
                onClick: () =>
                  navigate(`/dashboard/savings/company/${record?.id}`),
              },
              {
                label: 'Update Status',
                key: 2,
                disabled: !tabOperations?.update,
                onClick: () =>
                  setUpdateModal({
                    isOpen: true,
                    data: {id: record?.id, status: record?.status},
                  }),
              },
            ],
          }}>
          <div>
            <img src={dotsIcon} alt="" />
          </div>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <h1>Organizations List</h1>
        <Button
          label="Create Organization"
          disabled={!tabOperations?.create}
          onClick={() => navigate(`/dashboard/savings/company/create`)}
        />
      </div>
      <div>
        <Table
          columns={columns}
          loading={loading}
          dataSource={data?.getOrganizations?.data}
        />
      </div>
      <ConfirmModal
        openModal={updateModal.isOpen}
        setOpenModal={() => setUpdateModal({isOpen: false})}
        title="Update Organization Status"
        confirmText={`You are updating the status of this Organization to ${
          updateModal?.data?.status === 'ACTIVE' ? 'IN-ACTIVE' : 'ACTIVE'
        }`}
        loading={loadingUpdate}
        handleOperation={() =>
          updateSavingsOrganizationStatus({
            id: updateModal?.data?.id,
            status:
              updateModal?.data?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
            updateOrganization,
            onClose: () => setUpdateModal({isOpen: false}),
            refetch,
          })
        }
      />
    </div>
  );
};

export default SavingCompanies;
