import {CustomLink} from 'components/investorUpdate/management';
import styles from './financial.module.scss';
import {Outlet} from 'react-router-dom';

export const FinancialHeader = () => {
  return (
    <div className={styles.root}>
      <h1>Revenue KPI&apos;s</h1>
      <div className={styles.routes}>
        <CustomLink to="/dashboard/financial/" name="Revenue Metrics" />
        <CustomLink to="/dashboard/financial/wallet" name="Wallet Metrics" />
        <CustomLink to="/dashboard/financial/orders" name="Order Metrics" />
        <CustomLink to="/dashboard/financial/volume" name="Volume Metrics" />
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};
