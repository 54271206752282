import {useMutation, useQuery} from '@apollo/client';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {USER_TRANSACTIONS} from 'graphql/queries/users';
import {Table, Spin, Dropdown, Form} from 'antd';
import styles from './transactions.module.scss';
import moment from 'moment';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {Button} from '@dabafinance/react-components';
import deleteIcon from 'images/delete.png';
import {DELETE_TRADE} from 'graphql/mutations/trades';
import {toast} from 'react-toastify';
import {currencySymbols} from 'utils/mock';
import {useEffect, useState} from 'react';
import UserStocks from './UserStocks';
import UserDividends from './UserDividends';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';
import DBModal from 'components/modal/modal';
import {CreateTransactionModal} from './CreateTransactionModal';

const UserTransactions = () => {
  const [activeTab, setActiveTab] = useState('LISTINGS');
  const [createModal, setCreateModal] = useState({open: false, type: ''});

  const [form] = Form.useForm();

  const {id} = useParams();
  const navigate = useNavigate();
  const route = useLocation();

  const {data, loading} = useQuery(USER_TRANSACTIONS, {
    variables: {
      getUserByIdId: id,
      userId: id,
    },
  });
  const [deleteTrade] = useMutation(DELETE_TRADE);

  const pageOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Users),
  );
  const role = useSelector(state => state?.auth?.userData?.user);

  const searchParamValue = new URLSearchParams(route.search).get('tab');

  useEffect(() => {
    if (searchParamValue === 'stock') {
      setActiveTab('STOCKS');
    } else if (searchParamValue === 'dividend') {
      setActiveTab('DIVIDENDS');
    }
  }, [searchParamValue]);

  const handleDeleteTrade = id => {
    deleteTrade({
      variables: {
        tradeId: id,
      },
      refetchQueries: [
        {
          query: USER_TRANSACTIONS,
          variables: {getUserByIdId: id, userId: id},
        },
      ],
    })
      .then(({data: {deleteTrade}}) => {
        if (deleteTrade.__typename === 'Error') {
          toast.error(deleteTrade.message);
        } else {
          toast.success('Trade Deleted Successfully');
        }
      })
      .catch(error => toast.error(error.message));
  };

  const columns = [
    {
      title: 'Campaign',
      dataIndex: 'campaign',
      key: 'campaign',
      render: (text, record) => (
        <div className={styles.logo}>
          <img alt="" src={record.fundraisingCompany?.logoImgURL} />
          <span>{record.fundraisingCompany?.companyName}</span>
        </div>
      ),
    },
    {
      title: 'Payment Method',
      dataIndex: 'payment',
      key: 'payment',
      render: (text, record) => <span>{record?.paymentMethod}</span>,
    },
    {
      title: 'Ticker',
      dataIndex: 'symbol',
      key: 'symbol',
      render: (text, record) => <span>{record?.symbol}</span>,
    },
    {
      title: 'Transaction Amount in Asset Currency',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>
            {currencySymbols[record?.assetCurrency].symbol}{' '}
            {record?.transactionAmount}
          </h4>
        </div>
      ),
    },
    {
      title: 'Invested Amount in Asset Currency',
      dataIndex: 'investedAmount',
      key: 'investedAmount',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>
            {currencySymbols[record?.assetCurrency].symbol}{' '}
            {record?.investedAmount}
          </h4>
        </div>
      ),
    },
    {
      title: 'Invested Amount in Transaction Currency',
      dataIndex: 'investedAmount',
      key: 'investedAmount',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>
            {currencySymbols[record?.transactionCurrency].symbol}{' '}
            {record?.investedAmountInTransactionCurrency}
          </h4>
        </div>
      ),
    },
    {
      title: 'Transaction Currency To Asset Currency FX',
      dataIndex: 'investedAmount',
      key: 'investedAmount',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>
            {record?.transactionCurrencyToAssetCurrencyFx
              ? `$1 = ${currencySymbols[record?.assetCurrency].symbol} ${Number(
                  record?.transactionCurrencyToAssetCurrencyFx,
                ).toLocaleString()}`
              : '--'}
          </h4>
        </div>
      ),
    },

    {
      title: 'Transaction Status',
      dataIndex: 'status',
      key: 'category',
      render: (text, record) => <span>{record?.transactionStatus}</span>,
    },
    {
      title: 'Transaction Units',
      dataIndex: 'units',
      key: 'units',
      render: (text, record) => <span>{record.transactionUnits}</span>,
    },
    {
      title: 'Fees in Asset Currency',
      dataIndex: 'fees',
      key: 'fees',
      render: (text, record) => (
        <span>
          {currencySymbols[record?.assetCurrency].symbol}{' '}
          {record.transactionFees}
        </span>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{moment(record.createdAt).format('DD MMMM')}</h4>
        </div>
      ),
    },
  ];

  if (pageOperations?.update) {
    columns.push({
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <div className={styles.actions}>
          <Button
            type="secondary"
            label="Update"
            onClick={() =>
              navigate(`/dashboard/user/${id}/update-transaction/${record.id}`)
            }
          />
          <img
            src={deleteIcon}
            alt=""
            onClick={() => handleDeleteTrade(record.id)}
          />
        </div>
      ),
    });
  }

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>All Transactions</h1>
        {['SUPERADMIN'].includes(role?.roleSettings?.name) && (
          <Dropdown
            trigger="click"
            menu={{
              items: [
                {
                  label: 'Create Listing',
                  key: '1',
                  onClick: () =>
                    navigate(`/dashboard/user/${id}/create-transaction`),
                },
                {
                  label: 'Buy Stock',
                  key: '2',
                  onClick: () =>
                    setCreateModal({open: true, type: 'BUY_STOCK'}),
                },
                {
                  label: 'Sell Stock',
                  key: '3',
                  onClick: () =>
                    setCreateModal({open: true, type: 'SELL_STOCK'}),
                },
                {
                  label: 'Buy Bond',
                  key: '4',
                  onClick: () => setCreateModal({open: true, type: 'BUY_BOND'}),
                },
                {
                  label: 'Sell Bond',
                  key: '5',
                  onClick: () =>
                    setCreateModal({open: true, type: 'SELL_BOND'}),
                },
              ],
            }}>
            <div>
              <SubmitButton label="Create Transaction" />
            </div>
          </Dropdown>
        )}
      </div>
      <div>
        <div className={styles['details-toggler']}>
          <span
            className={activeTab === 'LISTINGS' ? styles['active-toggle'] : ''}
            onClick={() => setActiveTab('LISTINGS')}>
            Listings
          </span>

          <span
            className={activeTab === 'STOCKS' ? styles['active-toggle'] : ''}
            onClick={() => setActiveTab('STOCKS')}>
            Orders
          </span>
          <span
            className={activeTab === 'DIVIDENDS' ? styles['active-toggle'] : ''}
            onClick={() => setActiveTab('DIVIDENDS')}>
            Dividends
          </span>
        </div>
      </div>
      <div>
        <div className={styles.table}>
          {activeTab === 'LISTINGS' && (
            <Table
              dataSource={data?.getUserById?.user?.transactions}
              columns={columns}
              className={styles['user-table']}
            />
          )}
          {activeTab === 'STOCKS' && <UserStocks />}
          {activeTab === 'DIVIDENDS' && <UserDividends />}
        </div>
      </div>
      <DBModal
        isOpen={createModal.open}
        handleClose={() => {
          setCreateModal({open: false, type: ''});
          form.resetFields();
        }}
        title="Create Transaction"
        width={900}
        content={
          <CreateTransactionModal
            type={createModal.type}
            onClose={setCreateModal}
            form={form}
          />
        }
      />
    </div>
  );
};

export default UserTransactions;
