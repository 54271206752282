import {toast} from 'react-toastify';

const FeeTypeEnum = {
  Fixed: 'FIXED',
  Floating: 'FLOATING',
  Static: 'STATIC',
  Variable: 'VARIABLE',
  Exit: 'EXIT',
  Recurring: 'RECURRING',
};

export const handleCreateOrder = ({
  type,
  state,
  mutation,
  id,
  setFinishScreen,
  onClose,
  action,
  form,
}) => {
  const companyId = JSON.parse(state.companyId);
  mutation({
    variables: {
      input: {
        orderType: state.orderType,
        ...(['BUY_BOND', 'SELL_BOND'].includes(type)
          ? {
              instrumentId: companyId.id,
              unit: state.shareUnit,
              unitPrice: state.sharePrice,
            }
          : {
              companyId: companyId.id,
              shareUnit: state.shareUnit,
              sharePrice: state.sharePrice,
            }),
        userId: id,
        orderValue: state.orderValue,
        ...(state.feeSchedule?.length && {
          feeSchedule: state.feeSchedule?.map(fee => ({
            feeName: fee?.feeName,
            feeType: fee?.feeType,
            ...(fee?.fixedFeeAmount && {
              fixedFeeAmount: Number(fee?.fixedFeeAmount),
            }),
            ...(fee?.variableFeeAmount && {
              variableFeeAmount: Number(fee?.variableFeeAmount),
            }),
          })),
        }),
      },
    },
  })
    .then(({data}) => {
      if (data[action].statusCode === 400) {
        toast.error(data[action].message);
      } else {
        toast.success(data[action].message);
        setFinishScreen({open: false, data: null});
        onClose();
        form.resetFields();
      }
    })
    .catch(error => {
      console.log(error);
    });
};

export function computeFeeFromFeeSchedule(
  feeSchedule,
  amount,
  skip = {
    skipFixed: false,
    skipFloating: false,
    skipStatic: false,
    skipStaticFixed: false,
    skipVariable: false,
    skipExit: false,
    skipExitFixed: false,
    skipRecurring: false,
    skipRecurringFixed: false,
  },
) {
  let transactionFees = 0;
  if (feeSchedule?.length) {
    feeSchedule.forEach(fee => {
      if (fee.feeType) {
        if (
          (skip.skipFixed && fee?.feeType === FeeTypeEnum.Fixed) ||
          (skip.skipFloating && fee?.feeType === FeeTypeEnum.Floating) ||
          (skip.skipStatic && fee?.feeType === FeeTypeEnum.Static) ||
          (skip.skipVariable && fee?.feeType === FeeTypeEnum.Variable) ||
          (skip.skipExit && fee?.feeType === FeeTypeEnum.Exit) ||
          (skip.skipRecurring && fee?.feeType === FeeTypeEnum.Recurring)
        ) {
          return;
        }
        if (
          [
            FeeTypeEnum.Fixed,
            FeeTypeEnum.Static,
            FeeTypeEnum.Exit,
            FeeTypeEnum.Recurring,
          ].includes(fee.feeType) &&
          fee?.fixedFeeAmount &&
          fee.fixedFeeAmount > 0 &&
          !(skip.skipStaticFixed && fee?.feeType === FeeTypeEnum.Static) &&
          !(skip.skipExitFixed && fee?.feeType === FeeTypeEnum.Exit) &&
          !(skip.skipRecurringFixed && fee?.feeType === FeeTypeEnum.Recurring)
        ) {
          transactionFees += fee?.fixedFeeAmount ?? 0;
        } else if (fee?.variableFeeAmount && fee.variableFeeAmount > 0) {
          transactionFees += (fee.variableFeeAmount / 100) * amount;
        }
      }
    });
  }
  return transactionFees;
}

export const estimatedTransactionFee = (feeSchedule, amount, skip = {}) => {
  return computeFeeFromFeeSchedule(feeSchedule, amount, skip);
};
