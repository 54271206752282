import {Form, InputNumber, Select, Tag} from 'antd';
import styles from './transactions.module.scss';
import {GET_ALL_COMPANY} from 'graphql/queries/report';
import {useMutation, useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {FeeSchedule} from 'components/feeSchedule/FeeSchedule';
import {useState} from 'react';
import {Button} from '@dabafinance/react-components';
import {currencySymbols} from 'utils/mock';
import {
  BUY_BOND,
  BUY_STOCK,
  SELL_BOND,
  SELL_STOCK,
} from 'graphql/mutations/trades';
import {estimatedTransactionFee, handleCreateOrder} from './tradeActions';
import {useParams} from 'react-router-dom';
import {GET_ASSET_CLASS_INSTRUMENTS} from 'graphql/queries/assetManagement';
import _ from 'lodash';

export const CreateTransactionModal = ({type, onClose, form}) => {
  const [instruments, setInstruments] = useState([]);

  const [finishScreen, setFinishScreen] = useState({open: false, data: null});
  const {id} = useParams();
  const orderType = Form.useWatch('orderType', form);

  const tradeType = tradeTypes?.[type];

  const {data: getAllCompanies, loading: loadingCompanies} = useQuery(
    GET_ALL_COMPANY,
    {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      skip: ['BUY_BOND', 'SELL_BOND'].includes(type),
      notifyOnNetworkStatusChange: true,
      onError: error => toast.error(error.message),
    },
  );

  const {refetch: refetchInstruments, loading: loadingInstruments} = useQuery(
    GET_ASSET_CLASS_INSTRUMENTS,
    {
      variables: {
        input: {
          explore: false,
          assetClassKey: 'FIXED_INCOME',
          instrumentKey: 'BOND',
          page: 1,
          limit: 100,
        },
      },
      skip: !['BUY_BOND', 'SELL_BOND'].includes(type),
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onError: error => toast.error(error.message),
    },
  );

  // Mutations
  const [mutation, {loading}] = useMutation(tradeType?.mutation);

  const handleSearch = _.debounce(async value => {
    try {
      const {data} = await refetchInstruments({
        input: {
          explore: false,
          instrumentKey: 'BOND',
          assetClassKey: 'FIXED_INCOME',
          search: value,
          page: 1,
          limit: 100,
        },
      });
      setInstruments(data.getFinancialInstruments?.data || []);
    } catch (error) {
      console.error('Error fetching instruments:', error);
    }
  }, 1200);

  return (
    <div className={styles.createModal}>
      <h1>Create {tradeType?.label} Order</h1>
      <Form
        onFinish={values => {
          setFinishScreen({open: true, data: values});
        }}
        onFieldsChange={(changedField, allFields) => {
          const {companyId} = allFields.reduce((acc, field) => {
            acc[field.name[0]] = field.value;
            return acc;
          }, {});
          const fieldChanged = changedField[0];
          const fieldName = fieldChanged.name[0];
          const fieldValue = fieldChanged.value;

          if (fieldValue === 'MARKET' && form.getFieldValue('companyId')) {
            const company = JSON?.parse(form.getFieldValue('companyId'));
            const lastPrice = company?.lastPrice;
            const currency = company?.currency;
            form.setFieldsValue({
              sharePrice: lastPrice,
              currency: currency,
            });
          }

          if (fieldName === 'companyId') {
            const company = JSON?.parse(fieldValue);
            const lastPrice = company?.lastPrice;
            const currency = company?.currency;
            form.setFieldsValue({
              sharePrice: lastPrice,
              currency: currency,
              orderValue: lastPrice * (form.getFieldValue('shareUnit') || 0),
            });
          }

          if (fieldName === 'shareUnit') {
            const company = companyId ? JSON.parse(companyId) : null;
            const lastPrice =
              company?.lastPrice || form.getFieldValue('sharePrice') || 0;
            form.setFieldsValue({
              orderValue: lastPrice * fieldValue,
            });
          }
        }}
        layout="vertical"
        form={form}>
        {finishScreen.open ? (
          <div>
            <h2>Transaction Summary</h2>
            <div className={styles.summary}>
              <p>
                <strong>Company:</strong>{' '}
                {JSON.parse(finishScreen.data.companyId)?.name}
              </p>
              <p>
                <strong>Direction:</strong>{' '}
                <Tag
                  color={
                    tradeTypes?.[type]?.direction === 'BUY' ? 'green' : 'red'
                  }>
                  {tradeTypes?.[type]?.direction}
                </Tag>
              </p>
              <p>
                <strong>Order Type:</strong>
                <Tag
                  color={
                    finishScreen.data.orderType === 'MARKET' ? 'blue' : 'orange'
                  }>
                  {finishScreen.data.orderType}
                </Tag>
              </p>
              <p>
                <strong>Share Price:</strong>
                {currencySymbols[
                  JSON.parse(finishScreen.data?.companyId)?.currency
                ]?.symbol || ''}{' '}
                {finishScreen.data?.sharePrice}
              </p>
              <p>
                <strong>Share Unit:</strong> {finishScreen.data?.shareUnit}
              </p>
              <p>
                <strong>Order Value:</strong>{' '}
                {currencySymbols[
                  JSON.parse(finishScreen.data.companyId)?.currency
                ]?.symbol || ''}{' '}
                {finishScreen.data.orderValue?.toLocaleString()}
              </p>
              <p>
                <strong>Estimated Fee:</strong>{' '}
                {currencySymbols[
                  JSON.parse(finishScreen.data?.companyId)?.currency
                ]?.symbol || ''}{' '}
                {estimatedTransactionFee(
                  finishScreen.data?.feeSchedule?.length
                    ? finishScreen.data?.feeSchedule?.map(fee => ({
                        feeName: fee?.feeName,
                        feeType: fee?.feeType,
                        ...(fee?.fixedFeeAmount && {
                          fixedFeeAmount: Number(fee?.fixedFeeAmount),
                        }),
                        ...(fee?.variableFeeAmount && {
                          variableFeeAmount: Number(fee?.variableFeeAmount),
                        }),
                      }))
                    : JSON.parse(finishScreen.data.companyId)?.feeSchedule,
                  finishScreen.data.orderValue,
                  {
                    ...(['BUY_STOCK', 'BUY_BOND'].includes(type) && {
                      skipExit: true,
                      skipRecurring: true,
                    }),
                    ...(['SELL_STOCK', 'SELL_BOND'].includes(type) && {
                      skipFloating: true, // don't charge on floating fees
                      // skipStatic: true, // applies only to limit buy orders
                      skipExit: true, // does not apply
                      skipRecurring: true, // does not apply
                    }),
                  },
                )}
              </p>
            </div>
            <div className="buttons">
              <Button
                onClick={() => setFinishScreen({open: false, data: null})}
                label="Cancel"
                type="secondary"
              />
              <Button
                disabled={loading}
                label="Confirm"
                onClick={() => {
                  handleCreateOrder({
                    type,
                    state: finishScreen.data,
                    id,
                    mutation,
                    setFinishScreen,
                    onClose: () => onClose({open: false, type: ''}),
                    form,
                    action: tradeType.action,
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <div className={styles.form}>
            {['BUY_BOND', 'SELL_BOND'].includes(type) ? (
              <Form.Item
                className={styles.field}
                rules={[
                  {required: true, message: 'Please select a Instrument'},
                ]}
                name="companyId"
                label="Select Instrument">
                <Select
                  loading={loadingInstruments}
                  showSearch
                  onSearch={handleSearch}
                  allowClear
                  filterOption={false}
                  placeholder="Select Instrument">
                  {instruments?.map(instrument => (
                    <Select.Option
                      key={JSON.stringify({
                        id: instrument?.id,
                        name: instrument?.name,
                        ticker: instrument?.ticker,
                        currency: instrument?.currency?.currencyCode,
                        feeSchedule: instrument?.feeSchedule,
                        lastPrice: instrument?.currentMarketPrice,
                        fractionalShares: instrument?.fractionable,
                      })}>
                      {instrument?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                rules={[{required: true, message: 'Please select a company'}]}
                label="Select Company"
                name="companyId"
                className={styles.field}>
                <Select
                  allowClear
                  placeholder="Select Company"
                  showSearch
                  loading={loadingCompanies}
                  optionFilterProp="label">
                  {getAllCompanies?.getAllCompanies.map(data => (
                    <Select.Option
                      key={JSON.stringify({
                        id: data?.id,
                        name: data?.legalName,
                        ticker: data?.ticker,
                        lastPrice: data?.stock?.lastPrice,
                        currency: data?.stock?.baseCurrencyCode,
                        feeSchedule: data?.feeSchedule,
                        fractionalShares: data?.fractionalShares,
                      })}
                      label={`${data?.legalName} ${data?.ticker}`}>
                      <div className={styles['all-data']}>
                        <img src={data?.logoImgURL} alt="" />
                        <span>{data?.legalName}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <div className={styles.flexed}>
              <Form.Item
                name="orderType"
                rules={[
                  {required: true, message: 'Please select an order type'},
                ]}
                label="Order Type"
                className={styles.smallField}>
                <Select placeholder="Select Order Type">
                  <Select.Option value="MARKET">Market</Select.Option>
                  <Select.Option value="LIMIT">Limit</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="sharePrice"
                rules={[
                  {required: true, message: 'Please input an Asset Price'},
                ]}
                label="Asset Price"
                className={styles.smallField}>
                <InputNumber
                  disabled={orderType === 'MARKET'}
                  className={styles['input-number']}
                  placeholder="Enter Asset Price"
                  min={0}
                />
              </Form.Item>
              <Form.Item
                name="shareUnit"
                rules={[
                  {required: true, message: 'Please input a Share Unit'},
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      const companyData = getFieldValue('companyId')
                        ? JSON.parse(getFieldValue('companyId'))
                        : null;
                      if (!companyData?.fractionalShares && value % 1 !== 0) {
                        return Promise.reject(
                          'Fractional shares are not allowed',
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                label="Share Unit"
                className={styles.smallField}>
                <InputNumber
                  className={styles['input-number']}
                  placeholder="Enter Unit"
                  min={0}
                />
              </Form.Item>
              <Form.Item
                name="orderValue"
                rules={[
                  {required: true, message: 'Please input a Total Value'},
                  () => ({
                    validator(_, value) {
                      if (value < 1) {
                        return Promise.reject(
                          'Total value must be greater than 0',
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                label="Total Value"
                className={styles.smallField}>
                <InputNumber
                  disabled
                  className={styles['input-number']}
                  placeholder="Enter Total Value"
                  min={1}
                  formatter={value =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </div>
            <h2>Fee Schedule</h2>
            <FeeSchedule feeName="feeSchedule" form={form} />
            <div className={styles.notes}>
              <strong>Notes</strong>
              <p>
                1. Fees are <strong>OPTIONAL</strong>, If the Fee is not set the
                one on the Company / Instrument will be used.
              </p>
              <p>
                2. An estimated fee is calculated which is just an estimate and
                may not be the exact fee that will be charged.
              </p>
            </div>
            <SubmitButton label="Proceed" />
          </div>
        )}
      </Form>
    </div>
  );
};

const tradeTypes = {
  BUY_STOCK: {
    mutation: BUY_STOCK,
    direction: 'BUY',
    label: 'Buy Stock',
    action: 'buyStock',
  },
  SELL_STOCK: {
    mutation: SELL_STOCK,
    direction: 'SELL',
    label: 'Sell Stock',
    action: 'sellStock',
  },
  BUY_BOND: {
    mutation: BUY_BOND,
    direction: 'BUY',
    label: 'Buy Bond',
    action: 'buyBond',
  },
  SELL_BOND: {
    mutation: SELL_BOND,
    direction: 'SELL',
    label: 'Sell Bond',
    action: 'sellBond',
  },
};
