import {Dropdown, Tag, Tooltip} from 'antd';
import moment from 'moment';
import {toast} from 'react-toastify';
import {
  computeFeeFromFeeSchedule,
  convertToNumber,
  extractCompanyId,
  extractUserId,
  formatValue,
  sanitizeInput,
} from 'utils/helper';
import {ConfirmModal} from './HandleOrders';
import {HOLD_ORDER} from 'graphql/mutations/stocks';
import {useMutation} from '@apollo/client';

const handleStocksOrderFilter = (
  values,
  setFilterValues,
  page,
  user,
  refetch,
  activeTab,
  limit,
  searchTicker,
  searchedValue,
  isAffiliate,
  setOpenFilterModal,
) => {
  setFilterValues(values);
  let {
    limitPriceLessThan,
    limitPriceGreaterThan,
    companyId,
    startDate,
    status,
    endDate,
    collection,
    stockExchange,
    collectionId,
    startExpiryDate,
    instrumentKey,
    instrumentId,
    hold,
    affiliateOrder,
    endExpiryDate,
    type,
    direction,
    startSettlementDate,
    endSettlementDate,
    startFillDate,
    endFillDate,
  } = values;
  const formattedValues = {
    limitPriceLessThan: convertToNumber(limitPriceLessThan),
    limitPriceGreaterThan: convertToNumber(limitPriceGreaterThan),
    companyId: extractCompanyId(companyId),
    userId: extractUserId(user),
    startDate: formatValue(startDate, d => moment(d).format()),
    endDate: formatValue(endDate, d => moment(d).format()),
    startExpiryDate: formatValue(startExpiryDate, d => moment(d).format()),
    endExpiryDate: formatValue(endExpiryDate, d => moment(d).format()),
    startSettlementDate: formatValue(startSettlementDate, d =>
      moment(d).format(),
    ),
    endSettlementDate: formatValue(endSettlementDate, d => moment(d).format()),
    startFillDate: formatValue(startFillDate, d => moment(d).format()),
    endFillDate: formatValue(endFillDate, d => moment(d).format()),
  };

  refetch({
    input: {
      status: status
        ? status
        : ['PENDING', 'FILLED', 'EXPIRED'].includes(activeTab)
        ? activeTab
        : null,
      limit,
      collection,
      collectionId,
      affiliateOrder,
      stockExchange,
      instrumentKey,
      hold: hold ?? activeTab === 'HOLD',
      instrumentId,
      type,
      direction,
      ...formattedValues,
      search: searchTicker || searchedValue || null,
      page: page ?? 1,
      'paginate': true,
      'newFirst': true,
      ...(isAffiliate && {affiliateOrder: isAffiliate}),
      includeOrdersWithElapsedExpiry: true,
      openOrders:
        activeTab === 'ACTIVE' && !['PARTIALLY_FILLED', 'OPEN'].includes(status)
          ? true
          : [
              'PARTIALLY_FILLED',
              'REJECTED',
              'OPEN',
              'ERROR',
              'CANCELLED',
              'SETTLED',
            ].includes(status) ||
            ['PENDING', 'FILLED', 'HOLD', 'EXPIRED'].includes(activeTab)
          ? null
          : false,
    },
  });
  setOpenFilterModal(false);
};

const handleStocksOrdersColumns = (
  navigate,
  styles,
  gamer,
  tabOperations,
  handleTab,
  setCopied,
  copied,
  dotsIcon,
) => {
  const getOrderMenuItems = (render, tabOperations) => {
    const {status, type, history, notes, hold, id} = render;

    const isOpen = status === 'OPEN';
    const isPending = status === 'PENDING';
    const isFilled = status === 'FILLED';
    const isOpenOrPartiallyFilled = ['OPEN', 'PARTIALLY_FILLED'].includes(
      status,
    );
    const canHold = ['OPEN', 'PENDING', 'PARTIALLY_FILLED', 'FILLED'].includes(
      status,
    );
    const canExpire = isOpenOrPartiallyFilled && type === 'LIMIT';

    if (hold) {
      return [
        {
          key: '1',
          label: 'Release Hold',
          onClick: () => handleTab('ONHOLD', id),
        },
      ];
    }

    if (!tabOperations?.update) {
      return status !== 'PENDING'
        ? [
            {
              key: '5',
              label: 'View History',
              onClick: e => handleTab('HISTORY', history, e),
            },
          ]
        : [];
    }

    const menuItems = [];

    // Handle Open/Partially Filled status
    if (isOpenOrPartiallyFilled) {
      menuItems.push(
        {
          key: '1',
          label: 'Reject Order',
          onClick: () => handleTab('REJECT', id),
        },
        {
          key: '2',
          label: 'Fill Order',
          onClick: e => handleTab('SETTLE', id, e),
        },
      );
    }

    // Handle Filled status
    if (isFilled) {
      menuItems.push({
        key: '2',
        label: 'Settle Order',
        onClick: e => handleTab('FILLED', render, e),
      });
    }

    // Handle Pending status
    if (isPending) {
      menuItems.push(
        {
          key: '3',
          label: 'Open Order',
          onClick: e => handleTab('PENDING', id, e),
        },
        {
          key: '4',
          label: 'Reject Order',
          onClick: () => handleTab('REJECT', id),
        },
      );
    }

    // Add common actions
    if (isOpen) {
      menuItems.push({
        key: '6',
        label: 'Revert to Pending',
        onClick: e => handleTab('OPEN', id, e),
      });
    }

    if (!isPending) {
      menuItems.push({
        key: '5',
        label: 'View History',
        onClick: e => handleTab('HISTORY', history, e),
      });
    }

    if (canExpire) {
      menuItems.push({
        key: '7',
        label: 'Expire Order',
        onClick: e => handleTab('OPEN', {id, type: 'EXPIRE'}, e),
      });
    }

    if (!isPending && tabOperations?.update) {
      menuItems.push({
        key: '10',
        label: 'Rewrite Order',
        onClick: () => handleTab('REWRITE', id),
      });
    }

    // Always add Notes option
    menuItems.push({
      key: '8',
      label: 'View / Edit Notes',
      onClick: e => handleTab('NOTES', {notes, id}, e),
    });

    // Add Hold Order option for specified statuses
    if (canHold) {
      menuItems.push({
        key: 'hold',
        label: hold ? 'Remove Hold' : 'Hold Order',
        onClick: () => handleTab(hold ? 'ONHOLD' : 'HOLD', id),
      });
    }

    return menuItems;
  };

  const handleCopy = referenceId => {
    navigator.clipboard.writeText(referenceId).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 400);
    });
  };

  return [
    {
      title: 'Order ID',
      key: 'id',
      dataIndex: 'id',
      render: (text, record) => (
        <Tooltip trigger="hover" title={copied ? 'Copied' : 'Copy'}>
          <b
            style={{cursor: 'pointer'}}
            onClick={e => {
              e.stopPropagation();
              handleCopy(record?.id);
            }}>
            {record?.id}
          </b>
        </Tooltip>
      ),
    },

    {
      title: 'User',
      key: 'user',
      dataIndex: 'user',
      render: (text, record) => (
        <div
          onClick={() =>
            navigate(
              `/dashboard/user/${record?.user?.id}/transactions?tab=stock`,
            )
          }
          className={styles['record-label']}>
          <div
            onClick={() => navigate(`/dashboard/user/${record.id}`)}
            className={styles['record-name']}>
            {record?.user?.imageUrl ? (
              <img src={record?.user?.imageUrl} alt="" />
            ) : (
              <img src={gamer} alt="" />
            )}
            <h4>
              {record?.user?.firstName} {record?.user?.lastName}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: 'Ticker',
      key: 'ticker',
      dataIndex: 'ticker',
      render: (text, render) => <span>{render?.symbol}</span>,
    },
    {
      title: 'Direction',
      key: 'direction',
      dataIndex: 'direction',
    },

    {
      title: 'Instrument',
      key: 'instrumentKey',
      dataIndex: 'instrumentKey',
      render: (text, render) => (
        <Tag
          color={
            {
              'BOND': 'cyan',
              'STOCK': 'red',
              'MUTUAL_FUND': 'orange',
            }[render?.instrumentKey]
          }>
          {render?.instrumentKey}
        </Tag>
      ),
    },
    {
      title: 'Currency',
      key: 'assetCurrency',
      dataIndex: 'assetCurrency',
    },
    {
      title: 'Order Type',
      key: 'type',
      dataIndex: 'type',
      render: (text, render) => (
        <Tag color={render?.type === 'LIMIT' ? 'black' : 'default'}>
          {render?.type}
        </Tag>
      ),
    },
    {
      title: 'Hold Status',
      key: 'hold',
      dataIndex: 'hold',
      render: (text, render) =>
        render?.hold ? (
          <Tag color={render?.hold ? 'red' : ''}>
            {render?.hold ? 'ON HOLD' : ''}
          </Tag>
        ) : (
          '- -'
        ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (text, render) => (
        <Tag
          color={
            {
              'FILLED': 'green',
              'PENDING': 'orange',
              'ERROR': 'red',
              'REJECTED': 'red',
              'CANCELLED': 'red',
              'SETTLED': 'cyan',
              'EXPIRED': 'red',
              'PARTIALLY_FILLED': 'yellow',
            }[render?.status] || 'blue'
          }
          key={render.id}>
          {render.status}
        </Tag>
      ),
    },
    {
      title: 'Collection',
      key: 'collectionId',
      dataIndex: 'collectionId',
      render: (text, record) =>
        record?.collection ? (
          <div className={styles['record-label']}>
            <div className={styles['record-name']}>
              <img src={record?.collection?.bannerUrl} />
              <h4>{record?.collection?.name}</h4>
            </div>
          </div>
        ) : (
          <span>- -</span>
        ),
    },
    {
      title: 'Stock Price',
      key: 'stockPrice',
      dataIndex: 'stockPrice',
      render: (text, render) => (
        <span>{render?.limitPrice?.toLocaleString()}</span>
      ),
    },
    {
      title: 'Fill Price',
      key: 'avgFillPrice',
      dataIndex: 'avgFillPrice',
      render: (text, render) => (
        <span>{render?.avgFillPrice?.toLocaleString()}</span>
      ),
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Order Total (Ex. Fees)',
      key: 'price',
      dataIndex: 'price',
      render: (text, render) => (
        <span>
          {(
            render?.quantity * (render?.avgFillPrice || render.limitPrice)
          ).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'Static Fee',
      key: 'staticFee',
      dataIndex: 'staticFee',
    },
    {
      title: 'Floating Fee',
      key: 'FloatingFee',
      dataIndex: 'floatingFee',
      render: (text, render) => (
        <span>
          {computeFeeFromFeeSchedule(render?.feeSchedule, render?.price, {
            skipFixed: true,
            skipStatic: true,
            skipVariable: true,
          })}
        </span>
      ),
    },
    {
      title: 'Fee Charged',
      key: 'feeCharged',
      dataIndex: 'feeCharged',
      render: (text, render) => <span>{render?.feeCharged?.toFixed(2)}</span>,
    },
    {
      title: 'Expiration Date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (text, record) => (
        <span>
          {record?.expiresAt
            ? moment(record?.expiresAt).format('DD MMMM, YYYY h:mmA')
            : ''}
        </span>
      ),
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (text, record) => (
        <span>{moment(record?.createdAt).format('DD MMMM, YYYY h:mmA')}</span>
      ),
    },
    {
      title: 'Filled Date',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: (text, record) =>
        record?.lastFillDate ? (
          <span>
            {moment(record?.lastFillDate).format('DD MMMM, YYYY h:mmA')}
          </span>
        ) : (
          ''
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      fixed: 'right',
      render: (text, render) => (
        <Dropdown
          trigger={['click']}
          menu={{items: getOrderMenuItems(render, tabOperations)}}>
          <img src={dotsIcon} onClick={e => e.stopPropagation()} />
        </Dropdown>
      ),
    },
  ];
};

const bulkOperationItems = (
  tab,
  handleOperation,
  disabled,
  setOrderModal,
  additionalCheck,
) => {
  const defaultTabs = [
    {
      key: '1',
      label: 'Bulk Fill Selected Orders',
      disabled,
      onClick: () => handleOperation('SETTLE'),
    },
    {
      key: '2',
      label: 'Bulk Fill CSV Orders',
      onClick: () => setOrderModal('FILL_CSV'),
    },
    {
      key: '3',
      label: 'Bulk Revert Selected Orders to Pending',
      disabled,
      onClick: () => handleOperation('OPEN'),
    },
    {
      key: '5',
      label: 'Bulk Expire Orders',
      disabled: disabled || additionalCheck,
      onClick: () => handleOperation('OPEN', 'EXPIRE'),
    },
    {
      key: '6',
      label: 'Bulk Reject Open Orders',
      disabled,
      onClick: () => handleOperation('REJECT'),
    },
  ];

  if (tab === 'PENDING') {
    return [
      {
        key: 1,
        disabled,
        label: 'Open Selected Orders',
        onClick: () => handleOperation('PENDING'),
      },
      {
        key: 'hold',
        label: 'Bulk Hold Selected Orders',
        disabled,
        onClick: () => handleOperation('HOLD'),
      },
      {
        key: 'onhold',
        label: 'Bulk Release Selected Orders On Hold',
        disabled,
        onClick: () => handleOperation('ONHOLD'),
      },
    ];
  } else if (tab === 'HOLD') {
    return [
      {
        key: 'onhold',
        label: 'Bulk Release Selected Orders On Hold',
        disabled,
        onClick: () => handleOperation('ONHOLD'),
      },
    ];
  } else if (tab === 'FILLED') {
    return [
      {
        key: 1,
        disabled,
        label: 'Bulk Settle Selected Orders',
        onClick: () => handleOperation('FILLED'),
      },
      {
        key: 2,
        label: 'Bulk Settle CSV Orders',
        onClick: () => setOrderModal('SETTLE_CSV'),
      },
      {
        key: 'hold',
        label: 'Bulk Hold Selected Orders',
        disabled,
        onClick: () => handleOperation('HOLD'),
      },
      {
        key: 'onhold',
        label: 'Bulk Release Selected Orders On Hold',
        disabled,
        onClick: () => handleOperation('ONHOLD'),
      },
    ];
  } else if (tab === 'ACTIVE') {
    return [
      ...defaultTabs,
      {
        key: 'hold',
        label: 'Bulk Hold Selected Orders',
        disabled,
        onClick: () => handleOperation('HOLD'),
      },
      {
        key: 'onhold',
        label: 'Bulk Release Selected Orders On Hold',
        disabled,
        onClick: () => handleOperation('ONHOLD'),
      },
    ];
  } else {
    return [];
  }
};

const handleSearchStockTicker = (
  searchTerm,
  user,
  active,
  pageLimit,
  existingFilters,
  refetch,
  currentPage,
  isAffiliate,
  handleResetPage,
) => {
  // Check for double preceding whitespace or double whitespace
  const sanitizedValue = sanitizeInput(searchTerm);

  let {
    companyId,
    startDate,
    endDate,
    collection,
    collectionId,
    startExpiryDate,
    endExpiryDate,
    status,
    type,
    affiliateOrder,
    stockExchange,
    instrumentKey,
    hold,
    instrumentId,
    direction,
    startSettlementDate,
    endSettlementDate,
    startFillDate,
    endFillDate,
  } = existingFilters;

  const formattedValues = {
    companyId: extractCompanyId(companyId),
    userId: extractUserId(user),
    startDate: formatValue(startDate, d => moment(d).format()),
    endDate: formatValue(endDate, d => moment(d).format()),
    startExpiryDate: formatValue(startExpiryDate, d => moment(d).format()),
    endExpiryDate: formatValue(endExpiryDate, d => moment(d).format()),
    startSettlementDate: formatValue(startSettlementDate, d =>
      moment(d).format(),
    ),
    endSettlementDate: formatValue(endSettlementDate, d => moment(d).format()),
    startFillDate: formatValue(startFillDate, d => moment(d).format()),
    endFillDate: formatValue(endFillDate, d => moment(d).format()),
  };

  handleResetPage();
  refetch({
    input: {
      ...formattedValues,
      collection,
      collectionId,
      type,
      direction,
      instrumentKey,
      hold: hold ?? active === 'HOLD',
      stockExchange,
      instrumentId,
      affiliateOrder,
      status: ['PENDING', 'FILLED', 'EXPIRED'].includes(status || active)
        ? status || active
        : null,
      search: sanitizedValue.toUpperCase() ?? null,
      page: currentPage,
      'paginate': true,
      limit: pageLimit,
      'newFirst': true,
      ...(isAffiliate && {affiliateOrder: isAffiliate}),
      includeOrdersWithElapsedExpiry: true,
      openOrders:
        active === 'ACTIVE' && !['PARTIALLY_FILLED', 'OPEN'].includes(status)
          ? true
          : ['PENDING', 'FILLED', 'HOLD', 'EXPIRED'].includes(active) ||
            ['PARTIALLY_FILLED', 'OPEN'].includes(status)
          ? null
          : false,
    },
  });
};

const handleOrderRewrite = ({
  rewriteOrder,
  refetch,
  setOrder,
  setOrderModal,
  orderId,
}) => {
  rewriteOrder({
    variables: {
      orderId,
    },
  })
    .then(({data: {rewrite}}) => {
      if (rewrite.statusCode === 400 || rewrite.__typename === 'Error') {
        toast.error(rewrite?.message);
      } else {
        toast.success('Order Rewrite Successful');
        refetch();
        setOrder('');
        setOrderModal(false);
      }
    })
    .catch(error => toast.error(error));
};

const HoldOrderModal = ({
  holdStatus,
  orderId,
  setOrder,
  setOrderModal,
  refetch,
  setOrderDetails,
  orderModal,
  orderIds,
  clearOrderIds,
  operationType,
  clearOperationType,
}) => {
  const [holdOrder, {loading}] = useMutation(HOLD_ORDER, {
    onError: error => toast.error(error.message),
    errorPolicy: 'all',
  });

  return (
    <ConfirmModal
      loading={loading}
      title={holdStatus !== 'HOLD' ? 'Release Hold(s)' : 'Hold Order(s)'}
      confirmText={
        holdStatus !== 'HOLD'
          ? 'Are you sure you want to remove the hold(s)?'
          : 'Are you sure you want to hold this order(s)?'
      }
      openModal={orderModal}
      setOpenModal={() => {
        setOrderModal(false);
        setOrder('');
        setOrderDetails({});
        clearOrderIds();
        clearOperationType();
      }}
      handleOperation={() => {
        holdOrder({
          variables: {
            input: {
              orderIds: operationType === 'MULTIPLE' ? orderIds : [orderId],
              hold: holdStatus === 'HOLD' ? true : false,
            },
          },
        })
          .then(({data: {holdOrder}}) => {
            if (
              holdOrder.statusCode === 400 ||
              holdOrder.__typename === 'Error'
            ) {
              toast.error(holdOrder?.message);
            } else {
              toast.success(holdOrder?.message);
              refetch();
              setOrder('');
              setOrderModal(false);
              setOrderDetails({});
              clearOrderIds();
              clearOperationType();
            }
          })
          .catch(error => toast.error(error));
      }}
    />
  );
};

const getUserOrdersMenuItems = (render, handleTab, pageOperations) => {
  const items = [];
  const allowHoldStatuses = ['OPEN', 'PENDING', 'PARTIALLY_FILLED', 'FILLED'];

  if (pageOperations?.update) {
    if (render?.hold) {
      items.push({
        key: '1',
        label: 'Release Hold',
        onClick: () => handleTab('ONHOLD', render.id),
      });
    }

    if (['OPEN', 'PARTIALLY_FILLED'].includes(render?.status)) {
      items.push(
        {
          key: '2',
          label: 'Reject Order',
          onClick: () => handleTab('REJECT', render.id),
        },
        {
          key: '3',
          label: 'Fill Order',
          onClick: e => handleTab('SETTLE', render.id, e),
        },
      );
    } else if (render?.status === 'PENDING') {
      items.push({
        key: '3',
        label: 'Open Order',
        onClick: e => handleTab('PENDING', render.id, e),
      });
    }

    if (render?.status === 'FILLED') {
      items.push({
        key: '4',
        label: 'Settle Order',
        onClick: e => handleTab('FILLED', render, e),
      });
    }

    // Add Hold Order option only for specified statuses
    if (allowHoldStatuses.includes(render?.status) && !render?.hold) {
      items.push({
        key: '5',
        label: 'Hold Order',
        onClick: () => handleTab('HOLD', render.id),
      });
    }

    // Add history view for non-pending orders
    if (render?.status !== 'PENDING') {
      items.push({
        key: '6',
        label: 'View History',
        onClick: e => handleTab('HISTORY', render?.history, e),
      });
    }
  }

  return items;
};

export {
  handleStocksOrderFilter,
  HoldOrderModal,
  bulkOperationItems,
  handleOrderRewrite,
  handleStocksOrdersColumns,
  getUserOrdersMenuItems,
  handleSearchStockTicker,
};
