import {useMutation, useQuery} from '@apollo/client';
import {Form, Spin, Switch} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {UPDATE_PROFILE} from 'graphql/mutations/user';
import {GET_USER_BY_ID} from 'graphql/queries/users';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import styles from './userSecurity.module.scss';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const UserSecurity = () => {
  const {id} = useParams();
  const {data, loading} = useQuery(GET_USER_BY_ID, {
    variables: {
      getUserByIdId: id,
    },
  });
  const [updateProfile, {loading: loadingUpdate}] = useMutation(UPDATE_PROFILE);
  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Users),
  );

  const handleSave = values => {
    updateProfile({
      variables: {
        data: {
          isAccountLocked: values?.lockAccount,
          id,
        },
      },
    }).then(({data: updateProfile}) => {
      if (updateProfile.__typename === 'Error') {
        toast.error(updateProfile.message);
      } else {
        toast.success('Updated Successfully');
      }
    });
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <h1>Loading...</h1>
        <Spin size="loading" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div>
        <h1>Lock User Account</h1>
      </div>
      <Form onFinish={handleSave} className={styles.form} layout="vertical">
        <Form.Item
          name="lockAccount"
          label="Lock Account"
          className={styles['field-width']}>
          <Switch defaultChecked={data?.getUserById?.user?.isAccountLocked} />
        </Form.Item>
        {tabOperations?.update && (
          <SubmitButton disabled={loadingUpdate} label="Update" />
        )}
      </Form>
    </div>
  );
};

export default UserSecurity;
