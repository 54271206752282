import {Dropdown, Menu, Pagination, Table} from 'antd';
import {useQuery} from '@apollo/client';
import styles from '../ViewOrders/viewOrders.module.scss';
import {GET_COMPANIES_IN_STOCK_EXCHANGE} from 'graphql/queries/stocks';
import {useNavigate, useParams} from 'react-router-dom';
import dotsIcon from 'icons/dots.svg';
import backIcon from 'images/back.png';
import {useCallback, useState} from 'react';
import {toast} from 'react-toastify';
import {Search} from '@dabafinance/react-components';
import {debounce, sanitizeInput} from 'utils/helper';

const ViewCompanyListing = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [limit, setLimit] = useState(10);
  const {code} = useParams();
  const navigate = useNavigate();

  const {data, refetch, loading} = useQuery(GET_COMPANIES_IN_STOCK_EXCHANGE, {
    variables: {
      stockExchangeCode: code,
      page: 1,
      limit: 10,
    },
    onError: error => toast.error(error?.message),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const handleSearch = value => {
    const sanitizedValue = sanitizeInput(value);
    setSearchTerm(sanitizedValue);
    setCurrentPage(1);
    setLimit(10);
    refetch({
      searchTerm: sanitizedValue,
      page: 1,
      limit: 10,
    });
  };

  const optimizedSearchCall = useCallback(debounce(handleSearch), []);

  const handlePageChange = (page, pageSize) => {
    if (page === currentPage && pageSize === limit) return;
    setCurrentPage(page);
    setLimit(pageSize);
    refetch({
      page,
      limit: pageSize,
      searchTerm,
    });
  };

  const columns = [
    {
      dataIndex: 'ticker',
      key: 'ticker',
      title: 'Ticker',
      render: (text, record) => <span>{record?.symbol}</span>,
    },
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Name',
      render: (text, record) => <span>{record?.company?.name}</span>,
    },
    {
      dataIndex: 'baseCurrencyCode',
      key: 'baseCurrencyCode',
      title: 'Currency',
    },
    {
      dataIndex: 'price',
      key: 'price',
      title: 'Current Price',
      render: (text, record) => (
        <span>
          {record?.lastPrice} {record?.baseCurrencyCode}
        </span>
      ),
    },
    {
      dataIndex: 'marketCapitalization',
      key: 'marketCap',
      title: 'Market Capitalization',
      render: (text, record) => (
        <span>{record?.marketCapitalization?.toLocaleString()}</span>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (text, render) => (
        <Dropdown
          trigger="hover"
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                onClick={() =>
                  navigate(
                    `/dashboard/opportunities/startups/profile/${render?.company?.id}?route=stocks`,
                  )
                }>
                <div>
                  <span>View Settings</span>
                </div>
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() =>
                  navigate(
                    `/dashboard/stocks/listings?route=order&ticker=${render?.symbol}`,
                  )
                }>
                <div>
                  <span>View Transactions</span>
                </div>
              </Menu.Item>
            </Menu>
          }>
          <img src={dotsIcon} onClick={e => e.stopPropagation()} />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>{code} Listed Companies</h1>
      </div>
      <div className={styles.content}>
        <div className={styles.search}>
          <Search
            onChange={optimizedSearchCall}
            placeholder="Search by Ticker, Name"
          />
        </div>
        <Table
          loading={loading}
          pagination={false}
          columns={columns}
          dataSource={data?.adminGetStockExchangeListedCompanies?.companies}
        />
        <Pagination
          current={currentPage}
          onChange={handlePageChange}
          total={data?.adminGetStockExchangeListedCompanies?.total}
          showQuickJumper
          className="ant-table-pagination"
          showSizeChanger
          pageSize={limit}
        />
      </div>
    </div>
  );
};

export default ViewCompanyListing;
