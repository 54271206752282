import {Route, Routes} from 'react-router-dom';
import AssetClassesOverview from './assetClasses/AssetClassesOverview';
import {CreateAssetClass} from './assetClasses/create/CreateAssetClass';
import {AssetClassLanguageVersions} from './assetClasses/edit/AssetClassLanguageVersions';
import {UpdateAssetClass} from './assetClasses/edit/UpdateAssetClass';
import {AssetClassInstruments} from './assetClasses/assetInstruments/AssetClassInstruments';
import {AssetInstrumentHeader} from './Bonds/AssetInstrumentHeader';
// import {AssetInstrumentBondSettings} from './Bonds/AssetInstrumentBondSetting';
import BondPitchDeck from './Bonds/BondPitchDeck';
import BondOverview from './Bonds/BondOverview';
import BondsAnalysis from './Bonds/BondsAnalysis';
import {BondDabaScore} from './Bonds/BondDabaScore';
import {BondsKeyStatistics} from './Bonds/BondsKeyStatistics';
import {BondsInvestmentDetails} from './Bonds/BondsInvestmentDetails';
import {BondsLegalDocument} from './Bonds/BondsLegalDocument';
import {BondsTeamMembers} from './Bonds/BondsTeamMembers';
import {BondsFAQ} from './Bonds/BondsFAQ';
import {BondsInstrumentSettings} from './Bonds/BondsInstrumentSettings';
import {BondsUsersInvite} from './Bonds/BondsUsersInvite';
import {BondsAnalogs} from './Bonds/BondsAnalogs';
import {persistStore} from 'redux-persist';
import store from 'redux/store';
import {PersistGate} from 'redux-persist/integration/react';
import {BondsRiskEsg} from './Bonds/BondsRiskEsg';
import {BondsInvestmentContract} from './Bonds/bondsInvestmentContract';
import {BondsGeneralDetails} from './Bonds/BondsGeneralDetails';
import {BondsTrading} from './Bonds/BondsTrading';
import BondsStepTwoOverview from './Bonds/stepTwo/BondsStepTwoOverview';
import BondsBrokerCompliance from './Bonds/stepTwo/BondsBrokerCompliance';
import RatingsOverview from './ratings/RatingsOverview';
import {AssetInstrumentLanguages} from './assetClasses/assetInstruments/LanguageVersions';
import {GroupInstrumentsForm} from './GroupInstruments/GroupInstrumentsForm';

const persistor = persistStore(store);

export const AssetManagementRoute = () => {
  return (
    <Routes>
      <Route path="/classes" element={<AssetClassesOverview />} />
      <Route path="/classes/create" element={<CreateAssetClass />} />
      <Route path="/classes/ratings" element={<RatingsOverview />} />
      <Route
        path="/:assetClass/:key/create-instrument-group"
        element={<GroupInstrumentsForm />}
      />
      <Route
        path="/:assetClass/:key/update-instrument-group/:id"
        element={<GroupInstrumentsForm />}
      />
      <Route
        path="/classes/update/:language/:id"
        element={<UpdateAssetClass />}
      />
      <Route
        path="/class/:key/instruments"
        element={<AssetClassInstruments />}
      />

      <Route
        path="/class/:key/instruments/:id/languages"
        element={<AssetInstrumentLanguages />}
      />

      <Route
        path="/classes/languages/:id"
        element={<AssetClassLanguageVersions />}
      />
    </Routes>
  );
};

const bondRoutes = [
  {path: '/', element: <BondsGeneralDetails />},
  {path: '/overview', element: <BondOverview />},
  {path: '/documents', element: <BondsLegalDocument />},
  {path: '/analysis', element: <BondsAnalysis />},
  {path: '/risks', element: <BondsRiskEsg />},
  {path: '/teams', element: <BondsTeamMembers />},
  {path: '/faq', element: <BondsFAQ />},
  {path: '/analogs', element: <BondsAnalogs />},
  {path: '/contracts', element: <BondsInvestmentContract />},
  {path: '/investment', element: <BondsInvestmentDetails />},
  {path: '/users-invite', element: <BondsUsersInvite />},
  {path: '/settings', element: <BondsInstrumentSettings />},
  {path: '/score', element: <BondDabaScore />},
  {path: '/funds', element: <BondsKeyStatistics />},
  {path: '/pitch', element: <BondPitchDeck />},
  {path: '/trading', element: <BondsTrading />},
  {path: '/step-two', element: <BondsStepTwoOverview />},
  {path: '/step-two/broker', element: <BondsBrokerCompliance />},
];

const renderBondRoutes = () => {
  return bondRoutes.map(({path, element}) => (
    <Route key={path} path={path} element={element} />
  ));
};

export const CreateBondRoute = () => {
  return (
    <PersistGate loading={null} persistor={persistor}>
      <Routes>
        <Route path="/" element={<AssetInstrumentHeader />}>
          {renderBondRoutes()}
        </Route>
      </Routes>
    </PersistGate>
  );
};

export const UpdateBondRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AssetInstrumentHeader />}>
        {renderBondRoutes()}
      </Route>
    </Routes>
  );
};
