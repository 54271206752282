import {
  Link,
  Outlet,
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath,
} from 'react-router-dom';
import styles from './userDetails.module.scss';
import classnames from 'classnames/bind';
import backIcon from 'images/back.png';

const CustomLink = ({to, name}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({path: resolved.pathname, end: true});
  const cx = classnames.bind(styles);
  return (
    <Link className={cx('nav-link', match ? 'active-toggle' : '')} to={to}>
      <span>{name}</span>
    </Link>
  );
};

const UserDetails = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.right}>
          <img
            onClick={() => navigate('/dashboard/users')}
            src={backIcon}
            alt=""
          />
          <span>User /</span>
          <h1>Profile Details</h1>
        </div>
      </div>
      <section className={styles.section}>
        <nav className={styles['investor-toggler']}>
          <CustomLink to={`/dashboard/user/${id}`} name="Profile Details" />
          <CustomLink
            to={`/dashboard/user/${id}/kyc-status`}
            name="KYC Status"
          />
          <CustomLink
            to={`/dashboard/user/${id}/investment`}
            name="Investment Details"
          />
          <CustomLink to={`/dashboard/user/${id}/interests`} name="Interest" />
          {/**
           * Commented out the following fields to since they contain dummy data
           *
           */}
          {/* <CustomLink
              to={`/dashboard/user/${id}/banks`}
              name="Bank & Card Details"
            /> */}
          {/*
          <CustomLink to={`/dashboard/user/${id}/bookmark`} name='Bookmarked' />
          <CustomLink to={`/dashboard/user/${id}/saved`} name='Saved' /> */}
          {/* <CustomLink to={`/dashboard/user/${id}/feed`} name='Portfolio' /> */}
          <CustomLink
            to={`/dashboard/user/${id}/subscription`}
            name="Subscription"
          />
          <CustomLink
            to={`/dashboard/user/${id}/transactions`}
            name="Transactions"
          />
          <CustomLink to={`/dashboard/user/${id}/documents`} name="Documents" />
          <CustomLink
            to={`/dashboard/user/${id}/sessions`}
            name="Recorded Sessions"
          />

          <CustomLink
            to={`/dashboard/user/${id}/notification-preferences`}
            name="Notification Preference"
          />
          <CustomLink
            to={`/dashboard/user/${id}/referral`}
            name="Reward / Referral"
          />
          <CustomLink
            to={`/dashboard/user/${id}/wallet-settings`}
            name="Wallet Setting"
          />
          <CustomLink
            to={`/dashboard/user/${id}/user-security`}
            name="Security"
          />
        </nav>
      </section>
      <section>
        <Outlet />
      </section>
    </div>
  );
};

export default UserDetails;
