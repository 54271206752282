import {useEffect, useState} from 'react';
import styles from './adminSettingsOverview.module.scss';
import {Dropdown, Form, Menu, Select, Table, Tag} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_USER_ROLES} from 'graphql/queries/admin';
import dotsIcon from 'icons/dots.svg';
import {Button} from '@dabafinance/react-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {GET_ALL_ADMIN_USERS} from 'graphql/queries/users';
import DBModal from 'components/modal/modal';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {ASSIGN_USER_ROLE, DELETE_ADMIN_USER} from 'graphql/mutations/admin';
import {toast} from 'react-toastify';

const AdminSettingOverview = () => {
  const [activeTab, setActiveTab] = useState('ADMINS');
  const navigate = useNavigate();
  const route = useLocation();

  const searchParamValue = new URLSearchParams(route.search).get('tab');

  useEffect(() => {
    if (searchParamValue === 'role') {
      setActiveTab('ROLES');
    }
  }, [searchParamValue]);

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <h1>Admin Settings Overview</h1>
      </div>
      <div className={styles['details-toggler']}>
        <span
          className={activeTab === 'ADMINS' ? styles['active-toggle'] : ''}
          onClick={() => setActiveTab('ADMINS')}>
          List of Admins
        </span>

        <span
          className={activeTab === 'ROLES' ? styles['active-toggle'] : ''}
          onClick={() => setActiveTab('ROLES')}>
          Roles
        </span>
      </div>
      <div className={styles.content}>
        <div className={styles.topButton}>
          <Button
            onClick={() => navigate('/dashboard/create-role')}
            className={styles.button}
            label="Create New Role"
          />
        </div>
        {activeTab === 'ADMINS' && <AdminUsers />}

        {activeTab === 'ROLES' && <AdminRoles />}
      </div>
    </div>
  );
};

export const AdminUsers = () => {
  const [modalId, setModalId] = useState({id: '', type: '', open: false});

  const {data: roles, loading: loadingRoles} = useQuery(GET_ALL_USER_ROLES, {
    variables: {
      filter: {
        limit: 50,
      },
    },
  });
  const [assignUserRole, {loading: loadingAssign}] =
    useMutation(ASSIGN_USER_ROLE);
  const [deleteAdminUser, {loading: loadingDelete}] =
    useMutation(DELETE_ADMIN_USER);

  const {data, loading, refetch} = useQuery(GET_ALL_ADMIN_USERS, {
    variables: {
      filters: {
        user: {
          admin: true,
        },
      },
    },
  });

  const handleUpdateRole = values => {
    assignUserRole({
      variables: {
        userId: modalId.id,
        roleId: values.role,
      },
    })
      .then(({data: assignUserRole}) => {
        const {statusCode, __typename, message} =
          assignUserRole?.assignUserRole;
        if (__typename === 'Error' || statusCode === 400) {
          toast.error(`Something went wrong!!! - ${message} `);
        } else {
          toast.success('Reassigned Successfully');
          setModalId({open: false});
          refetch();
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err.message}`));
  };

  const handleDeleteAdmin = () => {
    deleteAdminUser({
      variables: {
        userId: modalId.id,
      },
    })
      .then(({data: deleteAdminUser}) => {
        if (
          deleteAdminUser.__typename === 'Error' ||
          deleteAdminUser.statusCode === 400
        ) {
          toast.error(`Something went wrong!!! - ${deleteAdminUser.message} `);
        } else {
          toast.success('Admin User Deleted Successfully');
          setModalId({open: false});
          refetch();
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err.message}`));
  };

  const columns = [
    {
      dataIndex: 'email',
      key: 'email',
      title: 'Email',
      render: (text, render) => <span>{render?.email}</span>,
    },
    {
      dataIndex: 'Role',
      key: 'role',
      title: 'Role',
      render: (text, render) => (
        <Tag color="blue">
          {render?.roleSettings?.name?.split('_')?.join(' ')}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (text, render) => (
        <Dropdown
          trigger="hover"
          overlay={
            <Menu>
              {render?.roleSettings?.name !== 'SUPERADMIN' ? (
                <>
                  <Menu.Item
                    onClick={() =>
                      setModalId({id: render?.id, open: true, type: 'REASSIGN'})
                    }
                    key="1">
                    <div>
                      <span>Reassign Role</span>
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      setModalId({id: render?.id, open: true, type: 'DELETE'})
                    }
                    key="2">
                    <div>
                      <span>Delete Admin</span>
                    </div>
                  </Menu.Item>
                </>
              ) : (
                <Menu.Item key="2">No Action</Menu.Item>
              )}
            </Menu>
          }>
          <img src={dotsIcon} onClick={e => e.stopPropagation()} />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <Table
        loading={loading}
        dataSource={data?.getAllUsers?.users}
        columns={columns}
      />
      <DBModal
        isOpen={modalId.open}
        handleClose={() => setModalId({open: false})}
        content={
          <div className={styles.modalContainer}>
            {modalId.type === 'DELETE' ? (
              <div>
                <h1>Delete Admin User</h1>
                <p>
                  Are you certain you wish to delete this Admin User? Please
                  note that this action <b>Cannot be Reversed</b>.
                </p>
              </div>
            ) : (
              <h1>Update Admin Role</h1>
            )}
            {modalId.type === 'DELETE' ? (
              <div className="buttons">
                <Button
                  disabled={loadingDelete}
                  label="Yes"
                  onClick={handleDeleteAdmin}
                />
                <Button
                  label="No"
                  type="secondary"
                  onClick={() => setModalId({open: false})}
                />
              </div>
            ) : (
              <Form onFinish={handleUpdateRole} layout="vertical">
                <Form.Item
                  rules={[{required: true, message: 'Please Select a Role'}]}
                  name="role"
                  className={styles.fieldWidth}
                  label="Role">
                  <Select loading={loadingRoles}>
                    {roles?.getAllUserRole?.data
                      ?.filter(
                        data => !['SUPERADMIN', 'USER'].includes(data?.name),
                      )
                      ?.map(data => (
                        <Select.Option key={data.id}>
                          {data.description}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <SubmitButton disabled={loadingAssign} label="Update Role" />
              </Form>
            )}
          </div>
        }
      />
    </>
  );
};

export const AdminRoles = () => {
  const {data, loading} = useQuery(GET_ALL_USER_ROLES, {
    variables: {
      filter: {
        limit: 50,
      },
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });
  const navigate = useNavigate();
  const column = [
    {
      dataIndex: 'name',
      key: 'Name',
      title: 'Name',
      render: (text, render) => (
        <Tag color="blue">{render?.name?.split('_')?.join(' ')}</Tag>
      ),
    },
    {
      dataIndex: 'permissions',
      key: 'permissions',
      title: 'Permissions',
      render: (text, record) => <span>{record?.tabs.length}</span>,
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (text, render) => (
        <Dropdown
          trigger="hover"
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => navigate(`/dashboard/update-role/${render?.id}`)}
                key="1">
                <div>
                  <span>Update Permissions</span>
                </div>
              </Menu.Item>
            </Menu>
          }>
          <img src={dotsIcon} onClick={e => e.stopPropagation()} />
        </Dropdown>
      ),
    },
  ];
  return (
    <Table
      columns={column}
      loading={loading}
      dataSource={data?.getAllUserRole?.data.filter(
        data => !['SUPERADMIN', 'USER']?.includes(data?.name),
      )}
    />
  );
};

export default AdminSettingOverview;
